<template>
    <div v-if="currentTicket">
        <div class="flex items-center ticketTitle py-2 mb-2">
            <div class="flex-1">
                <i class="fas fa-ticket-alt mr-2 text-blue-400"></i>
                {{ currentTicket.title }}
            </div>
            <div class="flex-1 text-center">
                <i class="fas fa-user-plus mr-2 text-blue-400"></i>
                {{
                    currentTicket.assignedTo
                        ? currentTicket.assignedTo.username
                        : $t("tickets.sheet.notAssigned")
                }}
            </div>
            <div class="flex-initial">
                <!-- ########################RAPPORTINI######################## -->
                <!-- <ReportModal v-if="isAssignedToMe()" /> -->
                <ReportModal v-if="isLeandro()" />
                <!-- ########################################################## -->

                <el-button
                    type="default"
                    v-if="
                        currentTicket.status_id !== ticketClosed.id &&
                        ($iCan(
                            constants.permissions.tickets
                                .view_configuration_menu_ticket
                        ) ||
                            $iCan(
                                constants.permissions.tickets
                                    .view_action_menu_ticket
                            ))
                    "
                    @click="handleMenu"
                >
                    <i class="fas fa-bars"></i>
                </el-button>
            </div>
        </div>

        <div class="text-right">
            <el-button
                type="success"
                v-if="
                    currentTicket.status_id !== ticketClosed.id &&
                    currentTicket.assignedTo
                "
                @click="addComment"
            >
                <i class="fas fa-plus"></i>
                {{ $t("tickets.editor.addComment") }}
            </el-button>
        </div>

        <div :set="(att = getAttachments(currentTicket.attachments))">
            <div class="p-2 mb-1 text-sm" v-if="att">
                <i class="fas fa-paperclip mr-2"></i
                >{{ $t("tickets.sheet.attachments") }}:
                <p v-for="a in att" :key="a.filename">
                    <a target="_blank" :href="`${a.path}/${a.filename}`">{{
                        a.filename
                    }}</a>
                </p>
            </div>
        </div>

        <el-card class="py-2 my-2">
            <div class="text-right pb-4">
                <el-button type="link" @click="toggleMessage">
                    <span v-if="expandText">
                        <i class="fas fa-compress mr-2"></i>
                        {{ $t("tickets.sheet.collapseAll") }}
                    </span>
                    <span v-else>
                        <i class="fas fa-expand mr-2"></i>
                        {{ $t("tickets.sheet.expandAll") }}
                    </span>
                </el-button>
            </div>
            <blockquote
                class="relative p-4 text-xl italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote mailText"
                :class="expandText ? 'fullHeight' : 'halfHeight'"
            >
                <p className="mb-4" v-html="formatBody(currentTicket.body)"></p>
            </blockquote>
            <div class="text-right" v-if="currentTicket.user">
                <cite class="text-xs"
                    >{{ currentTicket.user.username }} -
                    {{
                        formatDate(currentTicket.created_at, "DD-MM-YYYY HH:mm")
                    }}</cite
                >
            </div>
        </el-card>

        <div class="flex items-center">
            <div class="flex-1">
                <span class="text-2xl">Timeline</span>
            </div>
            <div class="flex-initial">
                <el-button
                    type="success"
                    v-if="
                        currentTicket.status_id !== ticketClosed.id &&
                        currentTicket.assignedTo
                    "
                    @click="addComment"
                >
                    <i class="fas fa-plus"></i>
                    {{ $t("tickets.editor.addComment") }}
                </el-button>
            </div>
        </div>
        <div v-if="currentTicket.comments && currentTicket.comments.length > 0">
            <div class="p-4">
                {{ $t("tickets.sheet.order") }}:
                <el-radio-group v-model="reverse">
                    <el-radio :label="true">{{
                        $t("tickets.sheet.descending")
                    }}</el-radio>
                    <el-radio :label="false">{{
                        $t("tickets.sheet.ascending")
                    }}</el-radio>
                </el-radio-group>
            </div>
            <div class="p-4 overflow-y-auto ticketTimeline">
                <el-timeline class="max-h-full" :reverse="reverse">
                    <el-timeline-item
                        v-for="t in currentTicket.comments"
                        :key="t.id"
                        :timestamp="`${formatDate(
                            t.created_at,
                            'DD-MM-YYYY HH:mm'
                        )} - ${t.user.username}`"
                        :type="t.user.id === user.id ? 'primary' : 'info'"
                        placement="top"
                    >
                        <el-card>
                            <div class="flex">
                                <div class="flex-1">
                                    <p v-html="t.body"></p>

                                    <div
                                        :set="
                                            (att = getAttachments(
                                                t.attactments
                                            ))
                                        "
                                    >
                                        <el-divider v-if="att"></el-divider>
                                        <div class="mt-1 text-sm" v-if="att">
                                            <i class="fas fa-paperclip mr-2"></i
                                            >{{
                                                $t("tickets.sheet.attachments")
                                            }}:
                                            <p
                                                v-for="a in att"
                                                :key="a.filename"
                                            >
                                                <a
                                                    target="_blank"
                                                    :href="`${a.path}/${a.filename}`"
                                                    >{{ a.filename }}</a
                                                >
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="flex-initial"
                                    v-if="t.user.id === user.id"
                                >
                                    <el-tooltip
                                        class="item"
                                        effect="dark"
                                        :content="
                                            $t('tickets.sheet.editComment')
                                        "
                                        placement="top-start"
                                    >
                                        <el-button
                                            size="mini"
                                            icon="fas fa-pen"
                                            @click="editComment(t)"
                                        />
                                    </el-tooltip>
                                </div>
                            </div>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
        <div v-else>
            <p>{{ $t("tickets.sheet.noTimelineData") }}</p>
        </div>

        <MainModal />
    </div>
</template>

<script>
// import utf8 from 'utf8';
import { EventBus } from "@/store/eventBus";
import { getters, actions } from "@/store";
import constants from "@/store/constants";
import BaseMixin from "@/mixins/Base";
import ReportModal from "@/entities/reports/ReportModal";
import MainModal from "@/entities/reports/modal/MainModal";

export default {
    name: "TicketSheet",

    mixins: [BaseMixin],

    components: {
        ReportModal,
        MainModal,
    },

    computed: {
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket,
        ticketClosed: getters.ticket.ticketClosed,
        ticketOpen: getters.ticket.ticketOpen,
    },

    data() {
        return {
            constants,
            loading: false,
            ticket: null,
            reverse: true,
            expandText: false,
        };
    },

    async created() {
        try {
            this.loading = true;

            await actions.ticket.getTicket({
                ticket_id: this.$route.params.ticket_id,
            });

            this.loading = false;
        } catch (error) {
            this.loading = false;
            this.$log.debug(error);
            this.$notify.error(error.message);
        }
    },

    methods: {
        isLeandro() {
            return this.user.id === 40;
        },

        isAssignedToMe() {
            return this.currentTicket.assigned_to === this.user.id;
        },

        getAttachments(attachments) {
            if (attachments && attachments.length > 0) {
                return attachments;
            }
            return null;
        },

        addComment() {
            EventBus.$emit("openTicketEditor", {
                type: "new",
                comment: "",
            });
        },

        editComment(t) {
            EventBus.$emit("openTicketEditor", {
                type: "mod",
                comment: t,
            });
        },

        handleMenu() {
            EventBus.$emit("toggleTicketActionMenu", true);
        },

        formatBody(html) {
            if (!html) {
                return ``;
            }

            let h = html.replace(/\n/g, "<br />");
            // h = utf8.encode(h);

            // console.log(h)

            return h;
        },

        toggleMessage() {
            this.expandText = !this.expandText;
        },
    },
};
</script>
