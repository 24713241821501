'use strict';

import dayjs from 'dayjs';

export default {
    methods: {
        formatDate (d, f, ts) {
            if (!d || d.trim() === '') {
                return '';
            }

            if (!f) {
                f = 'DD/MM/YYYY HH:mm';
            }

            if (!ts) {
                return dayjs(d).format(f);
            }

            return dayjs.unix(d).format(f);
        },

        renderInfo(val) {
            let r = {
                status: {},
                sla: {}
            };

            if (!val.status) {
                r.sla.icon = 'sort-amount-up-alt';
                r.sla.class = 'ticket sla-base';
                r.sla.label = this.$t('tickets.priority.low');

                return r;
            }

            switch (val.status.name.toLowerCase()) {
                case 'open':
                    r.status.icon = 'folder-open';
                    r.status.class = 'ticket open';
                    r.status.label = this.$t('tickets.status.open');
                break;
                case 'in_progress':
                    r.status.icon = 'spinner';
                    r.status.class = 'ticket in_progress';
                    r.status.label = this.$t('tickets.status.in_progress');
                break;
                case 'wait_feedback':
                    r.status.icon = 'circle-pause';
                    r.status.class = 'ticket wait_feedback';
                    r.status.label = this.$t('tickets.status.wait_feedback');
                break;
                default:
                    r.status.icon = 'folder';
                    r.status.label = this.$t('tickets.status.closed');
                break;
            }

            if (!val.priority) {
                r.sla.icon = 'sort-amount-up-alt';
                r.sla.class = 'ticket sla-base';
                r.sla.label = this.$t('tickets.priority.low');

                return r;
            }

            switch (val.priority.name.toLowerCase()) {
                case 'high':
                    r.sla.icon = 'sort-amount-up-alt';
                    r.sla.class = 'ticket sla-1';
                    r.sla.label = this.$t('tickets.priority.high');
                break;
                case 'average':
                    r.sla.icon = 'sort-amount-up-alt';
                    r.sla.class = 'ticket sla-2';
                    r.sla.label = this.$t('tickets.priority.average');
                break;
                case 'immediate':
                    r.sla.icon = 'sort-amount-up-alt';
                    r.sla.class = 'ticket sla-0';
                    r.sla.label = this.$t('tickets.priority.immediate');
                break;
                case 'low':
                default:
                    r.sla.icon = 'sort-amount-up-alt';
                    r.sla.class = 'ticket sla-base';
                    r.sla.label = this.$t('tickets.priority.low');
                break;
            }

            return r;
        },
    }
};
