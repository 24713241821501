<template>
    <el-button type="primary" @click="showModal" :class="'pulse-button'">
        <i class="fas fa-pencil-alt mr-2"></i>
        {{ $t("reports.modal.openBtn.text") }}
    </el-button>
</template>

<script>
import { mutations } from "@/store";

export default {
    name: "ReportModal",

    methods: {
        showModal() {
            mutations.reports.setModalOpen(true);
        },
    },
};
</script>

<style scoped lang="scss">
.pulse-button {
    background: rgb(16, 160, 226);

    box-shadow: 0 0 0 0 rgb(16, 160, 226, 1);
    transform: scale(1);
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(16, 160, 226, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgb(16, 160, 226, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgb(16, 160, 226, 0);
    }
}
</style>
