<template>
    <el-button
        type="success"
        @click="addRole"
        v-if="$iCan(constants.permissions.administration.create_role)"
    >
        <i class="fas fa-plus mr-2"></i>
        {{ $t("administration.roles.new") }}
    </el-button>
</template>

<script>
import slugify from "slugify";
import { actions } from "@/store";
import constants from "@/store/constants";

export default {
    name: "AdminRolesNew",

    data() {
        return {
            constants,
        };
    },

    methods: {
        addRole() {
            this.$prompt(
                this.$t("administration.roles.prompt.text"),
                this.$t("administration.roles.prompt.title"),
                {
                    confirmButtonText: this.$t(
                        "administration.roles.prompt.ok"
                    ),
                    cancelButtonText: this.$t("administration.roles.prompt.ko"),
                    inputPattern: /^[a-zA-Z0-9]*$/,
                    inputErrorMessage: this.$t(
                        "administration.roles.prompt.validationError"
                    ),
                }
            )
                .then(async ({ value }) => {
                    await actions.administration.saveRole({
                        name: value,
                        slug: slugify(value),
                    });
                    await actions.user.getRoles();

                    this.$message({
                        type: "success",
                        message: this.$t(
                            "administration.roles.prompt.operationSuccess"
                        ),
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: this.$t(
                            "administration.roles.prompt.operationAborted"
                        ),
                    });
                });
        },
    },
};
</script>
