<template>
    <div>
        <div class="pb-4">
            <el-button type="default" @click="handleFilters">
                <i class="fas fa-search"></i>
                {{ $t("reports.main.filters.title") }}
            </el-button>

            <el-button type="default" @click="clearFilters">
                <i class="fas fa-undo"></i>
            </el-button>
        </div>
        <el-tabs
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
        >
            <el-tab-pane :label="$t('reports.main.t1')" name="t1">
                <ReportList v-if="activeName === 't1'" />
            </el-tab-pane>
            <el-tab-pane :label="$t('reports.main.t2')" name="t2">
                <ReportUsers v-if="activeName === 't2'" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import BaseMixin from "@/mixins/Base.js";
import { EventBus } from "@/store/eventBus";
import ReportList from "@/entities/reports/ReportList.vue";
import ReportUsers from "@/entities/reports/ReportUsers.vue";
export default {
    name: "ReportMain",

    mixins: [BaseMixin],

    components: {
        ReportList,
        ReportUsers,
    },

    data() {
        return {
            activeName: "t1",
        };
    },

    methods: {
        handleClick(tab, event) {
            console.log(tab, event);
        },

        handleFilters() {
            EventBus.$emit("openReportFilters", true);
        },

        clearFilters() {
            EventBus.$emit(`resetAllReportFilters`, true);
        },
    },
};
</script>
