"use strict";

import Vue from "vue";
import { localStore } from "@/store/localStore";
import * as user from "@/store/user";
import * as ticket from "@/entities/tickets/store";
import * as knowledge from "@/entities/knowledge/store";
import * as administration from "@/entities/administration/store";
import * as reports from "@/entities/reports/store";

const state = Vue.observable({
    title: "Activity",
    copywrite: "InfoTech System 2022",
    locale: "it",
    recordPerPage: 20,
    paginations: [20, 30, 50],
});

export const getters = {
    title: () => state.title,
    copywrite: () => state.copywrite,
    locale: () => {
        const l = localStore.session("locale");
        if (l) {
            state.locale = l;
        }

        return state.locale;
    },
    recordPerPage: () => state.recordPerPage,
    paginations: () => state.paginations,
    user: user.getters,
    ticket: ticket.getters,
    knowledge: knowledge.getters,
    administration: administration.getters,
    reports: reports.getters,
};

export const mutations = {
    setTitle: (val) => (state.title = val),
    setLocale: (val) => {
        localStore.session("locale", val);
        state.locale = val;
    },
    user: user.mutations,
    ticket: ticket.mutations,
    knowledge: knowledge.mutations,
    administration: administration.mutations,
    reports: reports.mutations,
};

export const actions = {
    user: user.actions,
    ticket: ticket.actions,
    knowledge: knowledge.actions,
    administration: administration.actions,
    reports: reports.actions,
};
