<template>
    <div class="content">
        <div class="container-fluid">
            <!-- <transition-group name="fade" mode="out-in">
                <router-view name="slot1" key="slot1" />
                <router-view name="slot2" key="slot2" />
                <router-view name="slot3" key="slot3" />
                <router-view name="slot4" key="slot4" />
                <router-view name="slot5" key="slot5" />
            </transition-group> -->
            <router-view name="slot1" key="slot1" />
            <router-view name="slot2" key="slot2" />
            <router-view name="slot3" key="slot3" />
            <router-view name="slot4" key="slot4" />
            <router-view name="slot5" key="slot5" />
        </div>
    </div>
</template>
<script>
export default {};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.1s;
}

.fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */ {
    opacity: 0;
}
</style>
