<template>
    <footer class="footer footer-transparent text-center">
        <span>&copy; {{ copywrite }}</span>
    </footer>
</template>
<script>
import { getters } from "@/store";
export default {
    computed: {
        copywrite: getters.copywrite,
    },
};
</script>
<style></style>
