<template>
    <nav class="navbar navbar-expand-lg">
        <div class="container-fluid">
            <div class="navbar-minimize">
                <el-button
                    class="p-2"
                    @click="minimizeSidebar"
                    circle
                    :icon="
                        $sidebar.isMinimized
                            ? 'fas fa-bars'
                            : 'fas fa-arrow-left'
                    "
                />
            </div>
            <a class="navbar-brand">{{
                $t(`menu.vertical.${$route.meta.label}.main`)
            }}</a>
            <button
                type="button"
                class="navbar-toggler navbar-toggler-right"
                :class="{ toggled: $sidebar.showSidebar }"
                aria-expanded="false"
                @click="toggleSidebar"
            >
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
                <span class="navbar-toggler-bar burger-lines"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">
                <ul class="navbar-nav">
                    <drop-down position="right">
                        <template slot="title">
                            <i class="fas fa-user"></i>
                            <TicketPersonalCounter />
                            <span class="pr-2 pl-3">{{ user.username }}</span>
                        </template>
                        <a class="dropdown-item" href="#">
                            <i class="fas fa-language pr-2"></i>
                            <Locale></Locale>
                        </a>
                        <a class="dropdown-item" href="#">
                            <el-checkbox v-model="toggleNotifications">
                                {{ $t("menu.activateNotifications") }}
                            </el-checkbox>
                        </a>
                        <div class="divider"></div>
                        <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="logout"
                        >
                            <i class="fas fa-times pr-2"></i> logout
                        </a>
                    </drop-down>
                </ul>
            </div>
        </div>
    </nav>
</template>
<script>
import { localStore } from "@/store/localStore";
import { getters, mutations, actions } from "@/store";
import Locale from "@/entities/__app/Locale";
import TicketPersonalCounter from "@/entities/tickets/TicketPersonalCounter";

export default {
    components: {
        Locale,
        TicketPersonalCounter,
    },
    computed: {
        routeName() {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        },

        user: getters.user.data,

        toggleNotifications: {
            get() {
                return this.user.notificationActive === 1;
            },

            set(val) {
                (async () => {
                    await actions.user.me({
                        notificationActive: val ? 1 : 0,
                    });
                })();
            },
        },
    },
    data() {
        return {
            activeNotifications: false,
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown() {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown() {
            this.activeNotifications = false;
        },
        toggleSidebar() {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar() {
            this.$sidebar.displaySidebar(false);
        },
        minimizeSidebar() {
            this.$sidebar.toggleMinimize();
        },
        logout() {
            localStore.clearAll();
            mutations.user.clearToken();
            this.$router.replace({ name: "login" });
        },
    },
};
</script>
<style></style>
