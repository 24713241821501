<template>
    <el-drawer :visible.sync="drawer" :direction="direction">
        <template v-slot:title>
            <span class="font-bold">{{
                $t("reports.main.filters.title")
            }}</span>
        </template>

        <div class="p-2">
            <el-form ref="form" :model="form" :rules="rules" size="mini">
                <el-form-item prop="customers" label="ID">
                    <el-input v-model="form.idExtReport" type="text" />
                </el-form-item>
                <el-form-item
                    prop="customers"
                    :label="$t('reports.main.filters.customers')"
                >
                    <el-select
                        class="w-full"
                        v-model="form.customers"
                        filterable
                        multiple
                        remote
                        clearable
                        reserve-keyword
                        :remote-method="findCustomers"
                        :loading="loading1"
                    >
                        <el-option
                            v-for="item in customers"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item
                    prop="user"
                    :label="$t('reports.main.filters.users')"
                >
                    <el-select
                        class="w-full"
                        v-model="form.users"
                        filterable
                        multiple
                        remote
                        clearable
                        reserve-keyword
                        :remote-method="findUsers"
                        :loading="loading2"
                    >
                        <el-option
                            v-for="item in users"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="type"
                    :label="$t('reports.main.filters.state')"
                >
                    <el-select
                        clearable
                        v-model="form.is_approved"
                        class="w-full"
                    >
                        <el-option
                            v-for="item in states"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('reports.main.filters.forTime')">
                    <el-date-picker
                        v-model="form.range"
                        type="daterange"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        clearable
                        :range-separator="
                            $t('reports.main.filters.receivedSeparator')
                        "
                        :start-placeholder="
                            $t('reports.main.filters.receivedFrom')
                        "
                        :end-placeholder="$t('reports.main.filters.receivedTo')"
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item class="text-right">
                    <el-button type="primary" @click="setFilters">
                        {{ $t("reports.main.filters.setFilters") }}
                    </el-button>
                    <el-button @click="resetAllFilters">
                        {{ $t("reports.main.filters.delFilters") }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>

<script>
import BaseMixin from "@/mixins/Base.js";
import { EventBus } from "@/store/eventBus";
import { getters, mutations, actions } from "@/store";

const baseForm = {
    idExtReport: null,
    customers: [],
    users: [],
    state: null,
    range: [],
};

export default {
    name: "ReportFilters",

    mixins: [BaseMixin],

    computed: {
        customersList: getters.administration.customers,
        usersList: getters.administration.users,
        user: getters.user.data,
        mainCustomer_id: getters.user.mainCustomer,
        mainCustomer: () => {
            const x = getters.user
                .data()
                .parentClients.filter(
                    (c) =>
                        parseInt(c.id_parent) ===
                        parseInt(getters.user.mainCustomer())
                );

            return x[0].name.replace(/\s/g, "").toLowerCase();
        },
    },

    data() {
        return {
            drawer: false,
            direction: "rtl",
            form: this.$copyStatic(baseForm),
            rules: {},
            customers: [],
            users: [],
            listLocations: [],
            types: [],
            scopes: [{ label: "dummy", value: "Dummy" }],
            states: [
                { label: this.$t("reports.main.filters.toapprove"), value: 0 },
                { label: this.$t("reports.main.filters.approved"), value: 1 },
            ],
            loading1: false,
            loading2: false,
        };
    },

    async mounted() {
        EventBus.$on("openReportFilters", () => {
            this.drawer = true;
        });
        EventBus.$on("resetAllReportFilters", () => this.delFilters());

        await this.populateSelects();
    },

    beforeDestroy() {
        EventBus.$off("openReportFilters");
        EventBus.$off("resetAllReportFilters");
    },

    methods: {
        async findCustomers(term) {
            try {
                this.loading1 = true;
                await actions.administration.getCustomers(
                    {
                        term,
                    },
                    {}
                );

                this.customers = this.customersList.map((el) => {
                    return {
                        label: el.name,
                        value: el.arca_code,
                    };
                });
                this.loading1 = false;
            } catch (error) {
                this.loading1 = false;
                console.log(error);
            }
        },

        async findUsers(term) {
            try {
                this.loading2 = true;
                await actions.administration.getUsers({
                    query: {
                        email: term && term.trim() !== "" ? term : null,
                    },
                    payload: {},
                });

                this.users = this.usersList.map((el) => {
                    return {
                        label: el.username,
                        value: el.id,
                    };
                });
                this.loading2 = false;
            } catch (error) {
                this.loading2 = false;
                console.log(error);
            }
        },

        async setVenue() {
            try {
                const r = await actions.reports.getVenue(this.mainCustomer);

                if (r) {
                    this.listLocations = r
                        .sort((a, b) => {
                            return a.venue_ordine - b.venue_ordine;
                        })
                        .map((el) => {
                            return {
                                value: el.venue_id,
                                label: el.venue_name,
                            };
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },

        async populateSelects() {
            Promise.all([this.setVenue()]).then(() => {
                console.log("--campi popolati--");
                // qui andrò a impostare le select in base ai valori raccolti se presenti
            });
        },

        setFilters() {
            this.isOpen = false;

            const f = this.$copyStatic(this.form);
            mutations.reports.setFilters(f);

            //EventBus.$emit('filterTickets', this.form);
        },

        resetAllFilters() {
            EventBus.$emit(`resetAllReportFilters`, true);
        },

        delFilters() {
            console.log("<< CANCELLA FILTRI >>");
            mutations.reports.unsetFilters();
            const f = this.$copyStatic(baseForm);

            console.log(">>>>>>>>", f);

            this.form = f;
        },
    },
};
</script>
