<template>
    <el-button
        type="success"
        @click="addCategory"
        v-if="$iCan(constants.permissions.administration.create_category)"
    >
        <i class="fas fa-plus mr-2"></i>
        {{ $t("administration.categories.new") }}
    </el-button>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { actions } from "@/store";
import constants from "@/store/constants";

export default {
    name: "AdminCategoriesNew",

    data() {
        return {
            constants,
        };
    },

    methods: {
        addCategory() {
            this.$prompt(
                this.$t("administration.categories.prompt.text"),
                this.$t("administration.categories.prompt.title"),
                {
                    confirmButtonText: this.$t(
                        "administration.categories.prompt.ok"
                    ),
                    cancelButtonText: this.$t(
                        "administration.categories.prompt.ko"
                    ),
                    inputPattern: /^[a-zA-Z0-9]*$/,
                    inputErrorMessage: this.$t(
                        "administration.categories.prompt.validationError"
                    ),
                }
            )
                .then(async ({ value }) => {
                    await actions.administration.saveCategory({
                        name: value,
                    });

                    EventBus.$emit("updateCategories", true);

                    this.$message({
                        type: "success",
                        message: this.$t(
                            "administration.categories.prompt.operationSuccess"
                        ),
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: this.$t(
                            "administration.categories.prompt.operationAborted"
                        ),
                    });
                });
        },
    },
};
</script>
