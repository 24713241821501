"use strict";

import Vue from "vue";
import qs from "query-string";
import apiLib from "@/store/api";
import { localStore } from "@/store/localStore";
// import socket from '@/store/socket';

const api = apiLib(localStore);

const baseFilters = {
    search: "",
    priority_id: null,
    is_assigned: [1, 0],
    assigned_to: [],
    customers_id: [],
    status: {
        open: true,
        wait_feedback: false,
        in_progress: false,
        closed: false,
    },
    time: {
        received: "",
    },
    _updateTime: new Date().format,
};

const state = Vue.observable({
    ticket: {
        list: {
            tickets: [],
            total: 0,
            page: 1,
            pageSize: 20,
        },
        users: [],
        tags: [],
        categories: [],
        status: [],
        priorities: [],
        customers: [],
        currentListStatus: null,
        currentTicket: null,
        selectedTickets: [],
        type: [],
        assignedToMeCnt: 0,
        notAssignedCnt: 0,
        listSearch: {
            url: null,
            query: null,
        },
        filters: baseFilters,
    },
});

export const getters = {
    users: () => state.ticket.users,
    status: () => state.ticket.status,
    tags: () => state.ticket.tags,
    categories: () => state.ticket.categories,
    priorities: () => state.ticket.priorities,
    customers: () => state.ticket.customers,
    priorityLow: () =>
        state.ticket.priorities.filter((el) => el.name === "low")[0],
    ticketOpen: () => state.ticket.status.filter((el) => el.name === "open")[0],
    ticketClosed: () =>
        state.ticket.status.filter((el) => el.name === "closed")[0],
    ticketInProgress: () =>
        state.ticket.status.filter((el) => el.name === "in_progress")[0],
    ticketWaitFeedback: () =>
        state.ticket.status.filter((el) => el.name === "wait_feedback")[0],
    ticketsList: () => state.ticket.list,
    currentListStatus: () => state.ticket.currentListStatus,
    currentTicket: () => state.ticket.currentTicket,
    selectedTickets: () => state.ticket.selectedTickets,
    ticketType: () => state.ticket.type,
    assignedToMeCounter: () => state.ticket.assignedToMeCnt,
    notAssignedCounter: () => state.ticket.notAssignedCnt,
    listSearch: () => state.ticket.listSearch,
    filters: () => state.ticket.filters,
};

export const mutations = {
    setUsers: (val) => {
        state.ticket.users = val;
    },

    setTags: (val) => {
        state.ticket.tags = val;
    },

    setStatus: (val) => {
        state.ticket.status = val;
    },

    setCategories: (val) => {
        state.ticket.categories = val;
    },

    setPriorities: (val) => {
        state.ticket.priorities = val;
    },

    setCustomers: (val) => {
        state.ticket.customers = val;
    },

    setTicketList: (val) => {
        state.ticket.list = val;
    },

    setCurrentListStatus: (val) => {
        state.ticket.currentListStatus = val;
    },

    setCurrentTicket: (val) => {
        state.ticket.currentTicket = val;
    },

    setSelectedTickets: (val) => {
        state.ticket.selectedTickets = val;
    },

    setTicketType: (val) => {
        state.ticket.type = val;
    },

    setAssignedToMeCounter: (val) => {
        state.ticket.assignedToMeCnt = val;
    },

    setNotAssignedCounter: (val) => {
        state.ticket.notAssignedCnt = val;
    },

    setListSearch: (val) => {
        state.ticket.listSearch = val;
    },

    unsetListSearch: () => {
        state.ticket.listSearch = {
            url: null,
            query: null,
        };
    },

    setFilters: (val) => {
        console.log("<<<<<<<<", val);
        state.ticket.filters = { ...state.ticket.filters, ...val };
    },

    unsetFilters: () => {
        state.ticket.filters = baseFilters;
    },
};

export const actions = {
    async getTickets(url, payload) {
        try {
            console.log(">>PAY", payload);

            const res = await api.post(url, payload);

            // console.log('<<<<<<<', res.data)

            const r = {
                tickets: res.data ? res.data.data : [],
                total: res.data.total,
                page: res.data.page,
                perPage: parseInt(res.data.perPage),
            };

            mutations.setTicketList(r);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListTicketException",
                message: "Cannot list tickets",
                status: 400,
            });
        }
    },

    async getTicket(payload) {
        try {
            const res = await api.post(`/search/tickets`, payload);

            const r = res.data.data[0];

            mutations.setCurrentTicket(r);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetTicketException",
                message: "Cannot get ticket",
                status: 400,
            });
        }
    },

    async getStatus() {
        try {
            const res = await api.get(`/status`);
            mutations.setStatus(res.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListStatusError",
                message: "Cannot list status",
                status: 400,
            });
        }
    },

    async getCategories(payload) {
        try {
            const q = qs.stringify(payload);
            const res = await api.get(`/categories${q !== "" ? "?" + q : ""}`);
            mutations.setCategories(res.data.data);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListCategoriesError",
                message: "Cannot list categories",
                status: 400,
            });
        }
    },

    async getBaseCategory() {
        try {
            const res = await api.get(`/categories?term=base`);
            return res.data.data;
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetBaseCategoryError",
                message: "Cannot get base category",
                status: 400,
            });
        }
    },

    async getCustomers(payload) {
        try {
            const q = qs.stringify(payload);
            const res = await api.post(
                `/search/customers${q !== "" ? "?" + q : ""}`,
                {}
            );
            mutations.setCustomers(res.data.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListCustomersError",
                message: "Cannot list customers",
                status: 400,
            });
        }
    },

    async getPriorities() {
        try {
            const res = await api.get(`/priorities`);
            mutations.setPriorities(res.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListPrioritiesError",
                message: "Cannot list priorities",
                status: 400,
            });
        }
    },

    async getTags(payload) {
        try {
            const q = qs.stringify(payload);
            const res = await api.get(`/search/tags${q !== "" ? "?" + q : ""}`);
            mutations.setTags(res.data.data);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListTagsError",
                message: "Cannot list tags",
                status: 400,
            });
        }
    },

    async addComment(payload) {
        try {
            const id = payload.id;
            const ticket_id = payload.ticket_id;
            const us = payload.user_id;
            const comment_id = payload.comment_id;

            if (comment_id) {
                await api.put(`/comments/${comment_id}`, {
                    body: payload.body,
                });
            } else {
                await api.post(`/comments`, {
                    user_id: [us],
                    ticket_id: [id],
                    body: payload.body,
                });
            }

            const res = await api.post(`/search/tickets`, { ticket_id });
            mutations.setCurrentTicket(res.data.data[0]);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "CommentTicketError",
                message: "Cannot comment ticket",
                status: 400,
            });
        }
    },

    async deleteTicket(payload) {
        try {
            if (payload.tickets_id) {
                const ids = payload.tickets_id;
                await api.post(`/tickets/deleteAll`, {
                    tickets_id: ids,
                });
            } else {
                const id = payload.ticket_id;
                await api.delete(`/tickets/${id}`);
            }

            //mutations.setCurrentTicket(null);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteTicketError",
                message: "Cannot delete ticket",
                status: 400,
            });
        }
    },

    async closeTicket(payload) {
        try {
            const id = payload.ticket_id;
            const sc = state.ticket.status.filter((el) => el.name === "closed");

            await api.put(`/tickets/${id}`, {
                status_id: sc[0].id,
            });

            //mutations.setCurrentTicket(null);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "CloseTicketError",
                message: "Cannot close ticket",
                status: 400,
            });
        }
    },

    async inProgressTicket(payload) {
        try {
            const id = payload.ticket_id;
            const st = !payload.in_progress ? "in_progress" : "open";
            const sc = state.ticket.status.filter((el) => el.name === st);

            await api.put(`/tickets/${id}`, {
                status_id: sc[0].id,
            });

            await this.getTicket({ id });
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "InProgressTicketError",
                message: "Cannot set ticket in progress",
                status: 400,
            });
        }
    },

    async waitFeedbackTicket(payload) {
        try {
            const id = payload.ticket_id;
            const st = !payload.wait_feedback ? "wait_feedback" : "open";
            const sc = state.ticket.status.filter((el) => el.name === st);

            await api.put(`/tickets/${id}`, {
                status_id: sc[0].id,
            });

            await this.getTicket({ id });
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "WaitFeedbackTicketError",
                message: "Cannot set ticket waiting for feedback",
                status: 400,
            });
        }
    },

    async assignTicket(payload) {
        try {
            const id = payload.ticket_id;
            const us = payload.user_id;

            await api.put(`/tickets/${id}`, {
                is_assigned: 1,
                assigned_to: us,
            });

            mutations.setCurrentTicket(null);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "AssignTicketError",
                message: "Cannot assign ticket",
                status: 400,
            });
        }
    },

    async unassignTicket(payload) {
        try {
            const id = payload.ticket_id;
            const us = payload.user_id;

            const res = await api.post(`/search/tickets`, { id });
            const r = res.data.data[0];

            if (r.assigned_to && r.assigned_to === us) {
                await api.put(`/tickets/${id}`, {
                    is_assigned: 0,
                    assigned_to: null,
                });

                mutations.setCurrentTicket(null);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "UnassignTicketError",
                message: "Cannot unassign ticket",
                status: 400,
            });
        }
    },

    async getUsers(payload) {
        try {
            const q = qs.stringify(payload);
            const res = await api.post(
                `/search/users${q !== "" ? "?" + q : ""}`,
                {},
                {
                    "content-type": "application/json",
                }
            );

            mutations.setUsers(res.data.data);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListUsersError",
                message: "Cannot list users",
                status: 400,
            });
        }
    },

    async saveTicket(payload) {
        try {
            let res = null;
            if (payload.ticket_id) {
                const id = payload.ticket_id;
                res = await api.put(`/tickets/${id}`, payload);
            } else {
                res = await api.post(`/tickets`, payload);
            }

            const t = res.data;
            const res2 = await api.post(`/search/tickets`, {
                ticket_id: t.ticket_id,
            });

            mutations.setCurrentTicket(res2.data.data[0]);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveTicketError",
                message: "Cannot save ticket",
                status: 400,
            });
        }
    },

    async getCounters(userId) {
        try {
            const res = await api.get(`/tickets/count/${userId}`);

            mutations.setAssignedToMeCounter(res.data.assigned_to_me);
            mutations.setNotAssignedCounter(res.data.not_assigned);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetTicketsCountersError",
                message: "Cannot get tickets counters",
                status: 400,
            });
        }
    },
};
