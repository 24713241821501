"use strict";

import Vue from "vue";
import VueRouter from "vue-router";
import { Notification } from "element-ui";
import AppBootstrap from "./setup/app-bootstrap-main";
// Store
import { EventBus } from "./store/eventBus";
import { localStore } from "./store/localStore";
import { mutations } from "./store";
// i18n
import i18n from "@/setup/i18n";
// Plugins
import App from "./App.vue";

// router setup
import router from "./router";
// plugin setup
Vue.use(VueRouter);
Vue.use(AppBootstrap);

// prototypes
Vue.prototype.$notify = Notification;

Vue.prototype.$throwError = ({ type, message, status, error }) => {
    const e = new Error(message);
    e.name = type;
    e.status = 400;
    if (typeof status !== "undefined") {
        e.status = status;
    }

    if (error) {
        Vue.$log.debug("<<DEBUG - errore>>", error);
    }

    throw e;
};

Vue.prototype.$copyStatic = function (obj) {
    return JSON.parse(JSON.stringify(obj));
};

Vue.prototype.$iCan = function (permission) {
    const data = localStore.session("user");

    if (
        !data ||
        !data.user ||
        !data.user.permissions ||
        data.user.permissions.length === 0
    ) {
        return false;
    }

    const check = data.user.permissions.filter((el) => el.slug === permission);

    return check.length > 0;
};

Vue.prototype.$baseUrl =
    process.env.NODE_ENV === "production" ? "/public/" : "";

/* eslint-disable no-new */
new Vue({
    el: "#app",
    render: (h) => h(App),
    router,
    i18n,
    mounted() {
        EventBus.$off("store_setToken");
        EventBus.$on("store_setToken", (val) => {
            mutations.user().setToken(val);
        });
    },
});
