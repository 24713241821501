export default {
    login: {
        lost_pass: "Forgot your password?",

        enter: "enter",

        errors: {
            email_field: "Email is required",
            pass_field: "Password is required",
        },

        fields: {
            mainCustomer: "Select business",
        },
    },
};
