"use strict";

import axios from "axios";

export default () => {
    const api = axios.create({
        baseURL: process.env.VUE_APP_INTEGRATION_URL,
        timeout: 30000,
    });

    return api;
};
