<template>
    <div class="flex items-top pb-4">
        <div class="flex-1">
            <!-- <i class="fas fa-dot-circle mr-4"></i>
            <span class="text-2xl">{{ $t(`menu.vertical.${$route.meta.label}`) }}</span> -->
        </div>
        <div class="flex-initial">
            <el-button
                type="success"
                @click="newFaq"
                v-if="$iCan(constants.permissions.knowledge.create_faq)"
            >
                <i class="fas fa-plus mr-2"></i>
                {{ $t(`knowledge.newFaq`) }}
            </el-button>
        </div>

        <el-dialog
            :title="$t('knowledge.modal.titleNew')"
            :visible.sync="dialogVisible"
            :before-close="exit"
            width="50%"
        >
            <KnowledgeForm></KnowledgeForm>
        </el-dialog>
    </div>
</template>

<script>
import { mutations } from "@/store";
import { EventBus } from "@/store/eventBus";
import constants from "@/store/constants";
import KnowledgeForm from "@/entities/knowledge/KnowledgeForm.vue";

export default {
    name: "KnowledgeTopMenu",

    data() {
        return {
            constants,
            dialogVisible: false,
        };
    },

    components: {
        KnowledgeForm,
    },

    mounted() {
        EventBus.$on("exitKnowledgeForm", () => this.exit());
    },

    beforeDestroy() {
        EventBus.$off("exitKnowledgeForm");
    },

    methods: {
        newFaq() {
            mutations.knowledge.setFaq({
                data: {
                    q: "",
                    r: "",
                },
                locale: null,
            });
            this.dialogVisible = true;
        },

        exit() {
            this.dialogVisible = false;
        },
    },
};
</script>
