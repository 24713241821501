export default {
    tickets: {
        incoming_ticket: "Incoming new ticket",
        newTicket: "new ticket",
        filters: "Filters",
        operationSuccess: "Operation completed successfully",

        legend: {
            title: "Legend",
            report: "recorded report",
            toapprove: "in approval",
            approved: "approved",
        },

        selection: {
            delete: {
                deleteSelected: "delete selected",
                ok: "confirm",
                ko: "cancel",
                title: "Do you want delete selected tickets?",
            },
        },

        status: {
            open: "open",
            closed: "closed",
            in_progress: "in progress",
            wait_feedback: "wait for feedback",
        },

        priority: {
            low: "low",
            average: "average",
            high: "high",
            immediate: "immediate",
        },

        list: {
            open: "Open",
            assignedToMe: "Assegned to me",
            assigned: "Assigned",
            closed: "Closed (Archive)",
            createdByMeOpen: "My tickets (open)",
            createdByMeClosed: "My tickets (closed)",
        },

        table: {
            subject: "Title",
            category: "Category",
            priority: "SLA",
            status: "Status",
            from_email: "User",
            dueDate: "Expiration",
            customer: "Customer",
            updatedAt: "Updated at",
            openMenu: "Open menu",
            view: "View sheet",
            createDate: "Received",
            assignedTo: "Assigned to",
            changeTitle: "Change the title",
            changeTitlePrompt: "Change actual title",
        },

        counter: {
            assigned: "Num. of tickets assigned to me",
            not_assigned: "Num. of ticket to be assigned",
        },

        sheet: {
            title: "Ticket data # {ticket_id}",
            titleNew: "New ticket",
            order: "Order",
            ascending: "ascending",
            descending: "descending",
            editBody: "Add or update message",
            editComment: "Edit comment",
            noTimelineData: "no timeline data",
            notAssigned: "ticket not assigned",
            attachments: "Attachments",
            noAttachments: "No attachments",
            expandAll: "Expand all",
            collapseAll: "Collapse all",
        },

        drawer: {
            actions: {
                title: "Tickets menu",
                save: "save",
                cancel: "cancel",
                confirmInProgress:
                    "Do you confirm that you want to start processing this ticket?",
                successInProgress: "Processing ticket",
                confirmWaitFeedback:
                    "Do you confirm that this ticket is waiting for feedback?",
                successWaitFeedback: "Update completed",
                sections: {
                    configTitle: "Configurations",
                    actionsTitle: "Operations",

                    config: {
                        changePriority: "Priority",
                        changeCategory: "Category",
                        changeStatus: "Status",
                        changeTitle: "Title",
                        changeBody: "Description",
                        changeDueDate: "Expiration",
                        tag: "Tag",
                        modPriority: "Change priority:",
                        modCategory: "Change category:",
                        modStatus: "Change status:",
                        modTitle: "Change title:",
                        modDueDate: "Change expiration:",
                        newTag: "Add tag",
                        customer: "Select target customer",
                    },
                    actions: {
                        delete: "Delete",
                        deleteText: "i want delete this ticket",
                        confirmDelete: "confirm",
                        close: "Close",
                        closeText: "i want close this ticket",
                        confirmClose: "confirm",
                        take: "Take",
                        takeText: "i want to take this ticket",
                        confirmTake: "confirm",
                        assignTo: "Assign to an operator",
                        srcUsers: "Find an operator",
                        assignText: "confirm choice",
                        confirmAssign: "assign",
                    },

                    filter: {
                        forTitle: "Free search on title",
                        forSla: "Priority",
                        forCategory: "Category",
                        forCustomer: "Customer",
                        forStatus: "Search by status",
                        forUsers: "Search by assigned",
                        forOnlyAssigned: "",
                        isAssigned: "Select all assigned",
                        inProgress: "In progress",
                        waitFeedback: "Waiting for feedback",
                        open: "Open",
                        closed: "Closed",
                        forTime: "Received",
                        receivedFrom: "Start date",
                        receivedTo: "End date",
                        receivedSeparator: "to",
                        setFilters: "set filters",
                        delFilters: "delete filters",
                    },

                    placeholder1: "select",
                    placeholder2: "write...",
                    placeholder3: "find...",
                },
            },
            filters: {
                title: "Tickets search filters",
            },
        },

        editor: {
            newTicket: "New ticket creation",
            addComment: "add a comment",
            comment: "Make a comment and publish",
            save: "save",
            cancel: "cancel",
            confirmComment: "Confirm registration of this comment?",
            confirmTicketCreation: "You want to create this ticket?",
            required: "Required",
        },

        errors: {
            title: "Title field is mandatory",
            body: "Description field is mandatory",
            category: "Ticket must be associated to one category",
            priority: " Ticket must have a priority",
            customer: "Ticket must have a customer",
        },
    },
};
