<template>
    <div class="font-bold mb-4 bg-white py-2 px-4" :key="tkCounter">
        <i class="fas fa-bell pr-2"></i>
        {{ $t("tickets.counter.not_assigned") }}
        {{ notAssignedCounter }}
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters } from "@/store";

export default {
    name: "TicketCounter",

    data() {
        return {
            tkCounter: 0,
        };
    },

    computed: {
        user: getters.user.data,
        assignedToMeCounter: getters.ticket.assignedToMeCounter,
        notAssignedCounter: getters.ticket.notAssignedCounter,
    },

    mounted() {
        EventBus.$on("updateTicketList", () => {
            this.tkCounter += 1;
        });
    },

    beforeDestroy() {
        EventBus.$off("updateTicketList");
    },
};
</script>
