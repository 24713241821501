<template>
    <div class="ticketList">
        <TicketCounter />

        <div class="flex flex-wrap bg-white px-4 py-2 mb-2">
            <div class="flex-initial font-bold">
                {{ $t("tickets.legend.title") }}:
            </div>
            <div class="flex-1 text-right">
                <i class="fas fa-paperclip mr-2 text-blue-600" />
                {{ $t("tickets.legend.report") }}
            </div>
            <div class="flex-1 text-right">
                <i class="fas fa-eye mr-2 text-yellow-600" />
                {{ $t("tickets.legend.toapprove") }}
            </div>
            <div class="flex-1 text-right">
                <i class="fas fa-thumbs-up mr-2 text-green-600" />
                {{ $t("tickets.legend.approved") }}
            </div>
        </div>

        <data-tables-server
            ref="TicketsTable"
            class="datatable"
            :key="ticketsListKey"
            :loading="loading"
            :table-props="tableProps"
            :data="ticketsList.tickets"
            :total="parseInt(ticketsList.total) || 0"
            :current-page="parseInt(ticketsList['page']) || 1"
            :page-size="parseInt(ticketsList['pageSize']) || recordPerPage"
            :pagination-props="{ pageSizes: paginations }"
            :row-class-name="tableRowClassName"
            @query-change="getData"
            @selection-change="handleSelectionChange"
        >
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column
                v-for="column in columns"
                :key="column.prop"
                :label="column.label"
                :prop="column.prop"
                :sortable="column.sortable"
                :align="column.align"
                :width="column.width || 'auto'"
            >
                <template slot-scope="scope">
                    <span :set="(info = renderInfo(scope.row))">
                        <span v-if="column.prop == 'assignedTo'">
                            <span
                                class="text-note-1 block"
                                v-if="scope.row.is_assigned"
                            >
                                <i class="fas fa-user mr-2"></i>
                                {{ scope.row.assignedTo.username }}
                            </span>
                        </span>
                        <span v-else-if="column.prop == 'priority'">
                            <span :class="`${info.sla.class} mr-2 px-2`">
                                <i :class="`fas fa-${info.sla.icon} pr-2`"></i>
                                {{ info.sla.label }}
                            </span>
                        </span>
                        <span v-else-if="column.prop == 'status'">
                            <span :class="info.status.class">
                                <i
                                    :class="`fas fa-${info.status.icon} pr-2`"
                                ></i>
                                {{ info.status.label }}
                            </span>
                        </span>
                        <span v-else-if="column.prop == 'category'">
                            {{ scope.row.category.name }}
                        </span>
                        <span v-else-if="column.prop == 'customer'">
                            {{ scope.row.customer.name }}
                        </span>
                        <span v-else-if="column.prop == 'due_date'">
                            {{ formatDate(scope.row.due_date, "DD/MM/YYYY") }}
                        </span>
                        <span v-else-if="column.prop == 'created_at'">
                            {{ formatDate(scope.row.created_at, "DD/MM/YYYY") }}
                        </span>
                        <span v-else-if="column.prop == 'title'">
                            <div
                                @click="editTitle(scope.row)"
                                class="cursor-pointer"
                            >
                                {{ scope.row.title }}
                            </div>
                        </span>
                        <span v-else-if="column.prop == 'id'">
                            <div>
                                <i
                                    class="fas fa-paperclip mr-2 text-green-600"
                                    v-if="scope.row.report"
                                />
                                <i
                                    class="fas fa-eye mr-2 text-yellow-600"
                                    v-if="scope.row.approval_sent"
                                />
                                <i
                                    class="fas fa-thumbs-up mr-2 text-green-600"
                                    v-if="scope.row.approved_at"
                                />
                                {{ scope.row.id }}
                            </div>
                        </span>
                        <span v-else>{{ scope.row[column.prop] }}</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                label="Azioni"
                width="150px"
                align="center"
                fixed="right"
            >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        icon="fas fa-search"
                        @click="handleview(scope.row)"
                    />
                    <el-button
                        size="mini"
                        icon="fas fa-ellipsis-v"
                        v-if="
                            scope.row.status_id !== ticketClosed.id &&
                            (isMyCategory(scope.row) ||
                                isMine(scope.row) ||
                                isAssignedToMe(scope.row)) &&
                            ($iCan(
                                constants.permissions.tickets
                                    .view_configuration_menu_ticket
                            ) ||
                                $iCan(
                                    constants.permissions.tickets
                                        .view_action_menu_ticket
                                ))
                        "
                        @click="handlemenu(scope.row)"
                    />
                </template>
            </el-table-column>
        </data-tables-server>
    </div>
</template>

<script>
import dayjs from "dayjs";
import qs from "query-string";
import constants from "@/store/constants";
import BaseMixin from "@/mixins/Base.js";
import { EventBus } from "@/store/eventBus";
import { getters, mutations, actions } from "@/store";
import TicketCounter from "@/entities/tickets/TicketCounter.vue";

export default {
    name: "TicketList",

    mixins: [BaseMixin],

    props: ["cond"],

    components: {
        TicketCounter,
    },

    data() {
        return {
            constants,
            ticketsListKey: "init",
            loading: false,
            dialogVisible: false,
            tableProps: {
                border: true,
                stripe: true,
                defaultSort: {
                    prop: "id",
                    order: "descending",
                },
            },
            search: "",
            currentTableQuery: null,
        };
    },

    computed: {
        recordPerPage: getters.recordPerPage,
        paginations: getters.paginations,
        user: getters.user.data,
        ticketOpen: getters.ticket.ticketOpen,
        ticketClosed: getters.ticket.ticketClosed,
        ticketInProgress: getters.ticket.ticketInProgress,
        ticketWaitFeedback: getters.ticket.ticketWaitFeedback,
        ticketsList: getters.ticket.ticketsList,
        listSearch: getters.ticket.listSearch,
        filters: getters.ticket.filters,
    },

    created() {
        this.columns = [
            {
                prop: "id",
                label: "ID",
                sortable: "custom",
                align: "center",
            },
            {
                prop: "title",
                label: this.$t("tickets.table.subject"),
                sortable: "custom",
                align: "center",
            },
            {
                prop: "customer",
                label: this.$t("tickets.table.customer"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "created_at",
                label: this.$t("tickets.table.createDate"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "priority",
                label: this.$t("tickets.table.priority"),
                sortable: "custom",
                align: "center",
            },
            {
                prop: "assignedTo",
                label: this.$t("tickets.table.assignedTo"),
                sortable: "custom",
                align: "center",
            },
            {
                prop: "status",
                label: this.$t("tickets.table.status"),
                sortable: "custom",
                align: "center",
            },
            {
                prop: "from_email",
                label: this.$t("tickets.table.from_email"),
                sortable: "custom",
                align: "center",
            },
        ];

        this.columns.map((el) => (el.width = 180));
    },

    mounted() {
        EventBus.$on("updateTicketList", () => {
            this.ticketsListKey = dayjs().format();
        });
    },

    beforeDestroy() {
        EventBus.$off("updateTicketList");
    },

    methods: {
        async getData(queryInfo) {
            try {
                this.loading = true;

                const qp = {
                    limit: queryInfo.pageSize,
                    page: queryInfo.page,
                    orderBy: queryInfo.sort.prop,
                    orderType:
                        queryInfo.sort.order === "ascending" ? "asc" : "desc",
                };

                let url = `/search/tickets?${qs.stringify(qp)}`;
                if (this.search.trim() !== "") {
                    url += `&term=${this.search}`;
                }

                await actions.ticket.getTickets(url, this.cond);

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        isMine(row) {
            return row.user_id === this.user.id;
        },

        isAssignedToMe(row) {
            return row.assigned_to === this.user.id;
        },

        isMyCategory(row) {
            const check = this.user.categories.filter(
                (el) => parseInt(el.id) === parseInt(row.category_id)
            );

            return check.length > 0;
        },

        handlemenu(row) {
            mutations.ticket.setCurrentTicket(row);
            EventBus.$emit("toggleTicketActionMenu", true);
        },

        handleview(row) {
            mutations.ticket.setCurrentTicket(row);
            this.$router.replace({
                name: "ticket_sheet",
                params: { ticket_id: row.ticket_id },
            });
        },

        handleSelectionChange(val) {
            mutations.ticket.setSelectedTickets(val);
        },

        editTitle(row) {
            this.$prompt(
                this.$t("tickets.table.changeTitlePrompt"),
                this.$t("tickets.table.changeTitle"),
                {
                    confirmButtonText: this.$t("save"),
                    cancelButtonText: this.$t("close"),
                    inputValue: row.title,
                }
            )
                .then(async ({ value }) => {
                    await actions.ticket.saveTicket({
                        ticket_id: row.id,
                        title: value,
                    });
                    EventBus.$emit("updateTicketList", true);

                    this.$message({
                        type: "success",
                        message: this.$t("operationCompleted"),
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: this.$t("operationCanceled"),
                    });
                });
        },

        tableRowClassName({ row, rowIndex }) {
            console.log(rowIndex);

            if (row.report) {
                return "report-row";
            }

            return "";
        },
    },
};
</script>
