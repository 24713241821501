'use strict';

import Vue from 'vue';
import qs from 'query-string';
import apiLib from '@/store/api';
import { localStore } from '@/store/localStore';

const api = apiLib(localStore);

const state = Vue.observable({
    knowledge: {
        faq: null
    }
});

export const getters = {
    faq: () => state.knowledge.faq
};

export const mutations = {
    setFaq: val => {
        state.knowledge.faq = val;
    }
};

export const actions = {
    async getFaqs ({query, payload}) {
        try {
            const q = qs.stringify(query);
            return await api.post(`/search/faqs${q !== '' ? '?'+q : ''}`, payload, {
                'content-type': 'application/json'
            });
        } catch (error) {
            console.log(error)
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: 'ListFaqError',
                message: 'Cannot list faq',
                status: 400
            });
        }
    },

    async saveFaq(payload) {
        try {
            if (payload.faq_id) {
                const id = payload.faq_id;
                await api.put(`/faqs/${id}`, payload);
            } else {
                await api.post(`/faqs`, payload);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: 'SaveFaqError',
                message: 'Cannot save faq',
                status: 400
            });
        }
    },

    async deleteFaq(payload) {
        try {
            if (payload.faq_id) {
                const id = payload.faq_id;
                await api.delete(`/faqs/${id}`);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: 'DeleteFaqError',
                message: 'Cannot delete faq',
                status: 400
            });
        }
    },
};

