<template>
    <el-dialog
        :title="$t('tickets.editor.newTicket')"
        :visible.sync="isOpen"
        width="80%"
    >
        <el-form ref="form" :model="form" :rules="rules" size="medium">
            <div class="flex">
                <div class="flex-1">
                    <div class="p-2" v-if="listCustomers.length > 0">
                        <el-form-item
                            prop="customer_id"
                            :label="
                                $t(
                                    'tickets.drawer.actions.sections.config.customer'
                                )
                            "
                        >
                            <el-select
                                v-model="form.customer_id"
                                filterable
                                clearable
                                class="w-full"
                                :placeholder="
                                    $t(
                                        'tickets.drawer.actions.sections.placeholder1'
                                    )
                                "
                            >
                                <el-option
                                    v-for="item in listCustomers"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="p-2">
                        <el-form-item
                            prop="category_id"
                            :label="
                                $t(
                                    'tickets.drawer.actions.sections.config.changeCategory'
                                )
                            "
                        >
                            <el-select
                                v-model="form.category_id"
                                filterable
                                remote
                                clearable
                                reserve-keyword
                                :remote-method="srcCategories"
                                :loading="loadingCategories"
                                class="w-full"
                                :placeholder="
                                    $t(
                                        'tickets.drawer.actions.sections.config.modCategory'
                                    )
                                "
                            >
                                <el-option
                                    v-for="item in listCategories"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="p-2">
                        <el-form-item
                            prop="priority_id"
                            :label="
                                $t(
                                    'tickets.drawer.actions.sections.config.changePriority'
                                )
                            "
                        >
                            <el-select
                                clearable
                                v-model="form.priority_id"
                                class="w-full"
                                :placeholder="
                                    $t(
                                        'tickets.drawer.actions.sections.config.modPriority'
                                    )
                                "
                            >
                                <el-option
                                    v-for="item in listPriorities"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value"
                                />
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="p-2">
                        <el-form-item
                            :label="
                                $t(
                                    'tickets.drawer.actions.sections.config.changeDueDate'
                                )
                            "
                        >
                            <el-date-picker
                                clearable
                                v-model="form.due_date"
                                type="date"
                                format="dd-MM-yyyy"
                                value-format="yyyy-MM-dd"
                                style="width: 100%"
                                :placeholder="
                                    $t(
                                        'tickets.drawer.actions.sections.config.modDueDate'
                                    )
                                "
                            />
                        </el-form-item>
                    </div>
                </div>
                <div class="flex-1">
                    <div class="p-2">
                        <el-form-item
                            prop="title"
                            :label="
                                $t(
                                    'tickets.drawer.actions.sections.config.changeTitle'
                                )
                            "
                        >
                            <el-input
                                clearable
                                class="custom-input"
                                type="input"
                                v-model="form.title"
                                :placeholder="
                                    $t(
                                        'tickets.drawer.actions.sections.placeholder2'
                                    )
                                "
                            />
                        </el-form-item>
                    </div>
                    <div class="p-2">
                        <el-form-item
                            prop="description"
                            :label="
                                $t(
                                    'tickets.drawer.actions.sections.config.changeBody'
                                )
                            "
                        >
                            <el-input
                                clearable
                                class="custom-input"
                                type="textarea"
                                :rows="10"
                                v-model="form.body"
                                :placeholder="
                                    $t(
                                        'tickets.drawer.actions.sections.placeholder2'
                                    )
                                "
                            />
                        </el-form-item>
                    </div>
                </div>
            </div>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-popconfirm
                confirm-button-text="OK"
                cancel-button-text="NO"
                icon="el-icon-info"
                icon-color="red"
                :title="$t('tickets.editor.confirmTicketCreation')"
                @confirm="saveConfig"
            >
                <el-button
                    :loading="loadingSaveConfig"
                    class="mr-2"
                    slot="reference"
                    type="primary"
                >
                    {{ $t("tickets.editor.save") }}
                </el-button>
            </el-popconfirm>
            <el-button @click="closeConfig">{{
                $t("tickets.editor.cancel")
            }}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import dayjs from "dayjs";
import { EventBus } from "@/store/eventBus";
import { getters, actions } from "@/store";

const baseForm = () => {
    return {
        title: null,
        body: null,
        category_id: [],
        customer_id: null,
        priority_id: null,
        status_id: null,
        due_date: null,
        tag_id: [],
    };
};

export default {
    name: "TicketNew",

    computed: {
        user: getters.user.data,
        categories: getters.ticket.categories,
        priorities: getters.ticket.priorities,
        status: getters.ticket.status,
        tags: getters.ticket.tags,
        ticketOpen: getters.ticket.ticketOpen,
        priorityLow: getters.ticket.priorityLow,
        mainCustomer_id: getters.user.mainCustomer,

        listPriorities() {
            return this.priorities.map((el) => {
                return {
                    label: this.$t("tickets.priority." + el.name.toLowerCase()),
                    value: el.id,
                };
            });
        },

        listCustomers() {
            const c = this.$copyStatic(this.user.customers);
            return c;
        },
    },

    data() {
        return {
            isOpen: false,

            form: baseForm(),

            loadingCategories: false,
            listCategories: [],

            loadingTags: false,
            listTags: [],

            loadingSaveConfig: false,

            rules: {
                title: [
                    {
                        required: true,
                        message: this.$t("tickets.editor.required"),
                        trigger: "blur",
                    },
                ],
                body: [
                    {
                        required: true,
                        message: this.$t("tickets.editor.required"),
                        trigger: "blur",
                    },
                ],
                category_id: [
                    {
                        required: true,
                        message: this.$t("tickets.editor.required"),
                        trigger: "blur",
                    },
                ],
                customer_id: [
                    {
                        required: true,
                        message: this.$t("tickets.editor.required"),
                        trigger: "blur",
                    },
                ],
                priority_id: [
                    {
                        required: true,
                        message: this.$t("tickets.editor.required"),
                        trigger: "blur",
                    },
                ],
                status_id: [
                    {
                        required: true,
                        message: this.$t("tickets.editor.required"),
                        trigger: "blur",
                    },
                ],
            },
        };
    },

    /* watch: {
        priorityLow(val) {
            if (val) {
                this.form.priority_id = this.priorityLow.id;
            }
        }
    }, */

    mounted() {
        EventBus.$on("toggleNewTicket", async (val) => {
            this.isOpen = val;
            await this.getBaseCategory();

            if (!this.form.category_id) {
                this.form.category_id = this.listCategories[0].value;
            }

            console.log(
                "<<<<<<<<<<<",
                this.form.priority_id,
                this.priorityLow.id
            );

            if (!this.form.priority_id) {
                this.form.priority_id = this.priorityLow.id;
            }
        });
    },

    beforeDestroy() {
        EventBus.$off("toggleNewTicket");
    },

    methods: {
        async srcCategories(query) {
            try {
                if (query.trim() !== "") {
                    await actions.ticket.getCategories({ term: query });
                    this.listCategories = this.categories.map((el) => {
                        return {
                            label: el.name,
                            value: el.id,
                        };
                    });
                } else {
                    this.listCategories = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async getBaseCategory() {
            try {
                const r = await actions.ticket.getBaseCategory();
                this.listCategories = [
                    {
                        label: r[0].name,
                        value: r[0].id,
                    },
                ];
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async srcTags(query) {
            try {
                if (query.trim() !== "") {
                    await actions.ticket.getTags({ term: query });
                    this.listTags = this.tags.map((el) => {
                        return {
                            label: el.name,
                            value: el.id,
                        };
                    });
                } else {
                    this.listTags = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.tags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = "";
        },

        async saveConfig() {
            try {
                this.$refs.form.validate(async (valid) => {
                    if (valid) {
                        this.loadingSaveConfig = true;

                        this.form.status_id = this.ticketOpen.id;
                        this.form.user_id = this.user.id;
                        this.form.parentClient_id = this.mainCustomer_id;

                        if (this.user.customers.length === 1) {
                            this.form.customer_id = this.user.customers[0].id;
                        }

                        if (this.form.due_date) {
                            this.form.due_date = dayjs(
                                this.form.due_date
                            ).format("YYYY-MM-DD");
                        }

                        //console.log('<<<<<<<<<<<', this.form);

                        const frm = this.$copyStatic(this.form);
                        await actions.ticket.saveTicket(frm);
                        this.loadingSaveConfig = false;

                        this.$notify.success(
                            this.$t("tickets.operationSuccess")
                        );

                        EventBus.$emit("updateTicketList", true);
                        this.closeConfig();
                    } else {
                        return false;
                    }
                });
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        closeConfig() {
            Object.assign(this.form, baseForm());
            this.isOpen = false;
        },

        formValidation() {
            let res = true;

            if (!this.form.title || this.form.title.trim() === "") {
                this.$notify.error(this.$t("tickets.errors.title"));
                res = false;
            }
            if (!this.form.body || this.form.body.trim() === "") {
                this.$notify.error(this.$t("tickets.errors.body"));
                res = false;
            }
            if (!this.form.customer_id || this.form.customer_id.length === 0) {
                this.$notify.error(this.$t("tickets.errors.customer"));
                res = false;
            }
            if (!this.form.category_id || this.form.category_id.length === 0) {
                this.$notify.error(this.$t("tickets.errors.category"));
                res = false;
            }
            if (!this.form.priority_id || this.form.priority_id.length === 0) {
                this.$notify.error(this.$t("tickets.errors.priority"));
                res = false;
            }

            return res;
        },
    },
};
</script>
