<template>
    <div
        class="wrapper"
        :class="{ 'nav-open': $sidebar.showSidebar }"
        v-if="bootstrapCompleted"
    >
        <notifications></notifications>
        <side-bar
            :title="title"
            :backgroundImage="`${this.$baseUrl}static/img/INFOTECH-net-colori-esec.png`"
            :logo="`${this.$baseUrl}static/img/vue-logo.png`"
        >
            <mobile-menu></mobile-menu>
            <template slot="links">
                <sidebar-item
                    v-if="$iCan(constants.permissions.tickets.view_ticket_menu)"
                    :link="{
                        name: $t(`menu.vertical.tickets.main`),
                        icon: 'fas fa-ticket-alt',
                        path: '/tickets',
                    }"
                >
                </sidebar-item>
                <sidebar-item
                    v-if="
                        $iCan(
                            constants.permissions.knowledge.view_knowledge_menu
                        )
                    "
                    :link="{
                        name: $t(`menu.vertical.knowledge.main`),
                        icon: 'fas fa-book',
                        path: '/knowledge',
                    }"
                >
                </sidebar-item>
                <sidebar-item
                    v-if="
                        $iCan(constants.permissions.reports.view_report_menu) &&
                        false
                    "
                    :link="{
                        name: $t(`menu.vertical.reports.main`),
                        icon: 'fas fa-chart-pie',
                        path: '/reports',
                    }"
                >
                </sidebar-item>
                <sidebar-item
                    v-if="
                        $iCan(
                            constants.permissions.administration.view_admin_menu
                        )
                    "
                    :link="{
                        name: $t(`menu.vertical.administration.main`),
                        icon: 'fas fa-user-cog',
                    }"
                >
                    <sidebar-item
                        :link="{
                            icon: 'fas fa-users minIcon',
                            name: $t(
                                `menu.vertical.administration.customers.main`
                            ),
                            path: '/administration/customers',
                        }"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            icon: 'fas fa-tags minIcon',
                            name: $t(
                                `menu.vertical.administration.categories.main`
                            ),
                            path: '/administration/categories',
                        }"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            icon: 'fas fa-user-tag minIcon',
                            name: $t(`menu.vertical.administration.roles.main`),
                            path: '/administration/roles',
                        }"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            icon: 'fas fa-users minIcon',
                            name: $t(`menu.vertical.administration.users.main`),
                            path: '/administration/users',
                        }"
                    ></sidebar-item>
                    <sidebar-item
                        :link="{
                            icon: 'fas fa-envelope minIcon',
                            name: $t(
                                `menu.vertical.administration.blacklist.main`
                            ),
                            path: '/administration/blacklist',
                        }"
                    ></sidebar-item>
                </sidebar-item>
            </template>
        </side-bar>
        <div class="main-panel">
            <top-navbar></top-navbar>

            <dashboard-content @click.native="toggleSidebar">
            </dashboard-content>

            <content-footer></content-footer>
        </div>
    </div>
</template>
<script>
import { getters, actions } from "@/store";
import { EventBus } from "@/store/eventBus";
import constants from "@/store/constants";
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./Extra/MobileMenu.vue";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

export default {
    components: {
        TopNavbar,
        ContentFooter,
        DashboardContent,
        MobileMenu,
    },

    data() {
        return {
            loading: false,
            constants,
            bootstrapCompleted: false,
        };
    },

    computed: {
        title: getters.title,
        user: getters.user.data,
        assignedToMeCounter: getters.ticket.assignedToMeCounter,
    },

    methods: {
        toggleSidebar() {
            if (this.$sidebar.showSidebar) {
                this.$sidebar.displaySidebar(false);
            }
        },
        initScrollbar() {
            let docClasses = document.body.classList;
            let isWindows = navigator.platform.startsWith("Win");
            if (isWindows) {
                // if we are on windows OS we activate the perfectScrollbar function
                initScrollbar("main-panel");

                docClasses.add("perfect-scrollbar-on");
            } else {
                docClasses.add("perfect-scrollbar-off");
            }
        },
        async getCounters() {
            try {
                this.loading = true;

                await actions.ticket.getCounters(this.user.id);

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },
    },
    async created() {
        Promise.all([
            actions.ticket.getStatus(),
            actions.ticket.getPriorities(),
            actions.user.getRoles(),
            actions.user.getPermissions(),
        ])
            .then(() => {
                this.bootstrapCompleted = true;
            })
            .catch((error) => {
                this.$log.debug(error);
                this.$notify.error(error.message);
            });
    },
    async mounted() {
        this.initScrollbar();

        await this.getCounters();

        EventBus.$on("updateTicketList", async () => {
            await this.getCounters();
        });
    },

    beforeDestroy() {
        EventBus.$off("updateTicketList");
    },
};
</script>
