<template>
    <tickets-list :cond="cond" :key="reloader" v-if="cond"></tickets-list>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters } from "@/store";
import TicketsList from "./TicketList";
import constants from "@/store/constants";

export default {
    name: "TicketMain",

    components: {
        TicketsList,
    },

    computed: {
        status: getters.ticket.status,
        user: getters.user.data,
        ticketOpen: getters.ticket.ticketOpen,
        ticketClosed: getters.ticket.ticketClosed,
        ticketInProgress: getters.ticket.ticketInProgress,
        ticketWaitFeedback: getters.ticket.ticketWaitFeedback,
        filters: getters.ticket.filters,
        mainCustomer_id: getters.user.mainCustomer,
    },

    data() {
        return {
            activeName: null,
            ticketStatus: null,
            cond: {},
            reloader: 0,
            constants,
        };
    },

    watch: {
        status: function () {
            console.log("--------> Entro in status");
            this.cond = this.setConditions(this.filters);
            this.reloader += 1;
        },

        filters: function (val) {
            if (val) {
                console.log("--------> Entro in filter");
                this.cond = this.setConditions(val);
                this.reloader += 1;
            }
        },
    },

    created() {
        this.cond = this.setConditions(this.filters);
        this.reloader += 1;
    },

    beforeDestroy() {
        EventBus.$off("resetInitialCond");
    },

    methods: {
        setConditions(val) {
            if (!this.status || this.status.length === 0) {
                return null;
            }

            let cond = {
                parentClient_id: [this.mainCustomer_id],
            };

            console.log("------>CONDIZIONI<--------", val);

            cond.is_assigned = val.is_assigned;

            if (val.search && val.search.trim() !== "") {
                this.search = val.search;
            } else {
                this.search = "";
            }
            if (val.priority_id && val.priority_id.length > 0) {
                cond.priority_id = val.priority_id;
            } else {
                cond.priority_id = null;
            }
            if (val.assigned_to && val.assigned_to.length > 0) {
                cond.assigned_to = val.assigned_to;
            } else {
                cond.assigned_to = null;
            }

            if (val.customers_id && val.customers_id.length > 0) {
                cond.customers_id = val.customers_id;
            } else {
                cond.customers_id = null;
            }

            cond.status_id = [];

            if (val.status.open) {
                cond.status_id.push(this.ticketOpen.id);
            }
            if (val.status.in_progress) {
                cond.status_id.push(this.ticketInProgress.id);
            }
            if (val.status.wait_feedback) {
                cond.status_id.push(this.ticketWaitFeedback.id);
            }
            if (val.status.closed) {
                cond.status_id.push(this.ticketClosed.id);
            }

            if (val.time.received) {
                val.time.received[0] = val.time.received[0] + " 00:00:00";
                val.time.received[1] = val.time.received[1] + " 23:59:59";
                cond.created_at = val.time.received;
            } else {
                cond.created_at = null;
            }

            cond = this.$copyStatic(cond);

            console.log("--COND FILTER--", cond);

            return cond;
        },
    },
};
</script>
