var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.bootstrapCompleted)?_c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('side-bar',{attrs:{"title":_vm.title,"backgroundImage":((this.$baseUrl) + "static/img/INFOTECH-net-colori-esec.png"),"logo":((this.$baseUrl) + "static/img/vue-logo.png")}},[_c('mobile-menu'),_c('template',{slot:"links"},[(_vm.$iCan(_vm.constants.permissions.tickets.view_ticket_menu))?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t("menu.vertical.tickets.main"),
                    icon: 'fas fa-ticket-alt',
                    path: '/tickets',
                }}}):_vm._e(),(
                    _vm.$iCan(
                        _vm.constants.permissions.knowledge.view_knowledge_menu
                    )
                )?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t("menu.vertical.knowledge.main"),
                    icon: 'fas fa-book',
                    path: '/knowledge',
                }}}):_vm._e(),(
                    _vm.$iCan(_vm.constants.permissions.reports.view_report_menu) &&
                    false
                )?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t("menu.vertical.reports.main"),
                    icon: 'fas fa-chart-pie',
                    path: '/reports',
                }}}):_vm._e(),(
                    _vm.$iCan(
                        _vm.constants.permissions.administration.view_admin_menu
                    )
                )?_c('sidebar-item',{attrs:{"link":{
                    name: _vm.$t("menu.vertical.administration.main"),
                    icon: 'fas fa-user-cog',
                }}},[_c('sidebar-item',{attrs:{"link":{
                        icon: 'fas fa-users minIcon',
                        name: _vm.$t(
                            "menu.vertical.administration.customers.main"
                        ),
                        path: '/administration/customers',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                        icon: 'fas fa-tags minIcon',
                        name: _vm.$t(
                            "menu.vertical.administration.categories.main"
                        ),
                        path: '/administration/categories',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                        icon: 'fas fa-user-tag minIcon',
                        name: _vm.$t("menu.vertical.administration.roles.main"),
                        path: '/administration/roles',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                        icon: 'fas fa-users minIcon',
                        name: _vm.$t("menu.vertical.administration.users.main"),
                        path: '/administration/users',
                    }}}),_c('sidebar-item',{attrs:{"link":{
                        icon: 'fas fa-envelope minIcon',
                        name: _vm.$t(
                            "menu.vertical.administration.blacklist.main"
                        ),
                        path: '/administration/blacklist',
                    }}})],1):_vm._e()],1)],2),_c('div',{staticClass:"main-panel"},[_c('top-navbar'),_c('dashboard-content',{nativeOn:{"click":function($event){return _vm.toggleSidebar.apply(null, arguments)}}}),_c('content-footer')],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }