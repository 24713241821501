<template>
    <el-form ref="form" :model="form">
        <div class="p-2">
            <el-form-item :label="$t('tickets.drawer.actions.sections.config.changeTitle')">
                <el-input
                    type="input"
                    class="custom-input"
                    v-model="form.title"
                    :placeholder="$t('tickets.drawer.actions.sections.placeholder2')"
                    :readonly="!isMine"
                />
            </el-form-item>
        </div>
        <div class="p-2">
            <el-form-item :label="$t('tickets.drawer.actions.sections.config.changeBody')">
                <el-input
                    class="custom-input"
                    type="textarea"
                    :rows="6"
                    v-model="form.body"
                    :placeholder="$t('tickets.drawer.actions.sections.placeholder2')"
                    :readonly="!isMine"
                />
            </el-form-item>
        </div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.update_category_ticket)">
            <el-form-item :label="$t('tickets.drawer.actions.sections.config.changeCategory')">
                <el-select
                    v-model="form.category_id"
                    filterable
                    remote
                    reserve-keyword
                    :remote-method="srcCategories"
                    :loading="loadingCategories"
                    class="w-full"
                    :placeholder="$t('tickets.drawer.actions.sections.config.modCategory')"
                >
                    <el-option
                        v-for="item in listCategories"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>
        </div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.update_priority_ticket)">
            <el-form-item :label="$t('tickets.drawer.actions.sections.config.changePriority')">
                <el-select
                    v-model="form.priority_id"
                    class="w-full"
                    :placeholder="$t('tickets.drawer.actions.sections.config.modPriority')"
                >
                    <el-option
                        v-for="item in listPriorities"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>
        </div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.update_due_date_ticket)">
            <el-form-item :label="$t('tickets.drawer.actions.sections.config.changeDueDate')">
                <el-date-picker
                    v-model="form.due_date"
                    type="date"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    style="width: 100%;"
                    :placeholder="$t('tickets.drawer.actions.sections.config.modDueDate')"
                />
            </el-form-item>
        </div>
        <!-- <div class="p-2">
            <el-form-item :label="$t('tickets.drawer.actions.sections.config.tag')">
                <el-select
                    v-model="form.tag_id"
                    filterable
                    multiple
                    remote
                    reserve-keyword
                    :remote-method="srcTags"
                    :loading="loadingTags"
                    class="w-full"
                    :placeholder="$t('tickets.drawer.actions.sections.placeholder2')"
                >
                    <el-option
                        v-for="item in listTags"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    />
                </el-select>
            </el-form-item>
        </div> -->
        <div class="p-2 text-right">
            <el-button
                type="primary"
                :loading="loadingSaveConfig"
                @click="saveConfig"
            >
                {{$t('tickets.drawer.actions.save')}}
            </el-button>
        </div>
    </el-form>
</template>

<script>
import dayjs from 'dayjs';
import { EventBus } from '@/store/eventBus';
import { getters, actions } from "@/store";
import constants from '@/store/constants';

export default {
    name: 'TicketMenuConfig',

    computed: {
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket,
        categories: getters.ticket.categories,
        priorities: getters.ticket.priorities,
        status: getters.ticket.status,
        tags: getters.ticket.tags,
        ticketOpen: getters.ticket.ticketOpen,

        isMine() {
            if (!this.currentTicket.user) {
                return true;
            }
            return this.currentTicket && this.currentTicket.user.id === this.user.id;
        },

        listStatus() {
            return this.status.map(el => {
                return {
                    label: this.$t('tickets.status.' + el.name.toLowerCase()),
                    value: el.id
                }
            });
        },

        listPriorities() {
            return this.priorities.map(el => {
                return {
                    label: this.$t('tickets.priority.' + el.name.toLowerCase()),
                    value: el.id
                }
            });
        }
    },

    data() {
        return {
            constants,
            form: {
                ticket_id: null,
                title: null,
                body: null,
                category_id: [],
                priority_id: null,
                status_id: null,
                due_date: null,
                tag_id: []
            },

            loadingCategories: false,
            listCategories: [],

            loadingTags: false,
            listTags: [],

            loadingSaveConfig: false
        };
    },

    created() {
        this.listCategories = [{
            label: this.currentTicket.category.name,
            value: this.currentTicket.category.id
        }];

        this.listTags = this.currentTicket.tags.map(el => {
            return {
                label: el.name,
                value: el.id
            }
        });

        this.form = Object.assign({}, this.form, {
            ticket_id: this.currentTicket.id,
            title: this.currentTicket.title,
            body: this.currentTicket.body,
            category_id: this.currentTicket.category.id,
            status_id: this.currentTicket.status.id,
            priority_id: this.currentTicket.priority.id,
            due_date: this.currentTicket.due_date,
            tag_id: this.currentTicket.tags.map(el => el.id)
        });
    },

    methods: {
        async srcCategories(query) {
            try {
                if (query.trim() !== '') {
                    await actions.ticket.getCategories({ term: query });
                    this.listCategories = this.categories.map(el => {
                        return {
                            label: el.name,
                            value: el.id
                        }
                    });
                } else {
                    this.listCategories = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async srcTags(query) {
            try {
                if (query.trim() !== '') {
                    await actions.ticket.getTags({ term: query });
                    this.listTags = this.tags.map(el => {
                        return {
                            label: el.name,
                            value: el.id
                        }
                    });
                } else {
                    this.listTags = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(() => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
                this.form.tags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },

        async saveConfig() {
            try {
                if (!this.formValidation()) {
                    return false;
                }

                this.loadingSaveConfig = true;

                if (this.form.due_date) {
                    this.form.due_date = dayjs(this.form.due_date).format('YYYY-MM-DD');
                }

                await actions.ticket.saveTicket(this.form);
                this.loadingSaveConfig = false;

                this.$notify.success(this.$t('tickets.operationSuccess'));

                EventBus.$emit('updateTicketList', true);
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        formValidation() {
            let res = true;

            if (!this.form.title || this.form.title.trim() === '') {
                this.$notify.error(this.$t('tickets.errors.title'));
                res = false;
            }
            if (!this.form.body || this.form.body.trim() === '') {
                this.$notify.error(this.$t('tickets.errors.body'));
                res = false;
            }
            if (!this.form.category_id || this.form.category_id.length === 0) {
                this.$notify.error(this.$t('tickets.errors.category'));
                res = false;
            }

            return res;
        }
    }
}
</script>
