<template>
    <auth-layout pageClass="login-page">
        <div class="row d-flex justify-content-center align-items-center">
            <div class="col-lg-4 col-md-6 col-sm-8">
                <ValidationObserver v-slot="{ handleSubmit }">
                    <!--You can specify transitions on initial render. The `card-hidden` class will be present initially and then it will be removed-->
                    <form @submit.prevent="handleSubmit(submit)">
                        <fade-render-transition>
                            <card>
                                <div slot="header">
                                    <h3 class="card-title text-center">
                                        {{ title }}
                                    </h3>
                                </div>
                                <div>
                                    <ValidationProvider
                                        name="email"
                                        rules="required|email"
                                        v-slot="{ passed, failed }"
                                    >
                                        <fg-input
                                            type="email"
                                            :error="
                                                failed
                                                    ? $t(
                                                          `login.errors.email_field`
                                                      )
                                                    : null
                                            "
                                            :hasSuccess="passed"
                                            label="Email"
                                            name="email"
                                            v-model="email"
                                        >
                                        </fg-input>
                                    </ValidationProvider>
                                    <ValidationProvider
                                        name="password"
                                        rules="required|min:5"
                                        v-slot="{ passed, failed }"
                                    >
                                        <fg-input
                                            type="password"
                                            :error="
                                                failed
                                                    ? $t(
                                                          `login.errors.pass_field`
                                                      )
                                                    : null
                                            "
                                            :hasSuccess="passed"
                                            name="password"
                                            label="Password"
                                            v-model="password"
                                        >
                                        </fg-input>
                                    </ValidationProvider>
                                </div>
                                <div class="text-center">
                                    <button
                                        v-if="!loading"
                                        type="submit"
                                        class="btn btn-fill btn-info btn-round btn-wd"
                                    >
                                        Login
                                    </button>
                                    <button
                                        v-else-if="
                                            loading && !selectMainCustomer
                                        "
                                        type="button"
                                        disabled
                                        class="btn btn-fill btn-default btn-round btn-wd"
                                    >
                                        <i class="fas fa-spinner fa-spin"></i>
                                    </button>
                                    <div v-else class="w-full text-center">
                                        <span
                                            class="sys-bg-green text-white rounded-full px-3 py-2 text-center"
                                        >
                                            <i class="fas fa-arrow-down"></i>
                                        </span>
                                    </div>
                                </div>

                                <!-- MAIN SELECTION -->
                                <div v-if="selectMainCustomer" class="pt-3">
                                    <div class="form-group has-label">
                                        <label>{{
                                            $t("login.fields.mainCustomer")
                                        }}</label>
                                        <el-select
                                            class="mainCustomerSelect"
                                            v-model="mainCustomer"
                                            filterable
                                            size="medium"
                                        >
                                            <el-option
                                                v-for="item in mainCustomers"
                                                :key="item.value"
                                                :label="item.label"
                                                :value="item.value"
                                            >
                                            </el-option>
                                        </el-select>

                                        <div class="py-4 text-center">
                                            <el-button
                                                v-if="!loading2"
                                                type="button"
                                                :disabled="!mainCustomer"
                                                @click.prevent="enterApp"
                                                class="btn btn-fill btn-success btn-round"
                                            >
                                                {{ $t("login.enter") }}
                                            </el-button>
                                            <button
                                                v-else
                                                type="button"
                                                disabled
                                                class="btn btn-fill btn-default btn-round"
                                            >
                                                <i
                                                    class="fas fa-spinner fa-spin"
                                                ></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </card>
                        </fade-render-transition>
                    </form>
                </ValidationObserver>
            </div>
        </div>
    </auth-layout>
</template>
<script>
import { getters, mutations, actions } from "@/store";
import { FadeRenderTransition } from "@/components/index";
import AuthLayout from "@/pages/Dashboard/AuthLayout.vue";
import { extend } from "vee-validate";
import { required, email, min } from "vee-validate/dist/rules";

extend("email", email);
extend("required", required);
extend("min", min);

export default {
    components: {
        FadeRenderTransition,
        AuthLayout,
    },
    computed: {
        title: getters.title,
        user: getters.user.data,
    },
    data() {
        return {
            email: "",
            password: "",
            subscribe: true,
            loading: false,
            loading2: false,
            selectMainCustomer: false,
            mainCustomers: [],
            mainCustomer: null,
        };
    },
    methods: {
        async submit() {
            // try {
                this.loading = true;

                await actions.user.login({
                    email: this.email,
                    password: this.password,
                });

                if (this.user && this.user.parentClients) {
                    this.mainCustomers = this.user.parentClients.map((el) => {
                        return {
                            label: el.name,
                            value: el.id_parent,
                        };
                    });
                    this.selectMainCustomer = true;
                }
            // } catch (error) {
            //     this.$notify.error({
            //         title: "Error 1",
            //         message: error.message,
            //     });
            //     this.loading = false;
            // }
        },

        async enterApp() {
            try {
                if (!this.mainCustomer) {
                    this.$throwError({
                        type: "LoginError",
                        message: "Invalid username or password",
                        status: 400,
                    });
                }

                this.loading2 = true;

                mutations.user.setMainCustomer(this.mainCustomer);

                await actions.user.getVerticalCustomers(this.mainCustomer);

                this.$router.go({ name: "home" });
            } catch (error) {
                this.loading2 = false;

                this.$notify.error({
                    title: "Error 2",
                    message: error.message,
                });
            }
        },

        toggleNavbar() {
            document.body.classList.toggle("nav-open");
        },
        closeMenu() {
            document.body.classList.remove("nav-open");
            document.body.classList.remove("off-canvas-sidebar");
        },
    },
    beforeDestroy() {
        this.closeMenu();
    },
};
</script>
<style lang="scss">
.navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
}

.mainCustomerSelect {
    border: none;
    width: 100%;

    input {
        border: 1px solid #e3e3e3 !important;
        border-radius: 4px !important;
    }
}
</style>
