<template>
    <div class="ReportList">
        <data-tables-server
            ref="ReportsTable"
            class="datatable"
            :key="reportsListKey"
            :loading="loading"
            :table-props="tableProps"
            :data="reportsList.reports"
            :total="parseInt(reportsList.total) || 0"
            :current-page="parseInt(reportsList['page']) || 1"
            :page-size="parseInt(reportsList['pageSize']) || recordPerPage"
            :pagination-props="{ pageSizes: paginations }"
            @query-change="getData"
        >
            <el-table-column align="center" type="selection" width="55">
            </el-table-column>
            <el-table-column
                v-for="column in columns"
                :key="column.prop"
                :label="column.label"
                :prop="column.prop"
                :sortable="column.sortable"
                :align="column.align"
                :width="column.width || 'auto'"
            >
                <template slot-scope="scope">
                    <span :set="(data = renderJdata(scope.row.json_report))">
                        <span v-if="column.prop === 'customer'">
                            {{ data.customer_name }}
                        </span>
                        <span v-else-if="column.prop === 'venue'">
                            {{ data.report_venue_description }}
                        </span>
                        <span v-else-if="column.prop === 'type'">
                            {{ data.report_type_description }}
                        </span>
                        <span v-else-if="column.prop === 'scope'">
                            {{
                                data.interventions &&
                                data.interventions.length > 0
                                    ? data.interventions
                                          .map(
                                              (el) => el.report_scope_desciption
                                          )
                                          .join(",")
                                    : ""
                            }}
                        </span>
                        <span v-else-if="column.prop === 'approval_sent_at'">
                            {{ scope.row.approval_sent_at }}
                        </span>
                        <span v-else-if="column.prop === 'status'">
                            <div>
                                <i
                                    class="fas fa-eye mr-2 text-yellow-600"
                                    v-if="scope.row.approval_sent"
                                />
                                <i
                                    class="fas fa-thumbs-up mr-2 text-green-600"
                                    v-if="scope.row.approved_at"
                                />
                            </div>
                        </span>
                        <span v-else-if="column.prop === 'materials'">
                            <i
                                class="fas fa-laptop"
                                v-if="
                                    data.materials && data.materials.length > 0
                                "
                            />
                        </span>
                        <span v-else>{{ scope.row[column.prop] }}</span>
                    </span>
                </template>
            </el-table-column>
            <el-table-column
                :label="$t('reports.table.actions')"
                width="180px"
                align="center"
                fixed="right"
            >
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        icon="fas fa-search"
                        @click="handleview(scope.row)"
                    />
                    <el-button
                        size="mini"
                        icon="fas fa-file-pdf"
                        @click="handleview(scope.row)"
                    />
                    <el-popconfirm
                        v-if="
                            !scope.row.approved_at && !scope.row.approval_sent
                        "
                        class="ml-2"
                        :confirm-button-text="$t('reports.table.menu.delOk')"
                        :cancel-button-text="$t('reports.table.menu.delKo')"
                        icon="el-icon-info"
                        icon-color="red"
                        :title="$t('reports.table.menu.confirmDelete')"
                        @confirm="handleview(scope.row)"
                    >
                        <el-button
                            slot="reference"
                            size="mini"
                            icon="fas fa-trash"
                        />
                    </el-popconfirm>
                </template>
            </el-table-column>
        </data-tables-server>

        <div v-if="currentTicket">
            <MainModal />
        </div>
    </div>
</template>

<script>
import qs from "query-string";
import constants from "@/store/constants";
import BaseMixin from "@/mixins/Base.js";
// import { EventBus } from "@/store/eventBus";
import MainModal from "@/entities/reports/modal/MainModal";
import { getters, mutations, actions } from "@/store";

export default {
    name: "ReportList",

    mixins: [BaseMixin],

    components: {
        MainModal,
    },

    data() {
        return {
            constants,
            loading: false,
            dialogVisible: false,
            tableProps: {
                border: true,
                stripe: true,
                defaultSort: {
                    prop: "id",
                    order: "descending",
                },
            },
            search: "",
            currentTableQuery: null,
            reportsListKey: 0,
            cond: {},
            reportsList: [],
            currentTicketId: null,
        };
    },

    computed: {
        recordPerPage: getters.recordPerPage,
        paginations: getters.paginations,
        user: getters.user.data,
        filters: getters.reports.filters,
        mainCustomer_id: getters.user.mainCustomer,
        currentTicket: getters.ticket.currentTicket,
        mainCustomer: () => {
            const x = getters.user
                .data()
                .parentClients.filter(
                    (c) =>
                        parseInt(c.id_parent) ===
                        parseInt(getters.user.mainCustomer())
                );

            return x[0].name.replace(/\s/g, "").toLowerCase();
        },
    },

    created() {
        this.columns = [
            {
                prop: "idExtReport",
                label: "ID",
                sortable: "custom",
                align: "center",
            },
            {
                prop: "created_at",
                label: this.$t("reports.table.create_date"),
                sortable: "custom",
                align: "center",
            },
            {
                prop: "customer",
                label: this.$t("reports.table.customer"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "venue",
                label: this.$t("reports.table.venue"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "type",
                label: this.$t("reports.table.type"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "scope",
                label: this.$t("reports.table.scope"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "status",
                label: this.$t("reports.table.status.title"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "approval_sent_at",
                label: this.$t("reports.table.approval_sent_at"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
            {
                prop: "materials",
                label: this.$t("reports.table.materials"),
                sortable: "custom",
                align: "center",
                width: 120,
            },
        ];

        this.columns.map((el) => (el.width = 180));
    },

    watch: {
        filters: function () {
            this.setConditions();
            this.reportsListKey++;
        },
    },

    methods: {
        async handleview(row) {
            mutations.ticket.setCurrentTicket(null);
            //recupero l'id del ticket
            this.currentTicketId = row.json_report.ticket_id;
            await actions.ticket.getTicket({ id: this.currentTicketId });
            mutations.reports.setModalOpen(true);
        },

        renderJdata(data) {
            return data;
        },

        async getData(queryInfo) {
            try {
                this.loading = true;

                const qp = {
                    limit: queryInfo.pageSize,
                    page: queryInfo.page,
                    orderBy: queryInfo.sort.prop,
                    orderType:
                        queryInfo.sort.order === "ascending" ? "asc" : "desc",
                };

                let url = `/report/${this.mainCustomer}?${qs.stringify(qp)}`;
                if (this.search.trim() !== "") {
                    url += `&term=${this.search}`;
                }

                this.reportsList = await actions.reports.getReports(
                    url,
                    this.cond
                );

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        setConditions() {
            if (this.filters) {
                /* let cond = {
                    parent_client_id: [this.mainCustomer_id],
                }; */
                let cond = {};

                if (this.filters.idExtReport) {
                    cond.idExtReport = this.filters.idExtReport;
                }

                if (
                    this.filters.customers &&
                    this.filters.customers.length > 0
                ) {
                    cond.arca_codes = this.filters.customers;
                }

                if (this.filters.users && this.filters.users > 0) {
                    cond.user_ids = this.filters.users;
                }

                if (this.filters.is_approved) {
                    cond.is_approved = this.filters.is_approved;
                }

                if (this.filters.range && this.filters.range > 0) {
                    cond.data_range_creation = this.filters.range;
                }

                this.cond = { ...cond };
            }
        },
    },
};
</script>
