<template>
    <div class="flex items-top" v-if="currentTicket">
        <div class="flex-initial">
            <el-page-header
                @back="goBack"
                :title="$t('back')"
                :content="
                    $t('tickets.sheet.title', { ticket_id: currentTicket.id })
                "
            ></el-page-header>
        </div>
        <div class="flex-initial"></div>
    </div>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "TicketSheetMenu",

    computed: {
        currentTicket: getters.ticket.currentTicket,

        /* ticket_id() {
            return this.$route.params.ticket_id;
        } */
    },

    methods: {
        goBack() {
            this.$router.replace({
                name: "tickets_list",
                params: { cache: true },
            });
        },
    },
};
</script>
