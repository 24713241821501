"use strict";

import Vue from "vue";
import VueI18n from "vue-i18n";

import { getters } from "@/store";

import enLocale from "element-ui/lib/locale/lang/en";
import itLocale from "element-ui/lib/locale/lang/it";

import it_app from "@/entities/__app/i18n/it";
import en_app from "@/entities/__app/i18n/en";
import it_user from "@/entities/user/i18n/it";
import en_user from "@/entities/user/i18n/en";
import it_tickets from "@/entities/tickets/i18n/it";
import en_tickets from "@/entities/tickets/i18n/en";
import it_knowledge from "@/entities/knowledge/i18n/it";
import en_knowledge from "@/entities/knowledge/i18n/en";
import it_administration from "@/entities/administration/i18n/it";
import en_administration from "@/entities/administration/i18n/en";
import it_reports from "@/entities/reports/i18n/it";
import en_reports from "@/entities/reports/i18n/en";

Vue.use(VueI18n);

const messages = {
    en: {
        hello: "hello",
        ...enLocale,
        ...en_app,
        ...en_user,
        ...en_tickets,
        ...en_knowledge,
        ...en_administration,
        ...en_reports,
    },
    it: {
        hello: "ciao",
        ...itLocale,
        ...it_app,
        ...it_user,
        ...it_tickets,
        ...it_knowledge,
        ...it_administration,
        ...it_reports,
    },
};

export default new VueI18n({
    locale: getters.locale() || "it",
    messages,
});
