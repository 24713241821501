export default {
    login: {
        lost_pass: "Password dimenticata?",

        enter: "entra",

        errors: {
            email_field: "Campo email obbligatorio",
            pass_field: "Campo password obbligatorio",
        },

        fields: {
            mainCustomer: "Seleziona la ditta",
        },
    },
};
