<template>
    <div class="grid grid-cols-3  gap-4">
        <el-card class="box-card" v-for="domain in whitelistEntries" :key="domain.id">
            <div class="flex">
                <div class="flex-1">
                    {{ domain.name }}
                </div>
                <div class="flex-initial">
                    <el-popconfirm
                        :title="$t('administration.whitelist.prompt.confirmDelete')"
                        :confirm-button-text="$t('administration.whitelist.prompt.ok')"
                        :cancel-button-text="$t('administration.whitelist.prompt.ko')"
                        @confirm="deleteDomain(domain)"
                        v-if="$iCan(constants.permissions.administration.update_category)"
                    >
                        <el-button
                            slot="reference"
                            type="default"
                            icon="fas fa-trash"
                        />
                    </el-popconfirm>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import { getters, actions } from '@/store';
import constants from '@/store/constants';

export default {
    name: 'AdminWitelistMain',

    computed: {
        whitelist: getters.administration.whitelist
    },

    watch: {
        whitelist: function(val) {
            this.setWhitelistEntries(val);
        }
    },

    data() {
        return {
            constants,
            whitelistEntries: []
        }
    },

    async mounted() {
        await actions.administration.getWhitelist();

        this.setWhitelistEntries(this.whitelist);

        EventBus.$on('updateWhitelist', async () => {
            await actions.administration.getWhitelist();
        });
    },

    beforeDestroy() {
        EventBus.$off('updateWhitelist');
    },

    methods: {
        setWhitelistEntries(val) {
            const v = this.$copyStatic(val);

            this.whitelistEntries = v.map(el => {
                return el;
            });
        },

        async deleteDomain(domain) {
            try {
                /* await actions.administration.deleteCategory({
                    category_id: category.id
                });

                await actions.ticket.getCategories(); */
                console.log(domain);

                this.$notify.success(this.$t('administration.whitelist.prompt.operationSuccess'));
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        }
    }
}
</script>

