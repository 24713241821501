export default {
    knowledge: {
        newFaq: 'inserisci nuova FAQ',

        filter: {
            title: 'Cerca un termine nelle FAQ'
        },

        faq: {
            q: 'Domanda',
            r: 'Risposta',
            lang: 'Lingua',
            save: 'salva',
            cancel: 'annulla',
            operationSuccess: 'Operazione completata',
            operationAborted: 'Operazione annullata'
        },

        modal: {
            titleNew: 'Nuova FAQ',
            titleMod: 'Modifica FAQ'
        },

        prompt: {
            ok: 'salva',
            ko: 'annulla',
            del: 'elimina',
            sel: 'seleziona',
            operationSuccess: 'Operazione completata',
            operationAborted: 'Operazione annullata',
            confirmSave: 'Confermi i dati da salvare?',
            confirmDelete: 'Confermi la cancellazione di questa faq?'
        },

        errors: {
            faq_question: 'La domanda è obbligatoria',
            faq_response: 'La risposta è obbligatoria'
        }
    }
};
