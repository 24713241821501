<template>
    <div>
        <div>
            <p>{{$t('knowledge.filter.title')}}</p>
            <el-input v-model="filter" placeholder=""></el-input>
        </div>

        <div v-if="faqs.length > 0" class="py-4">
            <el-card v-for="faq in faqs" :key="faq.id" class="mb-4">
                <blockquote class="relative p-4 text-xl italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote">
                    <p className="mb-4">&ldquo;{{ faq.data.q }}&rdquo;</p>
                </blockquote>
                <div class="py-4" v-html="faq.data.r"></div>
                <div class="text-right">
                    <el-button
                        class="mr-2"
                        type="primary"
                        icon="fas fa-pen"
                        @click="modFaq(faq)"
                        v-if="$iCan(constants.permissions.knowledge.update_faq)"
                    />
                    <el-popconfirm
                        :title="$t('knowledge.prompt.confirmDelete')"
                        :confirm-button-text="$t('knowledge.prompt.ok')"
                        :cancel-button-text="$t('knowledge.prompt.ko')"
                        @confirm="delFaq(faq)"
                        v-if="$iCan(constants.permissions.knowledge.delete_faq)"
                    >
                        <el-button
                            slot="reference"
                            type="default"
                            icon="fas fa-trash"
                        />
                    </el-popconfirm>
                </div>
            </el-card>
        </div>

        <el-dialog
            :title="$t('knowledge.modal.titleMod')"
            :visible.sync="dialogVisible"
            :before-close="exit"
            width="50%"
        >
            <KnowledgeForm></KnowledgeForm>
        </el-dialog>
    </div>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import KnowledgeForm from '@/entities/knowledge/KnowledgeForm.vue';
import { getters, mutations, actions } from '@/store';
import constants from '@/store/constants';

export default {
    name: 'KnowledgeMain',

    computed: {
        locale: getters.locale
    },

    data() {
        return {
            constants,
            dialogVisible: false,
            filter: '',
            loading: false,
            faqs: []
        }
    },

    components: {
        KnowledgeForm
    },

    watch: {
        filter: async function(val) {
            if (val.trim().length > 3) {
                const res = await actions.knowledge.getFaqs({
                    query: {
                        term: this.filter
                    },
                    payload: {
                        locale: this.locale.toUpperCase()
                    }
                });

                this.faqs = res.data.data;

            } else {
                this.faqs = [];
                await this.setFaqs();
            }
        },

        locale: async function(val) {
            console.log(val);
            const res = await actions.knowledge.getFaqs({
                query: {
                    term: this.filter
                },
                payload: {
                    locale: val.toUpperCase()
                }
            });

            this.faqs = res.data.data;
        }
    },

    async mounted() {
        await this.setFaqs();

        EventBus.$on('exitKnowledgeForm', () => this.exit());
        EventBus.$on('updateFaqs', async () => {
            await this.setFaqs();
        });
    },

    beforeDestroy() {
        EventBus.$off('exitKnowledgeForm');
        EventBus.$off('updateFaqs');
    },

    methods: {
        async setFaqs() {
            const q = {
                query: {
                    limit: 5
                },
                payload: {
                    locale: this.locale.toUpperCase()
                }
            };

            const res = await actions.knowledge.getFaqs(q);
            this.faqs = res.data.data;
        },

        modFaq(faq) {
            faq.faq_id = faq.id;
            mutations.knowledge.setFaq(faq);
            this.dialogVisible = true;
        },

        async delFaq(faq) {
            try {
                await actions.knowledge.deleteFaq({
                    faq_id: faq.id
                });

                await this.setFaqs();

                this.$notify.success(this.$t('knowledge.prompt.operationSuccess'));
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        exit() {
            this.dialogVisible = false;
        }
    }
}
</script>
