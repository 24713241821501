<template>
    <el-drawer
        v-if="currentTicket"
        :visible.sync="isOpen"
        :direction="direction"
        :destroy-on-close="true"
    >
        <template v-slot:title>
            <span class="font-bold">{{ $t('tickets.drawer.actions.title') }}</span>
        </template>
        <div class="text-note-2 p-2 text-xs">
            # {{currentTicket.id}}
        </div>

        <div class="p-2 bg-gray-200 font-bold" v-if="(isMine || isAssignedToMe) && $iCan(constants.permissions.tickets.view_action_menu_ticket)">
            <el-checkbox v-model="in_progress">{{$t('tickets.status.in_progress')}}</el-checkbox>
            <el-checkbox v-model="wait_feedback">{{$t('tickets.status.wait_feedback')}}</el-checkbox>
        </div>

        <el-tabs v-model="activeName" class="px-4">
            <el-tab-pane
                :label="$t('tickets.drawer.actions.sections.configTitle')"
                name="first"
                v-if="(isMyCategory || isMine || isAssignedToMe) && $iCan(constants.permissions.tickets.view_configuration_menu_ticket)"
            >
                <ticket-menu-config></ticket-menu-config>
            </el-tab-pane>
            <el-tab-pane
                :label="$t('tickets.drawer.actions.sections.actionsTitle')"
                name="second"
                v-if="(isMyCategory || isMine || isAssignedToMe) && $iCan(constants.permissions.tickets.view_action_menu_ticket)"
            >
                <ticket-menu-operations></ticket-menu-operations>
            </el-tab-pane>
        </el-tabs>
    </el-drawer>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import { getters, actions } from "@/store";
import constants from '@/store/constants';
import TicketMenuConfig from './TicketMenuConfig';
import TicketMenuOperations from './TicketMenuOperations';

export default {
    name: 'TicketMenuLayout',

    components: {
        TicketMenuConfig,
        TicketMenuOperations
    },

    computed: {
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket,
        ticketInProgress: getters.ticket.ticketInProgress,
        ticketWaitFeedback: getters.ticket.ticketWaitFeedback,

        isMine() {
            return this.currentTicket && this.currentTicket.user_id === this.user.id;
        },

        isAssignedToMe() {
            return this.currentTicket && this.currentTicket.assigned_to === this.user.id;
        },

        isMyCategory() {
            if (this.currentTicket) {
                const check = this.user.categories.filter(el => parseInt(el.id) === parseInt(this.currentTicket.category_id));

                return check.length > 0;
            }

            return false;
        },

        activeName: {
            get() {
                if (
                    this.$iCan(constants.permissions.tickets.view_configuration_menu_ticket) &&
                    this.$iCan(constants.permissions.tickets.view_action_menu_ticket)
                ) {
                    return 'first'
                }

                if (
                    this.$iCan(constants.permissions.tickets.view_configuration_menu_ticket) &&
                    !this.$iCan(constants.permissions.tickets.view_action_menu_ticket)
                ) {
                    return 'first'
                }

                if (
                    !this.$iCan(constants.permissions.tickets.view_configuration_menu_ticket) &&
                    this.$iCan(constants.permissions.tickets.view_action_menu_ticket)
                ) {
                    return 'second'
                }

                return null;
            },

            set() {

            }
        },

        in_progress: {
            get() {
                if (this.currentTicket) {
                    return this.ticketInProgress.id === this.currentTicket.status_id;
                }

                return false;
            },
            set() {
                //if (val) {
                    this.$confirm(this.$t('tickets.drawer.actions.confirmInProgress'), 'Warning', {
                        confirmButtonText: this.$t('tickets.drawer.actions.save'),
                        cancelButtonText: this.$t('tickets.drawer.actions.cancel'),
                        type: 'warning'
                    }).then(async () => {
                        await this.setInProgress();
                        this.$message({
                            type: 'success',
                            message: this.$t('tickets.drawer.actions.successInProgress')
                        });
                    }).catch(err => console.log(err));
                //}
            }
        },

        wait_feedback: {
            get() {
                if (this.currentTicket) {
                    return this.ticketWaitFeedback.id === this.currentTicket.status_id;
                }

                return false;
            },
            set() {
                //if (val) {
                    this.$confirm(this.$t('tickets.drawer.actions.confirmWaitFeedback'), 'Warning', {
                        confirmButtonText: this.$t('tickets.drawer.actions.save'),
                        cancelButtonText: this.$t('tickets.drawer.actions.cancel'),
                        type: 'warning'
                    }).then(async () => {
                        await this.setWaitFeedback();
                        this.$message({
                            type: 'success',
                            message: this.$t('tickets.drawer.actions.successWaitFeedback')
                        });
                    }).catch(err => console.log(err));
                //}
            }
        }
    },

    data() {
        return {
            direction: 'rtl',
            isOpen: false,
            constants
        };
    },

    mounted() {
        EventBus.$on('toggleTicketActionMenu', val => this.isOpen = val);
    },

    beforeDestroy() {
        EventBus.$off('toggleTicketActionMenu');
    },

    methods: {
        async setInProgress() {

            console.log('>>>>>>>>', this.currentTicket.status_id, this.ticketInProgress)

            try {
                await actions.ticket.inProgressTicket({
                    ticket_id: this.currentTicket.id,
                    in_progress: this.currentTicket.status_id === this.ticketInProgress.id
                });

                EventBus.$emit('updateTicketList', true);
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async setWaitFeedback() {

            console.log('>>>>>>>>', this.currentTicket.status_id, this.ticketWaitFeedback)

            try {
                await actions.ticket.waitFeedbackTicket({
                    ticket_id: this.currentTicket.id,
                    wait_feedback: this.currentTicket.status_id === this.ticketWaitFeedback.id
                });

                EventBus.$emit('updateTicketList', true);
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },
    }
}
</script>
