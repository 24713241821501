<template>
    <el-button type="success" @click="addBlacklistDomain">
        <i class="fas fa-plus mr-2"></i>
        {{ $t("administration.blacklist.new") }}
    </el-button>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { actions } from "@/store";

export default {
    name: "AdminWitelistTopMenu",

    methods: {
        addBlacklistDomain() {
            this.$prompt(
                this.$t("administration.blacklist.prompt.text"),
                this.$t("administration.blacklist.prompt.title"),
                {
                    confirmButtonText: this.$t(
                        "administration.blacklist.prompt.ok"
                    ),
                    cancelButtonText: this.$t(
                        "administration.blacklist.prompt.ko"
                    ),
                    inputPattern: /^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                    inputErrorMessage: this.$t(
                        "administration.blacklist.prompt.validationError"
                    ),
                }
            )
                .then(async ({ value }) => {
                    await actions.administration.addBlacklistEntry({
                        email: value,
                    });

                    EventBus.$emit("updateBlacklist", true);

                    this.$message({
                        type: "success",
                        message: this.$t(
                            "administration.blacklist.prompt.operationSuccess"
                        ),
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: this.$t(
                            "administration.blacklist.prompt.operationAborted"
                        ),
                    });
                });
        },
    },
};
</script>
