<template>
    <div>
        <div class="text-right pb-4">
            <AdminRolesNew />
        </div>
        <div class="grid grid-cols-3 gap-4">
            <el-card
                class="box-card text-xs"
                v-for="role in roleList"
                :key="role.slug"
            >
                <el-input v-model="role.name" placeholder=""></el-input>
                <div class="p-2" v-if="permissionList">
                    <div
                        v-for="(permissions, permissionBlock) in permissionList"
                        :key="permissionBlock"
                    >
                        <p class="bg-gray-200 px-2 py-1 font-bold">
                            {{ permissionBlock }}
                        </p>
                        <el-checkbox-group v-model="role.permissions" class="">
                            <el-checkbox
                                class="block"
                                v-for="permission in permissions"
                                :key="permission.slug"
                                :label="permission.id"
                            >
                                {{
                                    $t(
                                        `administration.permissions.${permission.slug}`
                                    )
                                }}
                            </el-checkbox>
                        </el-checkbox-group>
                    </div>
                </div>
                <div class="text-right">
                    <el-popconfirm
                        :title="$t('administration.roles.prompt.confirmSave')"
                        :confirm-button-text="
                            $t('administration.roles.prompt.ok')
                        "
                        :cancel-button-text="
                            $t('administration.roles.prompt.ko')
                        "
                        @confirm="saveRole(role)"
                        v-if="
                            $iCan(
                                constants.permissions.administration.update_role
                            )
                        "
                    >
                        <el-button
                            slot="reference"
                            type="primary"
                            class="mr-2"
                            icon="fas fa-save"
                        />
                    </el-popconfirm>
                    <el-popconfirm
                        :title="$t('administration.roles.prompt.confirmDelete')"
                        :confirm-button-text="
                            $t('administration.roles.prompt.ok')
                        "
                        :cancel-button-text="
                            $t('administration.roles.prompt.ko')
                        "
                        @confirm="deleteRole(role)"
                        v-if="
                            $iCan(
                                constants.permissions.administration.delete_role
                            ) && !role.isFix
                        "
                    >
                        <el-button
                            slot="reference"
                            type="default"
                            class="mr-2"
                            icon="fas fa-trash"
                        />
                    </el-popconfirm>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import slugify from "slugify";
import { getters, actions } from "@/store";
import constants from "@/store/constants";
import socket from "@/store/socket";
import AdminRolesNew from "@/entities/administration/AdminRolesNew.vue";

export default {
    name: "AdminRolesMain",

    components: {
        AdminRolesNew,
    },

    computed: {
        roles: getters.user.roles,
        permissions: getters.user.permissions,
    },

    watch: {
        roles: function (val) {
            this.setRoleList(val);
        },

        permissions: function (val) {
            this.setPermissionList(val);
        },
    },

    data() {
        return {
            constants,
            roleList: [],
            permissionList: {},
        };
    },

    created() {
        this.setPermissionList(this.permissions);
        this.setRoleList(this.roles);
    },

    methods: {
        setRoleList(val) {
            const v = this.$copyStatic(val);

            this.roleList = v.map((el) => {
                if (el.permissions.length > 0) {
                    const pr = el.permissions.map((p) => {
                        return p ? p.id : null;
                    });

                    el.permissions = pr;
                }
                return el;
            });
        },

        setPermissionList(val) {
            const v = this.$copyStatic(val);
            const k = Object.keys(v);

            let o = {};

            for (let i = 0; i < k.length; i++) {
                const n = v[k[i]];

                if (!o[n.category]) {
                    o[n.category] = [];
                }

                o[n.category].push(n);
            }

            this.permissionList = o;
        },

        async saveRole(role) {
            try {
                if (!role.name || role.name.trim() === "") {
                    this.$notify.error(this.$t("tickets.errors.role"));
                    return false;
                }

                await actions.administration.saveRole({
                    role_id: role.id,
                    name: role.name,
                    slug: slugify(role.name),
                    permissions: role.permissions,
                });

                socket.emit("updating_role", role.id);
                this.setRoleList(this.roles);

                this.$notify.success(
                    this.$t("administration.roles.prompt.operationSuccess")
                );
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async deleteRole(role) {
            try {
                await actions.administration.deleteRole({
                    role_id: role.id,
                });

                socket.emit("updating_role", role.id);
                this.setRoleList(this.roles);

                this.$notify.success(
                    this.$t("administration.roles.prompt.operationSuccess")
                );
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },
    },
};
</script>
