export default {
    welcome: "Benvenuti",
    yes: "Sì",
    no: "No",
    search: "Cerca",
    select: "Seleziona",
    register: "Registra",
    print: "Stampa",
    back: "Indietro",
    save: "Salva",
    close: "Chiudi",
    operationCompleted: "Operazione completata",
    operationFailed: "Operazione fallita",
    operationCanceled: "Operazione annullata",

    menu: {
        activateNotifications: "ricevi notifiche via email",

        vertical: {
            tickets: {
                main: "Lista tickets",
                assigned: "Assegnati",
                todo: "Non assegnati",
                mine: "I miei tickets",
            },
            knowledge: {
                main: "Documentazione",
            },
            reports: {
                main: "Reports",
            },
            administration: {
                main: "Amministrazione",
                customers: {
                    main: "Clienti",
                },
                roles: {
                    main: "Ruoli e permessi",
                },
                categories: {
                    main: "Categorie",
                },
                users: {
                    main: "Utenti",
                },
                whitelist: {
                    main: "Whitelist email",
                },
                blacklist: {
                    main: "Blacklist email",
                },
            },
        },
    },
};
