export default {
    reports: {
        modal: {
            openBtn: {
                text: "report",
            },
            title: "Registra il report associato a questo ticket",
            form: {
                steps: {
                    step1: {
                        title: "Informazioni generali",
                        description: "",
                    },
                    step2: {
                        title: "Attività svolte",
                        description: "",
                    },
                    step3: {
                        title: "Materiali utilizzati",
                        description: "opzionale",
                    },
                },
                mainCustomer: "Cliente principale",
                customer: "Cliente",
                date: "Data",
                location: "Luogo intervento",
                traveltime: "Tempo di viaggio",
                type: "Tipo",
                scopeBlock: {
                    scope: "Tipo di attività",
                    from: "Dalle ore",
                    to: "Alle ore",
                    description: "Descrizione",
                },
                addScopeBlock: "Aggiungi attività",
                registerReport: "Registra il report",
                saveToApprove: "Salva per approvazione",
                registerDialog: {
                    confirm: "Conferma",
                    cancel: "Annulla",
                    title: "Vuoi registrare questo report?",
                },
                approveDialog: {
                    confirm: "Conferma",
                    cancel: "Annulla",
                    title: "Vuoi inviare questo report per l'approvazione? Una volta inviato non potrai più modificarlo.",
                },
                scopeBlockName: "Attività #{n}",
                notifications:
                    "Email per invio notifiche (scrivi e premi invio per aggiungere)",
                material: {
                    add: "aggiungi materiale",
                    register: "Registra materiale",
                    required: "Questo campo è obbligatorio",
                    save: "Salva",
                    cancel: "Annulla",
                    error: "Errore nell aregistrazione del materiale",
                    code: "Codice",
                    serial: "Seriale",
                    material: "Materiale",
                    quantity: "Q.tà",
                    price: "Prezzo",
                    actions: "Azioni",
                },
                save: {
                    success: "Operazione avvenuta con successo",
                },
            },
            rules: {
                required: "Campo obbligatorio",
            },
            activity: {
                title: "Attività svolte",
                totHours: "Ore totali",
            },
        },

        main: {
            t1: "Report registrati",
            t2: "Verifica report",
            filters: {
                title: "Filtri",
                customers: "Clienti",
                location: "Luogo dell'intervento",
                users: "Tecnici",
                scope: "Scopo",
                type: "Tipo",
                state: "Stato",
                toapprove: "da approvare",
                approved: "approvato",
                forTime: "Range temporale",
                receivedFrom: "Data inizio",
                receivedTo: "Data fine",
                receivedSeparator: "al",
                setFilters: "imposta i filtri",
                delFilters: "cancella i filtri",
            },
        },

        table: {
            create_date: "Data di registrazione",
            customer: "Cliente",
            venue: "Luogo intervento",
            type: "Tipo",
            scope: "Scopo",
            approval_sent_at: "Invio per approvazione",
            materials: "Materiali",
            actions: "Azioni",
            status: {
                title: "Stato",
                report: "report registrato",
                toapprove: "in approvazione",
                approved: "approvato",
            },
            menu: {
                confirmDelete: "Confermi la cancellazione del report?",
                delOk: "Sì",
                delKo: "No",
            },
        },
    },
};
