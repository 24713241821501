"use strict";

import Vue from "vue";
import VueRouter from "vue-router";
import { localStore } from "@/store/localStore";
import { getters } from "@/store";

import ticketsRoutes from "@/entities/tickets/routes";
import knowledgeRoutes from "@/entities/knowledge/routes";
import administrationRoutes from "@/entities/administration/routes";
import reportsRoutes from "@/entities/reports/routes";

import NotFound from "@/pages/GeneralViews/NotFoundPage.vue";

Vue.use(VueRouter);

let routes = [
    {
        path: "/login",
        name: "login",
        component: () =>
            import(/* webpackChunkName: "login" */ "@/entities/user/Login.vue"),
    },
    ticketsRoutes,
    knowledgeRoutes,
    administrationRoutes,
    reportsRoutes,
    { path: "*", component: NotFound },
];

/**
 * primo filtro sulla gestione dei ruoli: visibilità
 */
routes = routes.filter((r) => {
    if (!r.role) {
        return true;
    }

    return getters.user.roles.some((rl) => rl.slug === r.role);
});

// console.log(routes)

const router = new VueRouter({
    routes,
    linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
    if (getters.user.token() && getters.user.mainCustomer()) {
        if (to.name === "login") {
            next({ name: "tickets_list" });
        }

        next();
    } else {
        localStore.clearAll();

        if (to.name !== "login") {
            next({ name: "login" });
        }

        next();
    }
});

export default router;
