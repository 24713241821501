<template>
    <div v-if="faq">
        <div class="pb-4">
            <p>{{$t('knowledge.faq.q')}}</p>
            <el-input v-model="faq.data.q" type="textarea" class="custom-input" :rows="3"></el-input>
        </div>
        <div class="pb-4">
            <p>{{$t('knowledge.faq.r')}}</p>
            <vue-editor
                class="custom-input"
                v-model="faq.data.r"
                :editorToolbar="customToolbar"
                useCustomImageHandler
                @image-added="handleImageAdded"
            />
        </div>
        <div class="pb-4">
            <p>{{$t('knowledge.faq.lang')}}</p>
            <el-radio v-model="faq.locale" label="IT">IT</el-radio>
            <el-radio v-model="faq.locale" label="EN">EN</el-radio>
        </div>
        <div class="text-right">
            <el-button
                type="primary"
                @click="saveFaq"
            >
                {{ $t('knowledge.faq.save') }}
            </el-button>
            <el-button
                class="mr-2"
                type="default"
                @click="exit"
            >
                {{ $t('knowledge.faq.cancel') }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import { getters, mutations, actions } from "@/store";
import { VueEditor } from 'vue2-editor';
import { localStore } from '@/store/localStore';
import apiLib from '@/store/api';
import DOMPurify from 'dompurify';

const api = apiLib(localStore);

export default {
    name: 'KnowledgeForm',

    components: {
        VueEditor
    },

    data() {
        return {
            customToolbar: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['image', 'link', 'code-block'],
                ['clean']
            ]
        }
    },

    computed: {
        locale: getters.locale,
        faq: getters.knowledge.faq
    },

    mounted() {
        this.faq.locale = this.locale.toUpperCase();
    },

    methods: {
        async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('owner_id', this.user.id);
            formData.append('owner_type', 'Tickets');

            try {
                const res = await api.post(`/attachments`, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });

                const url = `${process.env.VUE_APP_API_URL.replace('/api', '')}/${res.data.path}/${res.data.filename}`;
                Editor.insertEmbed(cursorLocation, 'image', url);
                resetUploader();
            } catch (error) {
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async saveFaq() {
            try {
                if (!this.formValidation()) {
                    return false;
                }

                if (this.faq.data.q.trim() !== '' && this.faq.data.r.trim() !== '') {
                    this.faq.data.q = DOMPurify.sanitize( this.faq.data.q , {USE_PROFILES: {html: true}} );
                    this.faq.data.r = DOMPurify.sanitize( this.faq.data.r , {USE_PROFILES: {html: true}} );

                    await actions.knowledge.saveFaq(this.faq);

                    EventBus.$emit('updateFaqs', true);

                    this.$notify.success(this.$t('knowledge.faq.operationSuccess'));
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        exit() {
            mutations.knowledge.setFaq(null);
            EventBus.$emit('exitKnowledgeForm', true);
        },

        formValidation() {
            let res = true;

            if (!this.faq.data.q || this.faq.data.q.trim() === '') {
                this.$notify.error(this.$t('tickets.errors.faq_question'));
                res = false;
            }
            if (!this.faq.data.r || this.faq.data.r.trim() === '') {
                this.$notify.error(this.$t('tickets.errors.faq_response'));
                res = false;
            }

            return res;
        }
    }
}
</script>
