<template>
    <div v-if="currentUser">
        <div class="flex">
            <div class="flex-initial px-4">
                <avatar :username="currentUser.username"></avatar>
                <small>{{currentUser.id}}</small>
            </div>
            <div class="flex-1">
                <el-input class="mb-2" v-model="currentUser.username" placeholder="" clearable>
                    <template slot="prepend">
                        <i class="fas fa-user"></i>
                    </template>
                </el-input>

                <el-input class="mb-2" v-model="currentUser.email" placeholder="" clearable>
                    <template slot="prepend">
                        <i class="fas fa-envelope"></i>
                    </template>
                </el-input>

                <el-input class="mb-2" v-model="currentUser.password" type="password" v-if="!currentUser.id" clearable>
                    <template slot="prepend">
                        <i class="fas fa-key"></i>
                    </template>
                </el-input>

                <div class="mb-2">
                    <p class="pb-2">{{$t('administration.users.sheet.customer')}}</p>
                    <el-select
                        v-model="currentUser.customers"
                        filterable
                        multiple
                        clearable
                        value-key="id"
                        class="w-full"
                        :placeholder="$t('administration.users.prompt.sel')"
                    >
                        <el-option
                            v-for="customer in customers"
                            :key="customer.id"
                            :label="customer.name"
                            :value="customer"
                        />
                    </el-select>
                </div>

                <div class="mb-2">
                    <p class="pb-2">{{$t('administration.users.sheet.role')}}</p>
                    <el-select
                        class="w-full"
                        v-model="currentUser.roles"
                        clearable
                        multiple
                        value-key="id"
                        :placeholder="$t('administration.users.prompt.sel')"
                        :multiple-limit="1"
                    >
                        <el-option
                            v-for="role in roles"
                            :key="role.id"
                            :label="role.name"
                            :value="role"
                        />
                    </el-select>
                </div>

                <div class="mb-2">
                    <p class="pb-2">{{$t('administration.users.sheet.category')}}</p>
                    <el-select
                        class="w-full"
                        clearable
                        v-model="currentUser.categories"
                        multiple
                        value-key="id"
                        :placeholder="$t('administration.users.prompt.sel')"
                    >
                        <el-option
                            v-for="category in categories"
                            :key="category.id"
                            :label="category.name"
                            :value="category"
                        />
                    </el-select>
                </div>
            </div>
        </div>

        <div class="text-right">
            <el-popconfirm
                :title="$t('administration.users.prompt.confirmSave')"
                :confirm-button-text="$t('administration.users.prompt.ok')"
                :cancel-button-text="$t('administration.users.prompt.ko')"
                @confirm="saveUser(currentUser)"
            >
                <el-button
                    slot="reference"
                    type="primary"
                    class="mr-2"
                >
                    {{ $t('administration.users.prompt.ok') }}
                </el-button>
            </el-popconfirm>
            <el-popconfirm
                v-if="$iCan(constants.permissions.administration.delete_user)"
                :title="$t('administration.users.prompt.confirmDelete')"
                :confirm-button-text="$t('administration.users.prompt.ok')"
                :cancel-button-text="$t('administration.users.prompt.ko')"
                @confirm="deleteUser(currentUser)"
            >
                <el-button
                    slot="reference"
                    type="info"
                    class="mr-2"
                >
                    {{ $t('administration.users.prompt.del') }}
                </el-button>
            </el-popconfirm>
            <el-button
                type="default"
                @click="exit"
            >
                {{ $t('administration.users.prompt.ko') }}
            </el-button>
        </div>
    </div>
</template>

<script>
import Avatar from 'vue-avatar';
import { EventBus } from '@/store/eventBus';
import constants from '@/store/constants';
import { getters, mutations, actions } from '@/store';
import socket from '@/store/socket';

export default {
    name: 'AdminUserForm',

    components: {
        Avatar
    },

    data() {
        return {
            constants
        }
    },

    computed: {
        users: getters.administration.users,
        roles: getters.user.roles,
        permissions: getters.user.permissions,
        categories: getters.ticket.categories,
        customers: getters.administration.customers,
        currentUser: getters.administration.currentUser
    },

    async mounted() {
        await actions.administration.getCustomers();
    },

    methods: {
        async saveUser(user) {
            try {
                if (!this.formValidation()) {
                    return false;
                }

                let d = {
                    user_id: user.id,
                    username: user.username,
                    email: user.email,
                    customers_id: user.customers.map(el => el.id),
                    roles_id: [user.roles[0].id],
                    categories_id: user.categories.map(el => el.id)
                };

                if (user.password) {
                    d.password = user.password;
                }

                await actions.administration.saveUser(d);
                socket.emit('updating_user', user.id);

                EventBus.$emit('updateUsers', true);

                this.$notify.success(this.$t('administration.users.prompt.operationSuccess'));
                this.exit();
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async deleteUser(user) {
            try {
                await actions.administration.deleteUser({
                    user_id: user.id
                });

                EventBus.$emit('updateUsers', true);

                this.$notify.success(this.$t('administration.users.prompt.operationSuccess'));
                this.exit();
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        exit () {
            mutations.administration.setCurrentUser(null);
            EventBus.$emit('exitAdminUserForm', true);
        },

        formValidation() {
            let res = true;

            if (!this.currentUser.username || this.currentUser.username.trim() === '') {
                this.$notify.error(this.$t('administration.errors.user_username'));
                res = false;
            }
            if (!this.currentUser.email ||
                this.currentUser.email.trim() === '' ||
                !(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/).test(this.currentUser.email))
            {

                console.log('<<<<<<<', this.currentUser.email, (/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/).test(this.currentUser.email))

                this.$notify.error(this.$t('administration.errors.user_email'));
                res = false;
            }
            if (!this.currentUser.roles || this.currentUser.roles.length === 0) {
                this.$notify.error(this.$t('administration.errors.user_roles'));
                res = false;
            }
            if (!this.currentUser.categories || this.currentUser.categories.length === 0) {
                this.$notify.error(this.$t('administration.errors.user_categories'));
                res = false;
            }
            if (!this.currentUser.customers || this.currentUser.customers.length === 0) {
                this.$notify.error(this.$t('administration.errors.user_customers'));
                res = false;
            }

            return res;
        }
    }
}
</script>
