'use strict';

export default {
    permissions: {
        tickets: {
            view_ticket_menu: 'view_ticket_menu',
            view_only_own_ticket: 'view_only_own_ticket',
            view_operation_menu_ticket: 'view_operation_menu_ticket',
            view_configuration_menu_ticket: 'view_configuration_menu_ticket',
            view_action_menu_ticket: 'view_action_menu_ticket',
            update_category_ticket: 'update_category_ticket',
            update_status_ticket: 'update_status_ticket',
            update_priority_ticket: 'update_priority_ticket',
            update_due_date_ticket: 'update_due_date_ticket',
            delete_ticket: 'delete_ticket',
            close_ticket: 'close_ticket',
            assign_to_me_ticket: 'assign_to_me_ticket',
            assign_to_operator_ticket: 'assign_to_operator_ticket',
        },
        knowledge: {
            view_knowledge_menu: 'view_knowledge_menu',
            create_faq: 'create_faq',
            update_faq: 'update_faq',
            delete_faq: 'delete_faq'
        },
        reports: {
            view_report_menu: 'view_report_menu',
        },
        administration: {
            view_admin_menu: 'view_admin_menu',
            create_user: 'create_user',
            delete_user: 'delete_user',
            update_user: 'update_user',
            create_category: 'create_category',
            update_category: 'update_category',
            create_role: 'create_role',
            update_role: 'update_role',
            delete_role: 'delete_role',
            create_customer: 'create_customer',
            update_customer: 'update_customer',
            delete_customer: 'delete_customer'
        }
    }
};
