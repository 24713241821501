export default {
    administration: {
        domains: {
            new: "inserisci un nuovo dominio",
            prompt: {
                title: "Nuovo dominio",
                text: "Nome del nuovo dominio",
                ok: "salva",
                ko: "annulla",
                validationError:
                    "Nome non valido, il dominio deve iniziare con il carattere @",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questo dominio?",
            },
            modal: {
                title: "Gestisci domini attivi",
            },
        },
        customers: {
            new: "inserisci un nuovo cliente",
            prompt: {
                title: "Nuovo cliente",
                text: "Nome del nuovo cliente",
                ok: "salva",
                ko: "annulla",
                del: "cancella",
                validationError: "Nome non valido, usa solo lettere e numeri",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questo cliente?",
            },
            table: {
                search: "Cerca",
                name: "Nome",
                description: "Descrizione",
                address: "Indirizzo",
                phone: "Telefono",
                email: "Email",
                vat: "P.iva",
                email_to_ticket: "Email to ticket",
                arca_code: "codice ARCA",
            },
            modal: {
                titleMod: "Modifica cliente",
                titleNew: "Aggiungi cliente",
            },
            sheet: {
                name: "Nome",
                description: "Descrizione",
                address: "Indirizzo",
                phone: "Telefono",
                email: "Email",
                vat: "P.iva",
            },
            incomplete: "Attenzione: sono presenti {0} clienti senza email",
            selectIncomplete: "seleziona i clienti senza email associata",
        },
        roles: {
            new: "crea nuovo ruolo",
            prompt: {
                title: "Nuovo ruolo",
                text: "Nome del nuovo ruolo",
                ok: "salva",
                ko: "annulla",
                validationError: "Nome non valido, usa solo lettere e numeri",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questo ruolo?",
            },
        },
        categories: {
            new: "crea nuova categoria",
            prompt: {
                title: "Nuova categoria",
                text: "Nome della nuova categoria",
                ok: "salva",
                ko: "annulla",
                validationError: "Nome non valido, usa solo lettere e numeri",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questa categoria?",
            },
        },
        users: {
            new: "crea nuovo utente",
            prompt: {
                title: "Nuovo utente",
                ok: "salva",
                ko: "annulla",
                del: "elimina",
                sel: "seleziona",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questo utente?",
            },
            table: {
                search: "Cerca",
                username: "Utente",
                email: "Email",
                updated_at: "Aggiornato il",
                tickets: "Tickets associati",
                categories: "Settori",
                no_categories: "nessuna",
                roles: "Ruoli",
                no_roles: "nessuno",
            },
            modal: {
                titleMod: "Modifica utente",
                titleNew: "Aggiungi utente",
            },
            sheet: {
                role: "ruolo",
                category: "categoria",
                customer: "cliente",
                search: "cerca per email:",
            },
        },
        whitelist: {
            new: "aggiungi un dominio in whitelist",
            prompt: {
                title: "Nuovo dominio",
                text: "Dominio",
                ok: "salva",
                ko: "annulla",
                validationError: "Nome non valido, usa solo lettere e numeri",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questo dominio?",
            },
        },
        blacklist: {
            new: "aggiungi un record in blacklist",
            noEntries: "nessun record presente",
            filter: "Filtro",
            filterLabel: "cerca:",
            prompt: {
                title: "Nuovo record",
                text: "Record",
                ok: "salva",
                ko: "annulla",
                validationError: "Email non valida",
                operationSuccess: "Operazione completata",
                operationAborted: "Operazione annullata",
                confirmSave: "Confermi i dati da salvare?",
                confirmDelete: "Confermi la cancellazione di questo record?",
            },
        },
        permissions: {
            view_ticket_menu: "Vis. Menu ticket",
            view_only_own_ticket: "Vis. SOLO i propri ticket",
            view_operation_menu_ticket: "Vis. Menu operazioni ticket",
            view_configuration_menu_ticket: "Vis. Menu configurazioni ticket",
            view_action_menu_ticket: "Vis. Menu azioni ticket",
            update_category_ticket: "Aggiornare categoria ticket",
            update_status_ticket: "Aggiornare status ticket",
            update_priority_ticket: "Aggiornare priorità ticket",
            update_due_date_ticket: "Aggiornare scadenza ticket",
            delete_ticket: "Cancellare ticket",
            close_ticket: "Chiudere ticket",
            assign_to_me_ticket: "Prendere in carico il ticket",
            assign_to_operator_ticket: "Assegnare ticket ad un operatore",
            view_knowledge_menu: "Vis. Menu documentazione",
            create_faq: "Creare FAQ",
            update_faq: "Modificare FAQ",
            delete_faq: "Cancellare FAQ",
            view_report_menu: "Vis. Menu report",
            view_admin_menu: "Vis. Menu amministrazione",
            create_user: "Creare utenti",
            delete_user: "Cancellare utenti",
            update_user: "Aggiornare utenti",
            create_category: "Creare categorie",
            update_category: "Aggiornare categorie",
            create_role: "Creare ruoli",
            update_role: "Aggiornare ruoli",
            delete_role: "Cancellare ruoli",
            create_customer: "Creare clienti",
            update_customer: "Aggiornare clienti",
            delete_customer: "Cancellare clienti",
            add_whitelist_domain: "Aggiungere domini in whitelist",
            delete_whitelist_domain: "Rimuovere domini in whitelist",
        },

        errors: {
            category: "Il nome della categoria è obbligatorio",
            role: "Il nome del ruolo è obbligatorio",
            user_username: "Il nome dell'utente è obbligatorio",
            user_email: "L'email non è corretta",
            user_roles: "L'utente deve avere un ruolo",
            user_categories:
                "L'utente deve essere associato a una o più categorie",
            user_customers:
                "L'utente deve essere associato a uno o più clienti",
        },
    },
};
