<template>
    <div>
        <div v-if="customersInfo.total > 0">
            <el-alert
                :title="
                    $t('administration.customers.incomplete', [
                        customersInfo.total,
                    ])
                "
                type="warning"
                show-icon
                effect="dark"
            >
            </el-alert>
        </div>

        <div class="flex py-4">
            <span class="mr-2">{{
                $t("administration.customers.table.search")
            }}</span>
            <div class="flex-1 mr-2">
                <el-input v-model="search" clearable />
                <div class="py-2 text-right">
                    <el-checkbox v-model="incompleteCustomers">
                        {{ $t("administration.customers.selectIncomplete") }}
                    </el-checkbox>
                </div>
            </div>
            <div class="flex-1 mr-2">
                <el-button
                    icon="fas fa-search"
                    type="primary"
                    @click="doSearch"
                >
                </el-button>
            </div>
            <div class="flex-initial">
                <AdminCustomerNew />
            </div>
        </div>

        <data-tables-server
            ref="CustomersTable"
            class="datatable"
            :key="customersListKey"
            :loading="loading"
            :table-props="tableProps"
            :data="customersList.customers"
            :total="parseInt(customersList.total) || 0"
            :current-page="parseInt(customersList['page']) || 1"
            :page-size="parseInt(customersList['pageSize']) || recordPerPage"
            :filters="filters"
            :pagination-props="{ pageSizes: paginations }"
            @query-change="getData"
        >
            <el-table-column
                prop="name"
                sortable="custom"
                :label="$t('administration.customers.table.name')"
            />
            <el-table-column
                prop="vat_number"
                sortable="custom"
                :label="$t('administration.customers.table.vat')"
            />
            <el-table-column
                prop="address"
                sortable="custom"
                :label="$t('administration.customers.table.address')"
            />
            <el-table-column
                prop="phone"
                sortable="custom"
                :label="$t('administration.customers.table.phone')"
            />
            <el-table-column
                prop="email"
                sortable="custom"
                :label="$t('administration.customers.table.email')"
            />
            <el-table-column
                prop="arca_code"
                sortable="custom"
                :label="$t('administration.customers.table.arca_code')"
            />
            <el-table-column
                align="center"
                width="100"
                :label="$t('administration.customers.table.email_to_ticket')"
                v-if="
                    $iCan(constants.permissions.administration.update_customer)
                "
            >
                <template slot-scope="scope">
                    <input
                        type="checkbox"
                        v-model="scope.row.active_mail_to_ticket"
                        :checked="scope.row.active_mail_to_ticket === 1"
                        @change="activeMailToTicket(scope.row)"
                    />
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                width="200"
                v-if="
                    $iCan(constants.permissions.administration.update_customer)
                "
            >
                <template slot-scope="scope">
                    <el-button
                        class="mr-2 cursor-pointer"
                        size="mini"
                        type="default"
                        icon="fas fa-at"
                        @click="modDomains(scope.row)"
                    />
                    <el-button
                        class="cursor-pointer"
                        size="mini"
                        type="default"
                        icon="fas fa-pen"
                        @click="modCustomer(scope.row)"
                    />
                </template>
            </el-table-column>
        </data-tables-server>

        <el-dialog
            :title="$t('administration.customers.modal.titleMod')"
            :visible.sync="dialogVisible"
            :before-close="exit"
            width="50%"
        >
            <AdminCustomerForm></AdminCustomerForm>
        </el-dialog>

        <el-dialog
            v-if="currentCustomer"
            :title="$t('administration.domains.modal.title')"
            :visible.sync="dialogVisibleDomains"
            :before-close="exitDomains"
            width="50%"
        >
            <AdminDomainsList></AdminDomainsList>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
import qs from "query-string";
import BaseMixin from "@/mixins/Base.js";
import { EventBus } from "@/store/eventBus";
import constants from "@/store/constants";
import { getters, mutations, actions } from "@/store";
import socket from "@/store/socket";
import AdminCustomerNew from "@/entities/administration/AdminCustomerNew.vue";
import AdminCustomerForm from "@/entities/administration/AdminCustomerForm.vue";
import AdminDomainsList from "@/entities/administration/AdminDomainsList.vue";

export default {
    name: "AdminRolesMain",

    mixins: [BaseMixin],

    components: {
        AdminCustomerNew,
        AdminCustomerForm,
        AdminDomainsList,
    },

    computed: {
        recordPerPage: getters.recordPerPage,
        paginations: getters.paginations,
        customersList: getters.administration.customers,
        currentCustomer: getters.administration.currentCustomer,
        customersInfo: getters.administration.customersInfo,
        mainCustomer: getters.user.mainCustomer,
    },

    data() {
        return {
            constants,
            customersListKey: "init",
            loading: false,
            dialogVisible: false,
            tableProps: {
                border: true,
                stripe: true,
                defaultSort: {
                    prop: "id",
                    order: "descending",
                },
            },
            filters: [],
            search: "",
            dialogVisibleDomains: false,
            incompleteCustomers: false,
        };
    },

    async mounted() {
        try {
            EventBus.$on("updateCustomers", async () => {
                this.loading = true;
                this.customersListKey = dayjs().format();
                this.loading = false;
            });

            EventBus.$on("exitAdminCustomerForm", () => this.exit());
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.$log.debug(error);
            this.$notify.error(error.message);
        }
    },

    beforeDestroy() {
        EventBus.$off("updateCustomers");
        EventBus.$off("exitAdminCustomersForm");
    },

    methods: {
        async getData(queryInfo) {
            try {
                this.loading = true;

                const qp = {
                    limit: queryInfo.pageSize,
                    page: queryInfo.page,
                    sortBy: queryInfo.sort.prop,
                    sortType:
                        queryInfo.sort.order === "ascending" ? "asc" : "desc",
                };

                let url = `/search/customers?${qs.stringify(qp)}`;
                if (this.search.trim() !== "") {
                    url += `&term=${this.search}`;
                }

                const pay = {
                    email: !this.incompleteCustomers,
                };

                await actions.administration.getCustomersWithPagination(
                    url,
                    pay
                );
                await actions.administration.customersInfo(this.mainCustomer);

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        doSearch() {
            this.loading = true;
            this.customersListKey = dayjs().format();
            this.loading = false;
        },

        modCustomer(customer) {
            mutations.administration.setCurrentCustomer(
                this.$copyStatic(customer)
            );
            this.dialogVisible = true;
        },

        modDomains(customer) {
            mutations.administration.setCurrentCustomer(
                this.$copyStatic(customer)
            );
            EventBus.$emit("updateDeliveries", true);
            this.dialogVisibleDomains = true;
        },

        async activeMailToTicket(customer) {
            try {
                let d = {
                    customer_id: customer.id,
                    active_mail_to_ticket: customer.active_mail_to_ticket
                        ? 1
                        : 0,
                };

                await actions.administration.saveCustomer(d);
                socket.emit("updating_customer", customer.id);

                EventBus.$emit("updateCustomers", true);

                this.$notify.success(
                    this.$t("administration.customers.prompt.operationSuccess")
                );
            } catch (error) {
                console.log(error);
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        exit() {
            this.dialogVisible = false;
        },

        exitDomains() {
            this.dialogVisibleDomains = false;
        },
    },
};
</script>
