import "./polyfills";
// Import i18n language manager
import i18n from "./i18n";
// A plugin file where you could register global components used across the app
import GlobalComponents from "./globalComponents";
// A plugin file where you could register global directives
import GlobalDirectives from "./globalDirectives";
// Sidebar on the right. Used as a local plugin in DashboardLayout.vue
import SideBar from "@/components/SidebarPlugin";
import NotificationPlugin from "@/components/NotificationPlugin";
// Tabs plugin. Used on Panels page.
import VueTabs from "vue-nav-tabs";

import AsyncComputed from "vue-async-computed";
import VueLogger from "vuejs-logger";

// element ui language configuration
import locale from "element-ui/lib/locale";
locale.i18n((key, value) => i18n.t(key, value));

// asset imports
import "bootstrap/dist/css/bootstrap.css";
import "vue-nav-tabs/themes/vue-tabs.scss";
import "@/assets/sass/light-bootstrap-dashboard.scss";
import "@/assets/css/demo.css";

export default {
    async install(Vue) {
        Vue.use(GlobalComponents);
        Vue.use(GlobalDirectives);
        Vue.use(SideBar);
        Vue.use(NotificationPlugin);
        Vue.use(VueTabs);
        Vue.use(AsyncComputed);
        Vue.use(VueLogger, {
            isEnabled: process.env.VUE_APP_LOGGER_ENABLED === "true",
            logLevel: process.env.VUE_APP_LOGGER_LEVEL,
            stringifyArguments: false,
            showLogLevel: true,
            showMethodName: true,
            separator: "|",
            showConsoleColors: true,
        });
    },
};
