var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('auth-layout',{attrs:{"pageClass":"login-page"}},[_c('div',{staticClass:"row d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"col-lg-4 col-md-6 col-sm-8"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('fade-render-transition',[_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h3',{staticClass:"card-title text-center"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]),_c('div',[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed
                                                ? _vm.$t(
                                                      "login.errors.email_field"
                                                  )
                                                : null,"hasSuccess":passed,"label":"Email","name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                var passed = ref.passed;
                                                var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed
                                                ? _vm.$t(
                                                      "login.errors.pass_field"
                                                  )
                                                : null,"hasSuccess":passed,"name":"password","label":"Password"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center"},[(!_vm.loading)?_c('button',{staticClass:"btn btn-fill btn-info btn-round btn-wd",attrs:{"type":"submit"}},[_vm._v(" Login ")]):(
                                        _vm.loading && !_vm.selectMainCustomer
                                    )?_c('button',{staticClass:"btn btn-fill btn-default btn-round btn-wd",attrs:{"type":"button","disabled":""}},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_c('div',{staticClass:"w-full text-center"},[_c('span',{staticClass:"sys-bg-green text-white rounded-full px-3 py-2 text-center"},[_c('i',{staticClass:"fas fa-arrow-down"})])])]),(_vm.selectMainCustomer)?_c('div',{staticClass:"pt-3"},[_c('div',{staticClass:"form-group has-label"},[_c('label',[_vm._v(_vm._s(_vm.$t("login.fields.mainCustomer")))]),_c('el-select',{staticClass:"mainCustomerSelect",attrs:{"filterable":"","size":"medium"},model:{value:(_vm.mainCustomer),callback:function ($$v) {_vm.mainCustomer=$$v},expression:"mainCustomer"}},_vm._l((_vm.mainCustomers),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1),_c('div',{staticClass:"py-4 text-center"},[(!_vm.loading2)?_c('el-button',{staticClass:"btn btn-fill btn-success btn-round",attrs:{"type":"button","disabled":!_vm.mainCustomer},on:{"click":function($event){$event.preventDefault();return _vm.enterApp.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("login.enter"))+" ")]):_c('button',{staticClass:"btn btn-fill btn-default btn-round",attrs:{"type":"button","disabled":""}},[_c('i',{staticClass:"fas fa-spinner fa-spin"})])],1)],1)]):_vm._e()])],1)],1)]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }