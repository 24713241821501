<template>
    <div class="flex items-top py-4">
        <div class="flex-1">
            <i class="fas fa-dot-circle mr-4"></i>
            <span class="text-2xl">{{ $t(`menu.vertical.${$route.meta.label}`) }}</span>
        </div>
        <div class="flex-initial">
            <el-button
                type="success"
                @click="addWhitelistDomain"
                v-if="$iCan(constants.permissions.administration.create_category)"
            >
                <i class="fas fa-plus mr-2"></i>
                {{$t('administration.whitelist.new')}}
            </el-button>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import { actions } from '@/store';
import constants from '@/store/constants';

export default {
    name: 'AdminWitelistTopMenu',

    data() {
        return {
            constants
        }
    },

    methods: {
        addWhitelistDomain() {
            this.$prompt(this.$t('administration.whitelist.prompt.text'), this.$t('administration.whitelist.prompt.title'), {
                confirmButtonText: this.$t('administration.whitelist.prompt.ok'),
                cancelButtonText: this.$t('administration.whitelist.prompt.ko'),
                inputPattern: /^[a-zA-Z0-9]*$/,
                inputErrorMessage: this.$t('administration.whitelist.prompt.validationError')
            }).then(async ({value}) => {
                await actions.administration.addWhitelistDomain({
                    name: value
                });

                EventBus.$emit('updateWhitelist', true);

                this.$message({
                    type: 'success',
                    message: this.$t('administration.whitelist.prompt.operationSuccess')
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('administration.whitelist.prompt.operationAborted')
                });
            });
        }
    }
}
</script>
