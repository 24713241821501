<template>
    <div v-if="currentCustomer">
        <div class="mb-2">
            <p class="pb-2">{{$t('administration.customers.sheet.name')}}</p>
            <el-input v-model="currentCustomer.name" placeholder="" clearable>
                <template slot="prepend">
                    <i class="fas fa-users"></i>
                </template>
            </el-input>
        </div>
        <div class="mb-2">
            <p class="pb-2">{{$t('administration.customers.sheet.description')}}</p>
            <el-input type="textarea" rows="3" v-model="currentCustomer.description" placeholder="" clearable />
        </div>
        <div class="mb-2">
            <p class="pb-2">{{$t('administration.customers.sheet.address')}}</p>
            <el-input v-model="currentCustomer.address" placeholder="" clearable>
                <template slot="prepend">
                    <i class="fas fa-map-marker-alt"></i>
                </template>
            </el-input>
        </div>
        <div class="mb-2">
            <p class="pb-2">{{$t('administration.customers.sheet.vat')}}</p>
            <el-input v-model="currentCustomer.vat_number" placeholder="" clearable>
                <template slot="prepend">
                    <i class="fas fa-wallet"></i>
                </template>
            </el-input>
        </div>
        <div class="mb-2">
            <p class="pb-2">{{$t('administration.customers.sheet.phone')}}</p>
            <el-input v-model="currentCustomer.phone" placeholder="" clearable>
                <template slot="prepend">
                    <i class="fas fa-phone"></i>
                </template>
            </el-input>
        </div>
        <div class="mb-2">
            <p class="pb-2">{{$t('administration.customers.sheet.email')}}</p>
            <el-input v-model="currentCustomer.email" placeholder="" clearable>
                <template slot="prepend">
                    <i class="fas fa-envelope"></i>
                </template>
            </el-input>
        </div>

        <div class="text-right">
            <el-popconfirm
                :title="$t('administration.customers.prompt.confirmSave')"
                :confirm-button-text="$t('administration.customers.prompt.ok')"
                :cancel-button-text="$t('administration.customers.prompt.ko')"
                @confirm="saveCustomer(currentCustomer)"
            >
                <el-button
                    slot="reference"
                    type="primary"
                    class="mr-2"
                >
                    {{ $t('administration.customers.prompt.ok') }}
                </el-button>
            </el-popconfirm>
            <el-popconfirm
                v-if="$iCan(constants.permissions.administration.delete_customer)"
                :title="$t('administration.customers.prompt.confirmDelete')"
                :confirm-button-text="$t('administration.customers.prompt.ok')"
                :cancel-button-text="$t('administration.customers.prompt.ko')"
                @confirm="deleteCustomer(currentCustomer)"
            >
                <el-button
                    slot="reference"
                    type="info"
                    class="mr-2"
                >
                    {{ $t('administration.customers.prompt.del') }}
                </el-button>
            </el-popconfirm>
            <el-button
                type="default"
                @click="exit"
            >
                {{ $t('administration.customers.prompt.ko') }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import constants from '@/store/constants';
import { getters, mutations, actions } from '@/store';
import socket from '@/store/socket';

export default {
    name: 'AdminCustomerForm',

    computed: {
        currentCustomer: getters.administration.currentCustomer
    },

    data() {
        return {
            constants
        }
    },

    methods: {
        async saveCustomer(customer) {
            try {
                if (!this.formValidation()) {
                    return false;
                }

                let d = {
                    customer_id: customer.id,
                    name: customer.name,
                    description: customer.description,
                    address: customer.address,
                    phone: customer.phone,
                    email: customer.email,
                    vat_number: customer.vat_number
                };

                await actions.administration.saveCustomer(d);
                socket.emit('updating_customer', customer.id);

                EventBus.$emit('updateCustomers', true);

                this.$notify.success(this.$t('administration.customers.prompt.operationSuccess'));
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async deleteCustomer(customer) {
            try {
                await actions.administration.deleteCustomer({
                    customer_id: customer.id
                });

                EventBus.$emit('updateCustomers', true);

                this.$notify.success(this.$t('administration.customers.prompt.operationSuccess'));
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        exit () {
            mutations.administration.setCurrentCustomer(null);
            EventBus.$emit('exitAdminCustomerForm', true);
        },

        formValidation() {
            let res = true;

            if (!this.currentCustomer.name || this.currentCustomer.name.trim() === '') {
                this.$notify.error(this.$t('administration.errors.customer_name'));
                res = false;
            }

            return res;
        }
    }
}
</script>
