<template>
    <div>
        <el-table :data="userList" border style="width: 100%">
            <el-table-column prop="username" label="Utente" />
            <el-table-column prop="days" label="Attività">
                <template slot-scope="scope">
                    <el-button
                        v-for="r in scope.row.days"
                        :key="r.day"
                        :type="getTimeStatus(r.hours)"
                    >
                        {{ r.day }}
                        <i class="fas fa-clock" /> {{ r.hours }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import { getters, actions } from "@/store";

export default {
    name: "ReportUsers",

    computed: {
        usersList: getters.reports.users,
    },

    data() {
        return {
            loading: false,
            userList: [],
            search: "",
        };
    },

    async mounted() {
        await this.getUsers();
    },

    methods: {
        dummyRange() {
            return ["26-08-2022", "27-08-2022", "28-08-2022"].map((el) => ({
                day: el,
                hours: Math.floor(Math.random() * 9),
            }));
        },

        getTimeStatus(t) {
            if (t >= 8) {
                return "success";
            }

            if (t < 8) {
                return "warning";
            }

            if (t <= 4) {
                return "danger";
            }
        },

        async getUsers() {
            try {
                this.loading = true;

                const res = await actions.reports.getUsers({
                    query: {},
                    payload: {},
                });

                this.userList = res.map((el) => {
                    return {
                        username: el.username,
                        days: this.dummyRange(),
                    };
                });

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },
    },
};
</script>
