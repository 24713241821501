import ElementUI from "element-ui";
import fgInput from "@/components/Inputs/formGroupInput.vue";
import Card from "@/components/Cards/Card.vue";
import DropDown from "@/components/Dropdown.vue";
import Button from "@/components/Button.vue";
import i18n from "@/setup/i18n";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VueDataTables from "vue-data-tables";
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install(Vue) {
        Vue.component("fg-input", fgInput);
        Vue.component("card", Card);
        Vue.component("drop-down", DropDown);
        Vue.component(Button.name, Button);
        Vue.component("ValidationProvider", ValidationProvider);
        Vue.component("ValidationObserver", ValidationObserver);
        Vue.use(ElementUI, {
            size: "mini",
            i18n: (key, value) => i18n.t(key, value),
        });
        Vue.use(VueDataTables);
    },
};

export default GlobalComponents;
