"use strict";

export default {
    path: "/reports",
    component: () =>
        import(
            /* webpackChunkName: "home" */ "@/pages/Dashboard/Layout/DashboardLayout.vue"
        ),
    children: [
        {
            path: "",
            name: "reports_list",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/reports/ReportFilters.vue"
                    ),
                slot2: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/reports/ReportMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "reports",
                sub: "report",
                type: "general",
            },
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
