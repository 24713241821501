"use strict";

import Vue from "vue";
import apiLib from "@/store/api";
import { localStore } from "@/store/localStore";

const api = apiLib(localStore);

const state = Vue.observable({
    user: {
        token: null,
        data: null,
    },
    mainCustomer: null,
    roles: [],
    permissions: [],
});

const l = localStore.session("user");
const mc = localStore.session("mainCustomer");
if (l) {
    state.user.data = l.user;
    state.user.token = l.access_token;
}
if (mc) {
    state.mainCustomer = mc;
}

export const getters = {
    token: () => {
        return state.user.token;
    },
    mainCustomer: () => {
        return state.mainCustomer;
    },
    data: () => {
        return state.user.data;
    },
    roles: () => state.roles,
    permissions: () => state.permissions,
};

export const mutations = {
    setToken: (val) => {
        const l = localStore.session("user");
        l.access_token = val;

        localStore.session("user", l);
        state.user.token = val;
    },

    setMainCustomer: (val) => {
        localStore.session("mainCustomer", val);
        state.mainCustomer = val;
    },

    setData: (val) => {
        localStore.session("user", val);
        state.user.data = val.user;
    },

    setRoles: (val) => (state.roles = val),

    setPermissions: (val) => (state.permissions = val),

    clearToken: () => (state.user.token = null),
};

export const actions = {
    async login(payload) {
        try {
            const res = await api.post("/auth/login", payload);
            Vue.$log.error(res.data);

            if (!res.data) {
                return Vue.prototype.$throwError({
                    type: "LoginError",
                    message: "Invalid username or password",
                    status: 400,
                });
            }

            res.data.authenticated = true;

            mutations.setData(res.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "LoginError",
                message: "Invalid login",
                status: 400,
            });
        }
    },

    async getVerticalCustomers(mainCustomerId) {
        try {
            const res = await api.post("/search/users/clients", {
                clients_id: [mainCustomerId],
            });

            if (!res.data) {
                return Vue.prototype.$throwError({
                    type: "GetClientsError",
                    message: "Cannot list clients with id " + mainCustomerId,
                    status: 404,
                });
            }

            state.user.data.customers = res.data;

            let storedUser = localStore.session("user");
            storedUser.user = state.user.data;

            localStore.session("user", storedUser);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "LoginError",
                message: "Invalid login",
                status: 400,
            });
        }
    },

    async me(payload) {
        try {
            const l = localStore.session("user");

            const res = await api.post(
                `/search/users?email=${l.user.email}`,
                {}
            );

            if (!res.data) {
                return Vue.prototype.$throwError({
                    type: "UserError",
                    message: "Cannot find user",
                    status: 400,
                });
            }

            let u = localStore.session("user");
            u.user = res.data.data[0];

            if (payload) {
                u.user = Object.assign(u.user, payload);
            }

            mutations.setData(u);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetUserError",
                message: "Error retriving user data",
                status: 400,
            });
        }
    },

    async getRoles() {
        try {
            const res = await api.get(`/roles`);
            mutations.setRoles(res.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListRolesError",
                message: "Cannot list roles",
                status: 400,
            });
        }
    },

    async getPermissions() {
        try {
            const res = await api.get(`/permissions`);
            mutations.setPermissions(res.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListPermissionsError",
                message: "Cannot list permissions",
                status: 400,
            });
        }
    },
};
