<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters, actions } from "@/store";
import socket from "@/store/socket";

export default {
    name: "App",

    computed: {
        user: getters.user.data,
    },

    mounted() {
        if (this.user) {
            socket.on("update_role", async (val) => {
                await actions.user.getRoles();
                if (this.user.roles[0].id === val) {
                    await actions.user.me();
                }
            });

            socket.on(`update_user_${this.user.id}`, async () => {
                await actions.user.me();
            });

            socket.on(`incoming_new_ticket`, (data) => {
                const check = this.user.categories.filter(
                    (el) => parseInt(el.id) === parseInt(data.category)
                );

                if (check.length > 0) {
                    this.$notify.info(this.$t("tickets.incoming_ticket"));
                    EventBus.$emit("updateTicketList", true);
                }
            });
        }
    },

    beforeDestroy() {
        socket.off("update_role");
        socket.off("update_user");
        socket.off("incoming_new_ticket");
    },
};
</script>
