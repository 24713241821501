var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right pb-4"},[_c('AdminRolesNew')],1),_c('div',{staticClass:"grid grid-cols-3 gap-4"},_vm._l((_vm.roleList),function(role){return _c('el-card',{key:role.slug,staticClass:"box-card text-xs"},[_c('el-input',{attrs:{"placeholder":""},model:{value:(role.name),callback:function ($$v) {_vm.$set(role, "name", $$v)},expression:"role.name"}}),(_vm.permissionList)?_c('div',{staticClass:"p-2"},_vm._l((_vm.permissionList),function(permissions,permissionBlock){return _c('div',{key:permissionBlock},[_c('p',{staticClass:"bg-gray-200 px-2 py-1 font-bold"},[_vm._v(" "+_vm._s(permissionBlock)+" ")]),_c('el-checkbox-group',{model:{value:(role.permissions),callback:function ($$v) {_vm.$set(role, "permissions", $$v)},expression:"role.permissions"}},_vm._l((permissions),function(permission){return _c('el-checkbox',{key:permission.slug,staticClass:"block",attrs:{"label":permission.id}},[_vm._v(" "+_vm._s(_vm.$t( ("administration.permissions." + (permission.slug)) ))+" ")])}),1)],1)}),0):_vm._e(),_c('div',{staticClass:"text-right"},[(
                        _vm.$iCan(
                            _vm.constants.permissions.administration.update_role
                        )
                    )?_c('el-popconfirm',{attrs:{"title":_vm.$t('administration.roles.prompt.confirmSave'),"confirm-button-text":_vm.$t('administration.roles.prompt.ok'),"cancel-button-text":_vm.$t('administration.roles.prompt.ko')},on:{"confirm":function($event){return _vm.saveRole(role)}}},[_c('el-button',{staticClass:"mr-2",attrs:{"slot":"reference","type":"primary","icon":"fas fa-save"},slot:"reference"})],1):_vm._e(),(
                        _vm.$iCan(
                            _vm.constants.permissions.administration.delete_role
                        ) && !role.isFix
                    )?_c('el-popconfirm',{attrs:{"title":_vm.$t('administration.roles.prompt.confirmDelete'),"confirm-button-text":_vm.$t('administration.roles.prompt.ok'),"cancel-button-text":_vm.$t('administration.roles.prompt.ko')},on:{"confirm":function($event){return _vm.deleteRole(role)}}},[_c('el-button',{staticClass:"mr-2",attrs:{"slot":"reference","type":"default","icon":"fas fa-trash"},slot:"reference"})],1):_vm._e()],1)],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }