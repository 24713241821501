export default {
    tickets: {
        incoming_ticket: "Nuovo ticket registrato",
        newTicket: "nuovo ticket",
        filters: "Filtri",
        operationSuccess: "Operazione effettuata con successo",

        legend: {
            title: "Legenda",
            report: "report registrato",
            toapprove: "in approvazione",
            approved: "approvato",
        },

        selection: {
            delete: {
                deleteSelected: "cancella selezionati",
                ok: "conferma",
                ko: "annulla",
                title: "Vuoi cancellare i tickets selezionati?",
            },
        },

        status: {
            open: "aperto",
            closed: "chiuso",
            in_progress: "in lavorazione",
            wait_feedback: "in attesa di feedback",
        },

        priority: {
            low: "bassa",
            average: "media",
            high: "alta",
            immediate: "immediata",
        },

        list: {
            open: "Aperti",
            assignedToMe: "Assegnati a me",
            assigned: "Assegnati",
            closed: "Chiusi (Archivio)",
            createdByMeOpen: "I miei ticket (aperti)",
            createdByMeClosed: "I miei ticket (chiusi)",
        },

        table: {
            subject: "Titolo",
            category: "Categoria",
            priority: "SLA",
            status: "Stato",
            from_email: "Utente",
            dueDate: "Scadenza",
            customer: "Cliente",
            updatedAt: "Aggiornato il",
            openMenu: "Apri menu",
            view: "Visualizza scheda",
            createDate: "Ricezione",
            assignedTo: "Assegnazione",
            changeTitle: "Modifica il titolo",
            changeTitlePrompt: "Modifica il titolo attuale",
        },

        counter: {
            assigned: "N° di ticket assegnati a me",
            not_assigned: "N° di ticket da assegnare",
        },

        sheet: {
            title: "Scheda del ticket # {ticket_id}",
            titleNew: "Nuovo ticket",
            order: "Ordinamento",
            ascending: "crescente",
            descending: "decrescente",
            editBody: "Aggiungi o modifica messaggio",
            editComment: "Modifica il commento",
            noTimelineData: "nessun dato in timeline",
            notAssigned: "ticket non assegnato",
            attachments: "Allegati",
            noAttachments: "Nessun allegato presente",
            expandAll: "Visualizza tutto",
            collapseAll: "Riduci tutto",
        },

        drawer: {
            actions: {
                title: "Menu tickets",
                save: "salva",
                cancel: "annulla",
                confirmInProgress:
                    "Confermi di voler iniziare la lavorazione di questo ticket?",
                successInProgress: "Inizio lavorazione ticket",
                confirmWaitFeedback:
                    "Confermi che il ticket è in attesa di feedback?",
                successWaitFeedback: "Aggiornamento completato",
                sections: {
                    configTitle: "Configurazione",
                    actionsTitle: "Operazioni",

                    config: {
                        changePriority: "Priorità",
                        changeCategory: "Categoria",
                        changeStatus: "Stato",
                        changeTitle: "Titolo",
                        changeBody: "Descrizione",
                        changeDueDate: "Scadenza",
                        tag: "Tag",
                        modPriority: "Modifica priorità:",
                        modCategory: "Modifica categoria:",
                        modStatus: "Modifica lo status:",
                        modTitle: "Modifica il titolo:",
                        modDueDate: "Modifica la scadenza:",
                        newTag: "Aggiungi tag",
                        customer: "Seleziona un cliente di riferimento",
                    },
                    actions: {
                        delete: "Cancella",
                        deleteText: "desidero cancellare questo ticket",
                        confirmDelete: "conferma",
                        close: "Chiudi",
                        closeText: "desidero chiudere questo ticket",
                        confirmClose: "conferma",
                        take: "Assegna a te",
                        takeText: "deisdero assegnare a me questo ticket",
                        confirmTake: "conferma",
                        assignTo: "Assegna ad un operatore",
                        srcUsers: "cerca un operatore",
                        assignText: "conferma scelta",
                        confirmAssign: "assegna",
                    },

                    filter: {
                        forTitle: "Ricerca libera sul titolo",
                        forSla: "Priorità",
                        forCategory: "Categoria",
                        forCustomer: "Cliente",
                        forStatus: "Ricerca per stato",
                        forUsers: "Ricerca per assegnazione",
                        forOnlyAssigned: "",
                        isAssigned: "Seleziona tutti gli assegnati",
                        inProgress: "In lavorazione",
                        waitFeedback: "In attesa di feedback",
                        open: "Aperto",
                        closed: "Chiuso",
                        forTime: "Ricezione",
                        receivedFrom: "Data inizio",
                        receivedTo: "Data fine",
                        receivedSeparator: "al",
                        setFilters: "imposta i filtri",
                        delFilters: "cancella i filtri",
                    },

                    placeholder1: "seleziona",
                    placeholder2: "scrivi...",
                    placeholder3: "cerca...",
                },
            },
            filters: {
                title: "Filtri di ricerca tickets",
            },
        },

        editor: {
            newTicket: "Creazione nuovo ticket",
            addComment: "aggiungi un commento",
            comment: "Scrivi un commento e pubblicalo",
            save: "salva",
            cancel: "annulla",
            internalComment: "Registra solo per interni",
            externalComment: "Registra per tutti",
            confirmComment: "Confermi la registrazione di questo commento?",
            confirmTicketCreation: "Vuoi creare questo ticket?",
            required: "Campo obbligatorio",
        },

        errors: {
            title: "Il campo titolo è obbligatorio",
            body: "Il campo descrizione è obbligatorio",
            category: " Il ticket deve essere associato ad una categoria",
            priority: " Il ticket deve avere una priorità",
            customer: "Il ticket deve avere un cliente di riferimento",
        },
    },
};
