<template>
    <el-drawer :visible.sync="isOpen" :direction="direction">
        <template v-slot:title>
            <span class="font-bold">{{
                $t("tickets.drawer.filters.title")
            }}</span>
        </template>
        <div class="p-4" v-if="form">
            <el-form ref="form" :model="form" label-position="top">
                <el-form-item
                    :label="
                        $t('tickets.drawer.actions.sections.filter.forTitle')
                    "
                >
                    <el-input
                        v-model="form.search"
                        :placeholder="
                            $t('tickets.drawer.actions.sections.placeholder3')
                        "
                    />
                </el-form-item>
                <el-form-item
                    :label="$t('tickets.drawer.actions.sections.filter.forSla')"
                >
                    <el-select
                        v-model="form.priority_id"
                        :placeholder="
                            $t('tickets.drawer.actions.sections.placeholder3')
                        "
                        class="w-full"
                    >
                        <el-option
                            v-for="item in listPriorities"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="
                        $t('tickets.drawer.actions.sections.filter.forUsers')
                    "
                >
                    <el-select
                        v-model="form.assigned_to"
                        filterable
                        multiple
                        remote
                        reserve-keyword
                        :remote-method="srcUsers"
                        :loading="loadingUsers"
                        class="w-full"
                        :placeholder="
                            $t('tickets.drawer.actions.sections.placeholder3')
                        "
                    >
                        <el-option
                            v-for="item in listUsers"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="
                        $t('tickets.drawer.actions.sections.filter.forCustomer')
                    "
                >
                    <el-select
                        v-model="form.customers_id"
                        filterable
                        multiple
                        remote
                        reserve-keyword
                        :remote-method="srcCustomers"
                        :loading="loadingCustomers"
                        class="w-full"
                        :placeholder="
                            $t('tickets.drawer.actions.sections.placeholder3')
                        "
                    >
                        <el-option
                            v-for="item in listCustomers"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="
                        $t('tickets.drawer.actions.sections.filter.forStatus')
                    "
                >
                    <el-checkbox v-model="form.status.in_progress">
                        {{
                            $t(
                                "tickets.drawer.actions.sections.filter.inProgress"
                            )
                        }}
                    </el-checkbox>
                    <el-checkbox v-model="form.status.wait_feedback">
                        {{
                            $t(
                                "tickets.drawer.actions.sections.filter.waitFeedback"
                            )
                        }}
                    </el-checkbox>
                    <el-checkbox v-model="form.status.open">
                        {{ $t("tickets.drawer.actions.sections.filter.open") }}
                    </el-checkbox>
                    <el-checkbox v-model="form.status.closed">
                        {{
                            $t("tickets.drawer.actions.sections.filter.closed")
                        }}
                    </el-checkbox>
                </el-form-item>

                <el-form-item
                    :label="
                        $t('tickets.drawer.actions.sections.filter.forTime')
                    "
                >
                    <el-date-picker
                        v-model="form.time.received"
                        type="daterange"
                        format="dd-MM-yyyy"
                        value-format="yyyy-MM-dd"
                        clearable
                        :range-separator="
                            $t(
                                'tickets.drawer.actions.sections.filter.receivedSeparator'
                            )
                        "
                        :start-placeholder="
                            $t(
                                'tickets.drawer.actions.sections.filter.receivedFrom'
                            )
                        "
                        :end-placeholder="
                            $t(
                                'tickets.drawer.actions.sections.filter.receivedTo'
                            )
                        "
                    >
                    </el-date-picker>
                </el-form-item>

                <el-form-item class="text-right">
                    <el-button type="primary" @click="setFilters">
                        {{
                            $t(
                                "tickets.drawer.actions.sections.filter.setFilters"
                            )
                        }}
                    </el-button>
                    <el-button @click="resetAllFilters">
                        {{
                            $t(
                                "tickets.drawer.actions.sections.filter.delFilters"
                            )
                        }}
                    </el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-drawer>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters, mutations, actions } from "@/store";

export default {
    name: "TicketsFilter",

    computed: {
        categories: getters.ticket.categories,
        priorities: getters.ticket.priorities,
        customers: getters.ticket.customers,
        users: getters.ticket.users,
        filters: getters.ticket.filters,

        listPriorities() {
            return this.priorities.map((el) => {
                return {
                    label: this.$t("tickets.priority." + el.name.toLowerCase()),
                    value: el.id,
                };
            });
        },
    },

    data() {
        return {
            direction: "ltr",
            isOpen: false,
            form: null,

            loadingCategories: false,
            listCategories: [],

            loadingCustomers: false,
            listCustomers: [],

            loadingUsers: false,
            listUsers: [],
        };
    },

    watch: {
        isOpen(val) {
            if (val) {
                this.form = this.$copyStatic(this.filters);
            }
        },
    },

    mounted() {
        EventBus.$on("toggleTicketFilters", (val) => (this.isOpen = val));
        EventBus.$on("removeFilterTickets", () => this.delFilters());
    },

    beforeDestroy() {
        EventBus.$off("toggleTicketFilters");
        EventBus.$off("removeFilterTickets");
    },

    methods: {
        async srcCategories(query) {
            try {
                if (query.trim() !== "") {
                    await actions.ticket.getCategories({ term: query });
                    this.listCategories = this.categories.map((el) => {
                        return {
                            label: el.name,
                            value: el.id,
                        };
                    });
                } else {
                    this.listCategories = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async srcCustomers(query) {
            try {
                if (query.trim() !== "") {
                    await actions.ticket.getCustomers({ term: query });
                    this.listCustomers = this.customers.map((el) => {
                        return {
                            label: el.name,
                            value: el.id,
                        };
                    });
                } else {
                    this.listCustomers = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async srcUsers(query) {
            try {
                if (query.trim() !== "") {
                    await actions.ticket.getUsers({ email: query });
                    this.listUsers = this.users.map((el) => {
                        return {
                            label: el.username,
                            value: el.id,
                        };
                    });
                } else {
                    this.listUsers = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        setFilters() {
            this.isOpen = false;

            console.log("????????", this.filters, this.form);

            const f = this.$copyStatic(this.form);
            mutations.ticket.setFilters(f);

            //EventBus.$emit('filterTickets', this.form);
        },

        resetAllFilters() {
            EventBus.$emit(`resetAllFilters`, true);
        },

        delFilters() {
            mutations.ticket.unsetFilters();
            const f = this.$copyStatic(this.filters);
            this.form = f;
            //this.isOpen = false;
            //EventBus.$emit('filterTickets', this.form);
            //EventBus.$emit('resetInitialCond', true);
        },
    },
};
</script>
