<template>
    <div>
        <div class="text-right mb-2">
            <el-button
                class="cursor-pointer"
                type="success"
                size="mini"
                @click="addDelivery"
            >
                <i class="fas fa-plus mr-2"></i>
                {{$t('administration.domains.new')}}
            </el-button>
        </div>
        <div class="flex py-2" v-for="delivery in deliveries" :key="delivery.id">
            <div class="flex-1 mr-2">
                <el-input
                    class="w-full"
                    placeholder=""
                    v-model="delivery.email"
                />
            </div>
            <div class="flex-initial">
                <el-button
                    class="cursor-pointer mr-2"
                    type="default"
                    size="mini"
                    icon="fas fa-pen"
                    @click="saveDelivery(delivery)"
                />

                <el-popconfirm
                    :title="$t('administration.domains.prompt.confirmDelete')"
                    :confirm-button-text="$t('administration.domains.prompt.ok')"
                    :cancel-button-text="$t('administration.domains.prompt.ko')"
                    @confirm="deleteDelivery(delivery)"
                >
                    <el-button
                        class="cursor-pointer"
                        type="danger"
                        size="mini"
                        icon="fas fa-trash"
                        slot="reference"
                    />
                </el-popconfirm>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import BaseMixin from '@/mixins/Base.js';
import { getters, actions } from '@/store';

export default {
    name: 'AdminDomainsList',

    mixins: [BaseMixin],

    computed: {
        currentCustomer: getters.administration.currentCustomer,
        deliveries: getters.administration.deliveries
    },

    data() {
        return {
            loading: false
        }
    },

    async mounted() {
        try {
            this.loading = true;
            await this.getDeliveries();
            this.loading = false;
            
            EventBus.$on('updateDeliveries', async () => {
                this.loading = true;
                await this.getDeliveries();
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
            this.loading = false;
            this.$log.debug(error);
            this.$notify.error(error.message);
        }
    },

    beforeDestroy() {
        EventBus.$off('updateDeliveries');
    },

    methods: {
        async getDeliveries() {
            try {
                await actions.administration.getDeliveries({
                    payload: {
                        customer_id: this.currentCustomer.id,
                        is_whitelisted: 1
                    }
                });
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        addDelivery() {
            this.$prompt(this.$t('administration.domains.prompt.text'), this.$t('administration.domains.prompt.title'), {
                confirmButtonText: this.$t('administration.domains.prompt.ok'),
                cancelButtonText: this.$t('administration.domains.prompt.ko'),
                inputPattern: /^@(.)*$/,
                inputErrorMessage: this.$t('administration.domains.prompt.validationError')
            }).then(async ({value}) => {
                console.log('>>>>>>>>>', value)

                await actions.administration.saveDelivery({
                    payload: {
                        customer_id: this.currentCustomer.id,
                        emails: [value]
                    }
                });
                await this.getDeliveries();

                this.$message({
                    type: 'success',
                    message: this.$t('administration.domains.prompt.operationSuccess')
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: this.$t('administration.domains.prompt.operationAborted')
                });
            });
        },

        async saveDelivery(delivery) {
            try {
                await actions.administration.saveDelivery({
                    query: {
                        id: delivery.id,
                    },
                    payload: {
                        email: delivery.email
                    }
                });

                this.$message({
                    type: 'success',
                    message: this.$t('administration.domains.prompt.operationSuccess')
                });
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async deleteDelivery(delivery) {
            try {
                await actions.administration.deleteDelivery({
                    id: delivery.id
                });

                await this.getDeliveries();

                this.$notify.success(this.$t('administration.domains.prompt.operationSuccess'));
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        }
    }
}
</script>
