export default {
    welcome: "Welcome",
    yes: "Yes",
    no: "No",
    search: "Find",
    select: "Select",
    register: "Register",
    print: "Print",
    back: "Back",
    save: "Save",
    close: "Close",
    operationCompleted: "Operation completed",
    operationFailed: "Operation failed",
    operationCanceled: "Operation canceled",

    menu: {
        activateNotifications: "get email notifications",

        vertical: {
            tickets: {
                main: "Tickets list",
                assigned: "Assigned",
                todo: "Not assigned",
                mine: "My tickets",
            },
            knowledge: {
                main: "Documentation",
            },
            reports: {
                main: "Reports",
            },
            administration: {
                main: "Administration",
                customers: {
                    main: "Customers",
                },
                roles: {
                    main: "Roles and permissions",
                },
                categories: {
                    main: "Categories",
                },
                users: {
                    main: "Users",
                },
                whitelist: {
                    main: "Whitelist email",
                },
                blacklist: {
                    main: "Blacklist email",
                },
            },
        },
    },
};
