<template>
    <div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.delete_ticket)">
            <p class="py-2">{{$t('tickets.drawer.actions.sections.actions.delete')}}</p>
            <div class="flex">
                <div class="flex-1">
                    <el-switch
                        class="mr-4"
                        v-model="form.del"
                        inactive-text=""
                        :active-text="$t('tickets.drawer.actions.sections.actions.deleteText')"
                    />
                </div>
                <div class="flex-initial">
                    <el-button
                        type="primary"
                        :disabled="!form.del"
                        @click="confirmDelete"
                    >
                        {{$t('tickets.drawer.actions.sections.actions.confirmDelete')}}
                    </el-button>
                </div>
            </div>
        </div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.close_ticket)">
            <p class="py-2">{{$t('tickets.drawer.actions.sections.actions.close')}}</p>
            <div class="flex">
                <div class="flex-1">
                    <el-switch
                        class="mr-4"
                        v-model="form.close"
                        inactive-text=""
                        :active-text="$t('tickets.drawer.actions.sections.actions.closeText')"
                    />
                </div>
                <div class="flex-initial">
                    <el-button
                        type="primary"
                        :disabled="!form.close"
                        @click="confirmClose"
                    >
                        {{$t('tickets.drawer.actions.sections.actions.confirmClose')}}
                    </el-button>
                </div>
            </div>
        </div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.assign_to_me_ticket)">
            <p class="py-2">{{$t('tickets.drawer.actions.sections.actions.take')}}</p>
            <div class="flex">
                <div class="flex-1">
                    <el-switch
                        class="mr-4"
                        v-model="form.take"
                        inactive-text=""
                        :active-text="$t('tickets.drawer.actions.sections.actions.takeText')"
                    />
                </div>
                <div class="flex-initial">
                    <el-button
                        type="primary"
                        :disabled="form.take && isAssignedToMe"
                        @click="confirmTake"
                    >
                        {{$t('tickets.drawer.actions.sections.actions.confirmTake')}}
                    </el-button>
                </div>
            </div>
        </div>
        <div class="p-2" v-if="$iCan(constants.permissions.tickets.assign_to_operator_ticket)">
            <p class="py-2">{{$t('tickets.drawer.actions.sections.actions.assignTo')}}</p>
            <el-select
                v-model="form.assignToUser"
                filterable
                remote
                reserve-keyword
                :remote-method="srcUsers"
                :loading="loadingUsers"
                class="w-full py-2"
                :placeholder="$t('tickets.drawer.actions.sections.actions.srcUsers')"
            >
                <el-option
                    v-for="item in listUsers"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
            <div class="flex">
                <div class="flex-1">
                    <el-switch
                        class="mr-4"
                        v-model="form.assignTo"
                        inactive-text=""
                        :active-text="$t('tickets.drawer.actions.sections.actions.assignText')"
                    />
                </div>
                <div class="flex-initial">
                    <el-button
                        type="primary"
                        :disabled="!form.assignTo"
                        @click="confirmAssign"
                    >
                        {{$t('tickets.drawer.actions.sections.actions.confirmAssign')}}
                    </el-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import constants from '@/store/constants';
import { EventBus } from '@/store/eventBus';
import { getters, actions } from "@/store";

export default {
    name: 'TicketMenuOperations',

    computed: {
        user: getters.user.data,
        users: getters.ticket.users,
        currentTicket: getters.ticket.currentTicket,
        categories: getters.ticket.categories,
        priorities: getters.ticket.priorities,
        status: getters.ticket.status,

        isAssignedToMe() {
            return this.currentTicket && this.currentTicket.assigned_to === this.user.id;
        },
    },

    data() {
        return {
            constants,
            form: {
                del: false,
                close: false,
                take: false,
                assignTo: false,
                assignToUser: null
            },

            listUsers: [],
            loadingUsers: false
        };
    },

    created() {
        if (this.isAssignedToMe) {
            this.form.take = true;
        }
    },

    methods: {
        async confirmDelete() {
            try {
                if (this.form.del) {
                    await actions.ticket.deleteTicket({
                        ticket_id: this.currentTicket.id
                    });

                    EventBus.$emit('updateTicketList', true);
                    EventBus.$emit('toggleTicketActionMenu', false);
                    if (this.$route.name !== 'tickets_list') {
                        this.$router.replace({ name: 'tickets_list' });
                    }
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async confirmClose() {
            try {
                if (this.form.close) {
                    await actions.ticket.closeTicket({
                        ticket_id: this.currentTicket.id
                    });

                    EventBus.$emit('updateTicketList', true);
                    EventBus.$emit('toggleTicketActionMenu', false);
                    if (this.$route.name !== 'tickets_list') {
                        this.$router.replace({ name: 'tickets_list' });
                    }
                }

            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async confirmTake() {
            try {
                if (this.form.take) {
                    await actions.ticket.assignTicket({
                        ticket_id: this.currentTicket.id,
                        user_id: this.user.id
                    });

                    EventBus.$emit('updateTicketList', true);
                    if (this.$route.name !== 'tickets_list') {
                        this.$router.replace({ name: 'tickets_list' });
                    }
                } else {
                    await actions.ticket.unassignTicket({
                        ticket_id: this.currentTicket.id,
                        user_id: this.user.id
                    });

                    EventBus.$emit('updateTicketList', true);
                    if (this.$route.name !== 'tickets_list') {
                        this.$router.replace({ name: 'tickets_list' });
                    }
                }

            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async confirmAssign() {
            try {
                if (this.form.assignTo) {
                    await actions.ticket.assignTicket({
                        ticket_id: this.currentTicket.id,
                        user_id: this.form.assignToUser
                    });

                    EventBus.$emit('updateTicketList', true);
                    if (this.$route.name !== 'tickets_list') {
                        this.$router.replace({ name: 'tickets_list' });
                    }
                }

            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async srcUsers(query) {
            try {
                if (query.trim() !== '') {
                    await actions.ticket.getUsers({ email: query });
                    this.listUsers = this.users.map(el => {
                        return {
                            label: el.username,
                            value: el.id
                        }
                    });
                } else {
                    this.listUsers = [];
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        }
    }
}
</script>
