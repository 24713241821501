<template>
    <el-switch v-model="lang" active-text="ITA" inactive-text="ENG" />
</template>

<script>
import i18n from "@/setup/i18n";
import { getters, mutations } from "@/store";

export default {
    name: "Locale",

    data() {
        return {
            lang: true,
        };
    },

    computed: {
        locale: getters.locale,
    },

    mounted() {
        if (!this.locale) {
            mutations.setLocale(this.lang ? "it" : "en");
        }

        this.lang = this.locale.toLowerCase() === "it";
    },

    watch: {
        lang: function (val) {
            const l = val ? "it" : "en";
            mutations.setLocale(l);
            i18n.locale = this.locale;
        },
    },
};
</script>
