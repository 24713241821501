"use strict";

export default {
    path: "/",
    component: () =>
        import(
            /* webpackChunkName: "home" */ "@/pages/Dashboard/Layout/DashboardLayout.vue"
        ),
    children: [
        {
            path: "tickets",
            name: "tickets_list",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketTopMenu.vue"
                    ),
                slot2: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketMain.vue"
                    ),
                slot3: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketFilters.vue"
                    ),
                slot4: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketMenuLayout.vue"
                    ),
                slot5: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketNew.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "tickets",
                sub: "todo",
                type: "general",
            },
        },
        {
            path: "ticket/:ticket_id",
            name: "ticket_sheet",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketSheetMenu.vue"
                    ),
                slot2: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketSheet.vue"
                    ),
                slot3: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketEditor.vue"
                    ),
                slot4: () =>
                    import(
                        /* webpackChunkName: "home" */ "@/entities/tickets/TicketMenuLayout.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "tickets",
            },
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
