'use strict';

import axios from 'axios';
import { EventBus } from './eventBus';

export default localStore => {
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, access_token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(access_token);
            }
        });

        failedQueue = [];
    };

    const api = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        timeout: 30000
    });

    api.interceptors.request.use(
        async config => {
            const user = localStore.session('user');

            if (user && user.access_token) {
                config.headers.Authorization = `Bearer ${user.access_token}`;
            }

            if (['get', 'delete'].indexOf(config.method.toLowerCase()) > -1) {
                config.data = {};
            }

            config.headers['Content-Type'] = 'application/json';

            return config;
        },
        error => {
            Promise.reject(error);
        }
    );

    api.interceptors.response.use((response) => {
        return response;
    }, async function (error) {
        const originalRequest = error.config;

        if (error.response.status === 401 && !originalRequest._retry) {
            this.$log.debug('>>>> refresh >>>', isRefreshing);

            if (isRefreshing) {
                return new Promise(function (resolve, reject) {
                    failedQueue.push({
                        resolve,
                        reject
                    });
                })
                .then(access_token => {
                    axios.defaults.headers.common.Authorization = 'Bearer ' + access_token;
                    return api(originalRequest);
                })
                .catch(err => {
                    return Promise.reject(err);
                });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            try {
                const res = await api.get('/auth/refresh');

                EventBus.$emit('store_setToken', res.data.access_token);

                axios.defaults.headers.common.Authorization = 'Bearer ' + res.data.access_token;
                originalRequest.headers.Authorization = 'Bearer ' + res.data.access_token;
                processQueue(null, res.data.access_token);

                return api(originalRequest);
            } catch (error) {
                return Promise.reject(error);
            } finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    });

    return api;
};
