"use strict";

export default {
    path: "/knowledge",
    component: () =>
        import(
            /* webpackChunkName: "home" */ "@/pages/Dashboard/Layout/DashboardLayout.vue"
        ),
    children: [
        {
            path: "",
            name: "knowledge",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "knowledge" */ "@/entities/knowledge/KnowledgeTopMenu.vue"
                    ),
                slot2: () =>
                    import(
                        /* webpackChunkName: "knowledge" */ "@/entities/knowledge/KnowledgeMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "knowledge",
            },
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
