"use strict";

import Vue from "vue";
import qs from "query-string";
import apiLib from "@/store/api";
import { localStore } from "@/store/localStore";

const api = apiLib(localStore);

const state = Vue.observable({
    admin: {
        users: [],
        customers: [],
        currentUser: null,
        whitelist: [],
        blacklist: [],
        deliveries: [],
        currentCustomer: null,
        customersInfo: {
            total: 0,
        },
    },
});

export const getters = {
    users: () => state.admin.users,
    customers: () => state.admin.customers,
    currentUser: () => state.admin.currentUser,
    whitelist: () => state.admin.whitelist,
    blacklist: () => state.admin.blacklist,
    deliveries: () => state.admin.deliveries,
    currentCustomer: () => state.admin.currentCustomer,
    customersInfo: () => state.admin.customersInfo,
};

export const mutations = {
    setUsers: (val) => {
        state.admin.users = val;
    },

    setCustomers: (val) => {
        state.admin.customers = val;
    },

    setWhitelist: (val) => {
        state.admin.whitelist = val;
    },

    setBlacklist: (val) => {
        state.admin.blacklist = val;
    },

    setDeliveries: (val) => {
        state.admin.deliveries = val;
    },

    setCurrentUser: (val) => {
        state.admin.currentUser = val;
    },

    setCurrentCustomer: (val) => {
        state.admin.currentCustomer = val;
    },

    setCustomersInfo: (val) => {
        state.admin.customersInfo = val;
    },
};

export const actions = {
    async saveRole(payload) {
        try {
            if (payload.role_id) {
                const id = payload.role_id;
                await api.put(`/roles/${id}`, payload);
            } else {
                await api.post(`/roles`, payload);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveRoleError",
                message: "Cannot save role",
                status: 400,
            });
        }
    },

    async deleteRole(payload) {
        try {
            if (payload.role_id) {
                const id = payload.role_id;
                await api.delete(`/roles/${id}`);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteRoleError",
                message: "Cannot delete role",
                status: 400,
            });
        }
    },

    async saveCategory(payload) {
        try {
            if (payload.category_id) {
                const id = payload.category_id;
                await api.put(`/categories/${id}`, payload);
            } else {
                await api.post(`/categories`, payload);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveCategoryError",
                message: "Cannot save category",
                status: 400,
            });
        }
    },

    async deleteCategory(payload) {
        try {
            if (payload.category_id) {
                const id = payload.category_id;
                await api.delete(`/categories/${id}`);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteCategoryError",
                message: "Cannot delete category",
                status: 400,
            });
        }
    },

    async getWhitelist() {
        try {
            // TODO
            mutations.setWhitelist([
                {
                    id: 1,
                    name: "microsoft.com",
                },
            ]);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetWhitelistError",
                message: "Cannot get whitelist",
                status: 400,
            });
        }
    },

    async getBlacklist({ query }) {
        try {
            const q = qs.stringify(query);
            const res = await api.get(`/blacklists${q !== "" ? "?" + q : ""}`, {
                "content-type": "application/json",
            });

            mutations.setBlacklist(res.data.data);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetBlacklistError",
                message: "Cannot get blacklist",
                status: 400,
            });
        }
    },

    async addBlacklistEntry(payload) {
        try {
            await api.post(`/blacklists`, payload);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "AddBlacklistEntryError",
                message: "Cannot add entry to blacklist",
                status: 400,
            });
        }
    },

    async deleteBlacklistEntry(id) {
        try {
            await api.delete(`/blacklists/${id}`);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteBlacklistEntryError",
                message: "Cannot delete blacklist entry",
                status: 400,
            });
        }
    },

    async addWhitelistEntry(payload) {
        try {
            //TODO
            console.log(payload);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "AddWhitelistEntryError",
                message: "Cannot add entry to whitelist",
                status: 400,
            });
        }
    },

    async deleteWhitelistEntry(payload) {
        try {
            //TODO
            console.log(payload);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteWhitelistEntryError",
                message: "Cannot delete whitelist entry",
                status: 400,
            });
        }
    },

    async getUsers({ query, payload }) {
        try {
            const q = qs.stringify(query);
            const res = await api.post(
                `/search/users${q !== "" ? "?" + q : ""}`,
                payload,
                {
                    "content-type": "application/json",
                }
            );

            mutations.setUsers(res.data.data);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListUsersError",
                message: "Cannot list users",
                status: 400,
            });
        }
    },

    async getUsersWithPagination(url, payload = {}) {
        try {
            const res = await api.post(url, payload, {
                "content-type": "application/json",
            });

            const r = {
                users: res.data ? res.data.data : [],
                total: res.data.total,
                page: res.data.page,
                perPage: parseInt(res.data.perPage),
            };

            mutations.setUsers(r);
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListUsersError",
                message: "Cannot list users",
                status: 400,
            });
        }
    },

    async saveUser(payload) {
        try {
            if (payload.user_id) {
                const id = payload.user_id;
                await api.post(`/users/${id}`, payload);
            } else {
                await api.post(`/users`, payload);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveUserError",
                message: "Cannot save user",
                status: 400,
            });
        }
    },

    async deleteUser(payload) {
        try {
            if (payload.user_id) {
                const id = payload.user_id;
                await api.delete(`/users/${id}`);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteUserError",
                message: "Cannot delete user",
                status: 400,
            });
        }
    },

    async getCustomers(payload) {
        try {
            const q = qs.stringify(payload);
            const res = await api.post(
                `/search/customers${q !== "" ? "?" + q : ""}`,
                {}
            );
            mutations.setCustomers(res.data.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListCustomersError",
                message: "Cannot list customers",
                status: 400,
            });
        }
    },

    async getCustomersWithPagination(url, payload) {
        try {
            const res = await api.post(url, payload);

            const r = {
                customers: res.data ? res.data.data : [],
                total: res.data.total,
                page: res.data.page,
                perPage: parseInt(res.data.perPage),
            };

            mutations.setCustomers(r);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListTCustomersException",
                message: "Cannot list customers",
                status: 400,
            });
        }
    },

    async saveCustomer(payload) {
        try {
            if (payload.customer_id) {
                const id = payload.customer_id;
                await api.put(`/customers/${id}`, payload);
            } else {
                await api.post(`/customers`, payload);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveCustomerError",
                message: "Cannot save customer",
                status: 400,
            });
        }
    },

    async deleteCustomer(payload) {
        try {
            if (payload.customer_id) {
                const id = payload.customer_id;
                await api.delete(`/customers/${id}`);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteCustomerError",
                message: "Cannot delete customer",
                status: 400,
            });
        }
    },

    async customersInfo(mainCustomer) {
        try {
            const res = await api.get(`/search/customers/info/${mainCustomer}`);
            mutations.setCustomersInfo(res.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "CustomersInfoError",
                message: "Cannot get customers info",
                status: 400,
            });
        }
    },

    async getDeliveries({ query, payload }) {
        try {
            const q = qs.stringify(query);
            const res = await api.post(
                `/search/deliveries/${payload.customer_id}${
                    q !== "" ? "?" + q : ""
                }`,
                payload,
                {
                    "content-type": "application/json",
                }
            );
            mutations.setDeliveries(res.data.data);
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListDeliveriesError",
                message: "Cannot list deliveries",
                status: 400,
            });
        }
    },

    async saveDelivery({ query, payload }) {
        try {
            if (query && query.id) {
                const id = query.id;
                await api.put(`/deliveries/${id}`, payload);
            } else {
                await api.post(`/deliveries`, payload);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveDeliveriesError",
                message: "Cannot save delivery",
                status: 400,
            });
        }
    },

    async deleteDelivery(payload) {
        try {
            if (payload.id) {
                const id = payload.id;
                await api.delete(`/deliveries/${id}`);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "DeleteDeliveryError",
                message: "Cannot delete delivery",
                status: 400,
            });
        }
    },
};
