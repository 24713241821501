<template>
    <el-card class="scopeBlock">
        <div class="flex">
            <div class="flex-1">
                {{
                    $t("reports.modal.form.scopeBlockName", { n: value.index })
                }}
            </div>
            <div class="flex-initial">
                <div v-if="!value.isCompleted">
                    <el-button type="danger" @click="remove">
                        <i class="fas fa-trash"></i>
                    </el-button>

                    <el-button type="primary" @click="save">
                        <i class="fas fa-save"></i>
                    </el-button>
                </div>
                <div v-else>
                    <el-button type="success" @click="unlock">
                        <i class="fas fa-lock"></i>
                    </el-button>
                </div>
            </div>
        </div>

        <el-form-item
            prop="scope"
            :label="$t('reports.modal.form.scopeBlock.scope')"
        >
            <el-select
                clearable
                v-model="localValue.scope"
                class="w-full"
                :disabled="value.isCompleted"
            >
                <el-option
                    v-for="item in scopes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                />
            </el-select>
        </el-form-item>

        <div class="flex">
            <div class="flex-1 mr-2">
                <el-form-item
                    prop="from"
                    :label="$t('reports.modal.form.scopeBlock.from')"
                >
                    <el-time-select
                        :disabled="value.isCompleted"
                        v-model="localValue.from"
                        :picker-options="{
                            start: '08:00',
                            step: '00:15',
                            end: '19:30',
                        }"
                    />
                </el-form-item>
            </div>
            <div class="flex-1">
                <el-form-item
                    prop="from"
                    :label="$t('reports.modal.form.scopeBlock.to')"
                >
                    <el-time-select
                        :disabled="value.isCompleted"
                        v-model="localValue.to"
                        :picker-options="{
                            start: '08:00',
                            step: '00:15',
                            end: '19:30',
                        }"
                    />
                </el-form-item>
            </div>
        </div>

        <el-form-item
            prop="scope"
            :label="$t('reports.modal.form.scopeBlock.description')"
        >
            <el-input
                :disabled="value.isCompleted"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 8 }"
                maxlength="255"
                show-word-limit
                v-model="localValue.description"
            />
        </el-form-item>
    </el-card>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "ModalScopeBlock",

    props: ["value", "isCompleted"],

    computed: {
        scopes: getters.reports.scopes,
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket,
    },

    data() {
        return {
            localValue: {},
        };
    },

    created() {
        this.localValue = this.$copyStatic(this.value);
    },

    methods: {
        isAssignedToMe() {
            console.log("<< TICKET >>", this.currentTicket, this.user.id);
            return this.currentTicket.assigned_to === this.user.id;
        },

        remove() {
            this.$emit("remove", this.localValue.index);
        },

        save() {
            this.$emit("save", this.localValue);
        },

        unlock() {
            this.$emit("unlock", this.localValue.index);
        },
    },
};
</script>

<style lang="scss" scoped>
.scopeBlock {
    background-color: #dddddd;
    border: 1px solid #526f7a;
}
</style>
