<template>
    <div>
        <div class="text-right pb-4">
            <AdminCategoryNew />
        </div>
        <div class="grid grid-cols-3 gap-4">
            <el-card
                class="box-card"
                v-for="category in categoryList"
                :key="category.id"
            >
                <el-input
                    type="input"
                    v-model="category.name"
                    placeholder=""
                    class="custom-input"
                ></el-input>
                <div class="py-2">
                    <el-input
                        class="w-full mt-2 custom-input"
                        v-model="category.description"
                        placeholder=""
                        type="textarea"
                        rows="2"
                    />
                </div>
                <div class="text-right">
                    <el-popconfirm
                        :title="
                            $t('administration.categories.prompt.confirmSave')
                        "
                        :confirm-button-text="
                            $t('administration.categories.prompt.ok')
                        "
                        :cancel-button-text="
                            $t('administration.categories.prompt.ko')
                        "
                        @confirm="saveCategory(category)"
                        v-if="
                            $iCan(
                                constants.permissions.administration
                                    .update_category
                            )
                        "
                    >
                        <el-button
                            slot="reference"
                            type="primary"
                            icon="fas fa-save"
                        />
                    </el-popconfirm>
                    <!-- <el-popconfirm
                        :title="$t('administration.categories.prompt.confirmDelete')"
                        :confirm-button-text="$t('administration.categories.prompt.ok')"
                        :cancel-button-text="$t('administration.categories.prompt.ko')"
                        @confirm="deleteCategory(category)"
                    >
                        <el-button
                            slot="reference"
                            type="default"
                            class="mr-2"
                            icon="fas fa-trash"
                        />
                    </el-popconfirm> -->
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters, actions } from "@/store";
import constants from "@/store/constants";
import AdminCategoryNew from "@/entities/administration/AdminCategoryNew.vue";

export default {
    name: "AdminCategoriesMain",

    components: {
        AdminCategoryNew,
    },

    computed: {
        categories: getters.ticket.categories,
    },

    watch: {
        categories: function (val) {
            this.setCategoryList(val);
        },
    },

    data() {
        return {
            constants,
            categoryList: [],
        };
    },

    async mounted() {
        await actions.ticket.getCategories();

        this.setCategoryList(this.categories);

        EventBus.$on("updateCategories", async () => {
            await actions.ticket.getCategories();
        });
    },

    beforeDestroy() {
        EventBus.$off("updateCategories");
    },

    methods: {
        setCategoryList(val) {
            const v = this.$copyStatic(val);

            this.categoryList = v.map((el) => {
                return el;
            });
        },

        async saveCategory(category) {
            try {
                if (!category.name || category.name.trim() === "") {
                    this.$notify.error(this.$t("tickets.errors.category"));
                    return false;
                }

                await actions.administration.saveCategory({
                    category_id: category.id,
                    name: category.name,
                    description: category.description,
                });

                await actions.ticket.getCategories();

                this.$notify.success(
                    this.$t("administration.categories.prompt.operationSuccess")
                );
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async deleteCategory(category) {
            try {
                await actions.administration.deleteCategory({
                    category_id: category.id,
                });

                await actions.ticket.getCategories();

                this.$notify.success(
                    this.$t("administration.categories.prompt.operationSuccess")
                );
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },
    },
};
</script>
