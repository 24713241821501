<template>
    <el-dialog
        :visible.sync="modalOpen"
        fullscreen
        class="border-8 border-blue-400"
    >
        <el-form ref="form" :model="form" :rules="rules" size="mini">
            <slot name="title">
                <div class="p-2 flex">
                    <div class="flex-1">
                        <i class="fas fa-flag-checkered mr-4 text-blue-400"></i>
                        <span class="text-2xl">{{
                            $t("reports.modal.title")
                        }}</span>
                        <i class="fas fa-arrow-right px-4"></i>
                        <span class="text-xl"> #{{ currentTicket.id }} </span>
                    </div>
                    <div class="flex-initial">
                        <el-button type="primary" @click="prev" rounded>
                            <i class="fas fa-arrow-left"></i>
                        </el-button>
                        <el-button
                            type="primary"
                            @click="next"
                            rounded
                            class="mr-2"
                            :disabled="!issetReport"
                        >
                            <i class="fas fa-arrow-right"></i>
                        </el-button>
                        <el-popconfirm
                            v-if="isAssignedToMe()"
                            class="ml-2"
                            :confirm-button-text="
                                $t('reports.modal.form.approveDialog.confirm')
                            "
                            :cancel-button-text="
                                $t('reports.modal.form.approveDialog.cancel')
                            "
                            icon="el-icon-info"
                            icon-color="red"
                            :title="
                                $t('reports.modal.form.approveDialog.title')
                            "
                            @confirm="saveToApprove"
                        >
                            <el-button
                                type="success"
                                slot="reference"
                                :disabled="!canSentToApprove"
                            >
                                <i class="fas fa-paper-plane"></i>
                                {{ $t("reports.modal.form.saveToApprove") }}
                            </el-button>
                        </el-popconfirm>
                    </div>
                </div>
            </slot>

            <el-steps :active="activeStep" finish-status="success">
                <el-step
                    :title="$t('reports.modal.form.steps.step1.title')"
                    :description="
                        $t('reports.modal.form.steps.step1.description')
                    "
                    icon="fas fa-info-circle"
                />
                <el-step
                    :title="$t('reports.modal.form.steps.step2.title')"
                    :description="
                        $t('reports.modal.form.steps.step2.description')
                    "
                    icon="fas fa-check-double"
                />
                <el-step
                    :title="$t('reports.modal.form.steps.step3.title')"
                    :description="
                        $t('reports.modal.form.steps.step3.description')
                    "
                    icon="fas fa-hammer"
                />
            </el-steps>

            <div class="py-4">
                <div v-show="activeStep === 0">
                    <div class="grid grid-cols-3 gap-4">
                        <div>
                            <el-form-item
                                prop="customer"
                                :label="$t('reports.modal.form.customer')"
                            >
                                <el-input
                                    type="text"
                                    v-model="form.customer"
                                    :readonly="true"
                                />
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item
                                prop="date"
                                :label="$t('reports.modal.form.date')"
                            >
                                <el-date-picker
                                    clearable
                                    v-model="form.date"
                                    type="date"
                                    format="dd-MM-yyyy"
                                    value-format="yyyy-MM-dd"
                                    style="width: 100%"
                                />
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item
                                prop="location"
                                :label="$t('reports.modal.form.location')"
                            >
                                <el-select
                                    clearable
                                    v-model="form.location"
                                    class="w-full"
                                    :disabled="!mainCustomer"
                                >
                                    <el-option
                                        v-for="item in listLocations"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item
                                prop="traveltime"
                                :label="$t('reports.modal.form.traveltime')"
                            >
                                <el-select
                                    clearable
                                    v-model="form.traveltime"
                                    class="w-full"
                                    :disabled="!mainCustomer"
                                >
                                    <el-option
                                        v-for="item in traveltimes"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                        </div>
                        <div>
                            <el-form-item
                                prop="type"
                                :label="$t('reports.modal.form.type')"
                            >
                                <el-select
                                    clearable
                                    v-model="form.type"
                                    class="w-full"
                                    :disabled="!mainCustomer"
                                >
                                    <el-option
                                        v-for="item in types"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value"
                                    />
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <div>
                        <el-form-item
                            prop="notifications"
                            :label="$t('reports.modal.form.notifications')"
                        >
                            <el-select
                                class="w-full"
                                v-model="form.notifications"
                                multiple
                                filterable
                                allow-create
                                default-first-option
                                :disabled="!mainCustomer"
                            >
                                <el-option
                                    v-for="item in notifications"
                                    :key="item"
                                    :label="item"
                                    :value="item"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </div>
                    <div class="text-right py-2">
                        <el-popconfirm
                            v-if="isAssignedToMe()"
                            :confirm-button-text="
                                $t('reports.modal.form.registerDialog.confirm')
                            "
                            :cancel-button-text="
                                $t('reports.modal.form.registerDialog.cancel')
                            "
                            icon="el-icon-info"
                            icon-color="red"
                            :title="
                                $t('reports.modal.form.registerDialog.title')
                            "
                            @confirm="saveReport(1)"
                        >
                            <el-button
                                type="info"
                                slot="reference"
                                :loading="loading"
                            >
                                <i class="fas fa-save"></i>
                                {{ $t("reports.modal.form.registerReport") }}
                            </el-button>
                        </el-popconfirm>
                    </div>
                </div>
                <div v-show="activeStep === 1">
                    <div class="flex p-2">
                        <div class="flex-1">
                            <i
                                class="fas fa-info-circle text-blue-400 mr-2"
                            ></i>
                            <span class="text-2xl">{{
                                $t("reports.modal.activity.title")
                            }}</span>

                            <span class="font-bold px-4">
                                {{ $t("reports.modal.activity.totHours") }}:
                                <span class="ml-2">{{ totHours }}</span>
                            </span>
                        </div>
                        <div class="flex-initial">
                            <el-button
                                v-if="isAssignedToMe()"
                                type="primary"
                                @click="addScopeBlock"
                                class="mr-2"
                            >
                                <i class="fas fa-plus mr-2"></i>
                                {{ $t("reports.modal.form.addScopeBlock") }}
                            </el-button>
                            <el-popconfirm
                                v-if="isAssignedToMe()"
                                class="ml-2"
                                :confirm-button-text="
                                    $t(
                                        'reports.modal.form.registerDialog.confirm'
                                    )
                                "
                                :cancel-button-text="
                                    $t(
                                        'reports.modal.form.registerDialog.cancel'
                                    )
                                "
                                icon="el-icon-info"
                                icon-color="red"
                                :title="
                                    $t(
                                        'reports.modal.form.registerDialog.title'
                                    )
                                "
                                @confirm="saveReport(2)"
                            >
                                <el-button
                                    type="info"
                                    slot="reference"
                                    :loading="loading"
                                >
                                    <i class="fas fa-save"></i>
                                    {{
                                        $t("reports.modal.form.registerReport")
                                    }}
                                </el-button>
                            </el-popconfirm>
                        </div>
                    </div>
                    <div
                        class="grid grid-cols-4 gap-4 p-4"
                        :key="scopeBlockList"
                    >
                        <div v-for="(block, i) in form.scopeBlock" :key="i">
                            <ModalScopeBlock
                                :value="form.scopeBlock[i]"
                                :isCompleted="form.isCompleted"
                                @save="updateScopeBlock"
                                @remove="removeScopeBlock"
                                @unlock="unlockScopeBlock"
                            />
                        </div>
                    </div>
                </div>
                <div v-show="activeStep === 2">
                    <ModalMaterialBlock
                        :value="form.materials"
                        :isCompleted="form.isCompleted"
                        @loadMaterials="loadMaterials"
                        @saveMaterials="saveReport(3)"
                        :loading="loading"
                    />
                </div>
            </div>
        </el-form>
    </el-dialog>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import randomstring from "randomstring";
import ModalScopeBlock from "./ModalScopeBlock.vue";
import ModalMaterialBlock from "./ModalMaterialBlock.vue";
import { getters, mutations, actions } from "@/store";

const baseScopeBlock = {
    index: null,
    scope: null,
    from: "",
    to: "",
    description: "",
};

export default {
    name: "ReportMainModal",

    components: {
        ModalScopeBlock,
        ModalMaterialBlock,
    },

    computed: {
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket,
        currentReport: getters.reports.currentReport,
        materialCodes: getters.reports.materialCodes,
        scopes: getters.reports.scopes,
        mainCustomer_id: getters.user.mainCustomer,
        mainCustomer: () => {
            const x = getters.user
                .data()
                .parentClients.filter(
                    (c) =>
                        parseInt(c.id_parent) ===
                        parseInt(getters.user.mainCustomer())
                );

            return x[0].name.replace(/\s/g, "").toLowerCase();
        },
        modalOpen: {
            get() {
                return getters.reports.modalOpen();
            },
            set(value) {
                mutations.reports.setModalOpen(value);
            },
        },
        issetReport() {
            const r = _.get(this.currentReport, "data.reportId", false);
            return r ? true : false;
        },
        canSentToApprove() {
            const r = _.get(this.currentReport, "report.interventions", []);
            console.log("<---------", r);
            return r.length > 0;
        },
    },

    data() {
        return {
            activeStep: 0,
            form: {},
            scopeBlockList: 0,
            rules: {
                customer: [
                    {
                        required: true,
                        message: this.$t("reports.modal.rules.required"),
                        trigger: "blur",
                    },
                ],
            },
            mainCustomers: [],
            listLocations: [],
            traveltimes: [],
            types: [],
            predefinedEmails: [],
            notifications: [],
            totHours: 0,
            loading: false,
        };
    },

    async created() {
        console.log("<< USER >>", getters.user.data());

        await actions.reports.getReport(this.mainCustomer, {
            username: this.user.email,
            ticket_id: this.currentTicket.id,
        });

        this.form = {
            customer: this.currentTicket.customer.name,
            date: new Date(),
            location: null,
            traveltime: 6,
            type: null,
            scopeBlock: [],
            isCompleted: false,
            notifications: [],
            materials: [],
        };

        const cr = this.currentReport;
        console.log("------cr---->", cr, this.issetReport);

        if (cr) {
            this.form.date = cr.report.inserted_at;
            this.form.location = cr.report.report_venue_id;
            this.form.type = cr.report.reportType_id;

            if (cr.report.interventions && cr.report.interventions.length > 0) {
                this.form.scopeBlock = cr.report.interventions.map((el) => {
                    return {
                        description: el.description,
                        scope: el.report_scope_id,
                        from: el.start_time,
                        to: el.end_time,
                        isCompleted: true,
                        index: el.index,
                    };
                });

                this.calculateTotHours();
            }

            if (cr.report.materials && cr.report.materials.length > 0) {
                this.form.materials = cr.report.materials.map((el) => {
                    return {
                        material: el.material,
                        quantity: el.qty_material,
                        price: el.price,
                        code: el.code_material,
                        serial: el.serial,
                    };
                });
            }
        }

        if (this.form.notifications.length === 0) {
            this.form.notifications = this.predefinedEmails;
        }

        //this.addScopeBlock();
        await this.populateSelects();

        const emails = await actions.reports.getCustomerEmails({
            customerId: this.mainCustomer,
            arca_code: this.currentTicket.customer.arca_code,
            customer_name: this.currentTicket.customer.name,
        });

        this.predefinedEmails.push(emails.customer_email);

        // console.log("<< FORM >>", this.form);
    },

    methods: {
        isAssignedToMe() {
            return this.currentTicket.assigned_to === this.user.id;
        },

        prev() {
            if (this.activeStep-- <= 0) this.activeStep = 0;
        },

        next() {
            if (this.activeStep++ >= 2) this.activeStep = 0;
        },

        addScopeBlock() {
            const newScopeBlock = {
                ...baseScopeBlock,
                index: randomstring.generate(8),
            };
            this.form.scopeBlock.push(newScopeBlock);
        },

        calculateTotHours() {
            // conteggio delle ore totali
            const minutes = this.form.scopeBlock.reduce((acc, curr) => {
                return (
                    acc +
                    moment(curr.to, "HH:mm").diff(
                        moment(curr.from, "HH:mm"),
                        "minutes"
                    )
                );
            }, 0);

            this.totHours = moment.duration(minutes, "minutes").asHours();
        },

        updateScopeBlock(value) {
            value.isCompleted = true;

            this.form.scopeBlock = this.form.scopeBlock.map((block) => {
                if (block.index === value.index) {
                    return {
                        ...block,
                        ...value,
                    };
                }

                return block;
            });

            this.calculateTotHours();
        },

        removeScopeBlock(index) {
            console.log(">>>INDEX>>>>", index);

            this.form.scopeBlock = this.form.scopeBlock.filter(
                (el) => el.index !== index
            );

            console.log(">>>>>>SCOPELIST>>>>", this.form.scopeBlock);

            this.scopeBlockList++;
        },

        unlockScopeBlock(index) {
            console.log(">>>INDEX>>>>", index);

            this.form.scopeBlock = this.form.scopeBlock.map((el) => {
                if (el.index === index) {
                    return {
                        ...el,
                        isCompleted: false,
                    };
                }

                return el;
            });

            this.scopeBlockList++;
        },

        async saveReport(step) {
            try {
                this.loading = true;

                const check = this.form.scopeBlock.every(
                    (el) => el.isCompleted
                );
                if (check) {
                    this.form.isCompleted = true;
                    this.scopeBlockList++;
                }

                console.log(">>>> FORM >>>>>", this.form);
                console.log(">>>> TICKET >>>>>", this.currentTicket);

                if (step === 1) {
                    const step1 = {
                        step: 1,
                        username: this.user.email,
                        arca_code_customer:
                            this.currentTicket.customer.arca_code,
                        inserted_at: moment().format("YYYY-MM-DD"),
                        report_created_at: moment().format("YYYY-MM-DD"),
                        reportType_id: this.form.type,
                        clientParent_id: this.mainCustomer_id,
                        report_venue_id: this.form.location,
                        ticket_id: this.currentTicket.id,
                        customer_name: this.currentTicket.customer.name,
                    };

                    console.log("<< STEP 1 >>", step1);

                    const r = await actions.reports.saveStep1(
                        this.mainCustomer,
                        step1
                    );

                    mutations.reports.setCurrentReport({ ...r, report: step1 });

                    console.log("<< REPORT >>", this.currentReport);
                }

                if (step === 2) {
                    console.log(
                        "<<<<< CURRENT REPORT >>>>>>",
                        this.currentReport
                    );

                    const step2 = {
                        step: 2,
                        username: this.user.email,
                        report_code: this.currentReport.data.idExtReport,
                        inserted_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                        interventions: this.form.scopeBlock.map((el) => {
                            const typeDescr = this.types.filter(
                                (x) => x.value === this.form.type
                            );

                            const scopeDescr = this.scopes.filter(
                                (x) => x.value === el.scope
                            );

                            return {
                                index: el.index,
                                report_type_id: this.form.type,
                                description: el.description,
                                report_scope_id: el.scope,
                                start_time: el.from,
                                end_time: el.to,
                                report_type_description: typeDescr[0].label,
                                report_scope_desciption: scopeDescr[0].label,
                            };
                        }),
                    };

                    console.log("<< STEP 2 >>", step2);

                    await actions.reports.saveStep2(this.mainCustomer, step2);

                    let rp = { ...this.currentReport };
                    rp.report.interventions = step2.interventions;

                    mutations.reports.setCurrentReport(rp);

                    console.log("<< REPORT >>", this.currentReport);
                }

                if (step === 3) {
                    const step3 = {
                        step: 3,
                        username: this.user.email,
                        report_code: this.currentReport.data.idExtReport,
                        inserted_at: moment().format("YYYY-MM-DD HH:mm:ss"),
                        materials: this.form.materials.map((el) => {
                            const artId = this.materialCodes.filter(
                                (x) => x.value === el.code
                            );

                            return {
                                material: el.material,
                                qty_material: el.quantity,
                                price: el.price,
                                code_material: el.code,
                                serial: el.serial,
                                article_id: artId[0]
                                    ? artId[0].id_articoli
                                    : null,
                                invoice: false,
                            };
                        }),
                    };

                    console.log("<< STEP 3 >>", step3);

                    await actions.reports.saveStep2(this.mainCustomer, step3);

                    let rp = { ...this.currentReport };
                    rp.report.materials = step3.materials;

                    mutations.reports.setCurrentReport(rp);

                    console.log("<< REPORT >>", this.currentReport);
                }

                this.$notify.success(this.$t("reports.modal.form.success"));
                this.loading = false;
            } catch (error) {
                console.log(error);
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        saveToApprove() {
            console.log("TODO");
        },

        loadMaterials(materials) {
            this.form.materials = materials;
        },

        //-------------------------------------------------

        async setVenue() {
            try {
                const r = await actions.reports.getVenue(this.mainCustomer);

                if (r) {
                    this.listLocations = r
                        .sort((a, b) => {
                            return a.venue_ordine - b.venue_ordine;
                        })
                        .map((el) => {
                            return {
                                value: el.venue_id,
                                label: el.venue_name,
                            };
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },

        async setTransferTime() {
            try {
                const r = await actions.reports.getTransferTime(
                    this.mainCustomer
                );

                if (r) {
                    this.traveltimes = r
                        .sort((a, b) => {
                            return a.transfer_id - b.transfer_id;
                        })
                        .map((el) => {
                            return {
                                value: el.transfer_id,
                                label: el.time,
                            };
                        });
                }
            } catch (error) {
                console.log(error);
            }
        },

        async setType() {
            try {
                const r = await actions.reports.getType({
                    customerName: this.mainCustomer,
                    customerId: this.currentTicket.customer.id,
                });

                if (r && r.length > 0) {
                    this.types = r.map((el) => {
                        return {
                            value: el.type_id,
                            label: `${el.type_name}`,
                        };
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },

        async setScopes() {
            try {
                const r = await actions.reports.getScopes(this.mainCustomer);

                if (r) {
                    const scopes = r
                        .sort((a, b) => {
                            return a.scope_ordine - b.scope_ordine;
                        })
                        .map((el) => {
                            return {
                                value: el.scope_id,
                                label: el.scope_name,
                            };
                        });

                    mutations.reports.setScopes(scopes);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async setMaterialCodes() {
            try {
                const r = await actions.reports.getMaterialCodes(
                    this.mainCustomer
                );

                if (r) {
                    const materials = r
                        .sort((a, b) => {
                            return a.id_articoli - b.id_articoli;
                        })
                        .map((el) => {
                            return {
                                value: el.codice_arca,
                                label: el.descrizione,
                                id_articoli: el.id_articoli,
                            };
                        });

                    mutations.reports.setMaterialCodes(materials);
                }
            } catch (error) {
                console.log(error);
            }
        },

        async populateSelects() {
            console.log("<<MAIN CUSTOMER>>", this.mainCustomer);

            if (this.mainCustomer && this.mainCustomer.trim() !== "") {
                Promise.all([
                    this.setVenue(),
                    this.setTransferTime(),
                    this.setType(),
                    this.setScopes(),
                    this.setMaterialCodes(),
                ]).then(() => {
                    console.log("--campi popolati--");
                    // qui andrò a impostare le select in base ai valori raccolti se presenti
                });
            }
        },
    },
};
</script>
