<template>
    <div>
        <div class="py-2 text-right" v-if="isAssignedToMe()">
            <el-button type="primary" @click="addMaterial">
                <i class="el-icon-plus"></i>
                <span>{{ $t("reports.modal.form.material.add") }}</span>
            </el-button>
            <el-popconfirm
                class="ml-2"
                :confirm-button-text="
                    $t('reports.modal.form.registerDialog.confirm')
                "
                :cancel-button-text="
                    $t('reports.modal.form.registerDialog.cancel')
                "
                icon="el-icon-info"
                icon-color="red"
                :title="$t('reports.modal.form.registerDialog.title')"
                @confirm="save"
            >
                <el-button type="info" slot="reference" :loading="loading">
                    <i class="fas fa-save"></i>
                    {{ $t("reports.modal.form.registerReport") }}
                </el-button>
            </el-popconfirm>
        </div>

        <el-table
            :data="materials"
            border
            style="width: 100%"
            :key="tableMaterialsKey"
        >
            <el-table-column
                v-for="column in columns"
                :key="column.key"
                :prop="column.key"
                :label="column.label"
                :width="column.width"
            >
                <template slot-scope="scope">
                    <span
                        v-html="
                            column.render
                                ? column.render(scope.row)
                                : scope.row[column.key]
                        "
                    ></span>
                </template>
            </el-table-column>
            <el-table-column
                key="actions"
                :label="$t('reports.modal.form.material.actions')"
                align="center"
            >
                <template slot-scope="scope">
                    <el-button
                        v-if="isAssignedToMe()"
                        type="text"
                        size="mini"
                        @click="deleteMaterial(scope.row.code)"
                        class="text-red-500"
                    >
                        <i class="fas fa-trash"></i>
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-dialog
            :title="$t('reports.modal.form.material.register')"
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
        >
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item
                    :label="$t('reports.modal.form.material.code')"
                    prop="code"
                >
                    <el-select
                        clearable
                        v-model="form.code"
                        class="w-full"
                        filterable
                    >
                        <el-option
                            v-for="item in materialCodes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item
                    :label="$t('reports.modal.form.material.serial')"
                    prop="serial"
                >
                    <el-input v-model="form.serial"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('reports.modal.form.material.material')"
                    prop="material"
                >
                    <el-input v-model="form.material"></el-input>
                </el-form-item>
                <el-form-item
                    :label="$t('reports.modal.form.material.quantity')"
                    prop="quantity"
                >
                    <el-input-number v-model="form.quantity"></el-input-number>
                </el-form-item>
                <el-form-item
                    :label="$t('reports.modal.form.material.price')"
                    prop="price"
                >
                    <el-input-number v-model="form.price"></el-input-number>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">{{
                    $t("reports.modal.form.material.cancel")
                }}</el-button>
                <el-button
                    type="primary"
                    @click="registerMaterial"
                    :loading="loadingMat"
                    >{{ $t("reports.modal.form.material.save") }}</el-button
                >
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "ModalMaterialBlock",

    props: ["value", "loading"],

    computed: {
        materialCodes: getters.reports.materialCodes,
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket,
    },

    data() {
        return {
            tableMaterialsKey: 0,
            columns: [],
            materials: [],
            loadingMat: false,
            dialogVisible: false,
            form: {
                name: "",
                description: "",
                unit: "",
                quantity: 0,
                price: 0,
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: this.$t(
                            "reports.modal.form.material.required"
                        ),
                    },
                ],
                description: [
                    {
                        required: true,
                        message: this.$t(
                            "reports.modal.form.material.required"
                        ),
                    },
                ],
                unit: [
                    {
                        required: true,
                        message: this.$t(
                            "reports.modal.form.material.required"
                        ),
                    },
                ],
                quantity: [
                    {
                        required: true,
                        message: this.$t(
                            "reports.modal.form.material.required"
                        ),
                    },
                ],
                price: [
                    {
                        required: true,
                        message: this.$t(
                            "reports.modal.form.material.required"
                        ),
                    },
                ],
            },
        };
    },

    created() {
        this.columns = [
            {
                label: this.$t("reports.modal.form.material.code"),
                key: "code",
                render: (data) => {
                    console.log(">>>>>>>>", data);
                    return `${data.code}`;
                },
            },
            {
                label: this.$t("reports.modal.form.material.serial"),
                key: "serial",
                render: (data) => {
                    return `${data.serial}`;
                },
            },
            {
                label: this.$t("reports.modal.form.material.material"),
                key: "material",
                render: (data) => {
                    return `${data.material}`;
                },
            },
            {
                label: this.$t("reports.modal.form.material.quantity"),
                key: "quantity",
                render: (data) => {
                    return `${data.quantity}`;
                },
            },
            {
                label: this.$t("reports.modal.form.material.price"),
                key: "price",
                render: (data) => {
                    return `${data.price}`;
                },
            },
        ];

        if (this.value) {
            console.log("----------------------", this.value);
            this.materials = this.$copyStatic(this.value);
        }
    },

    methods: {
        isAssignedToMe() {
            console.log("<< TICKET >>", this.currentTicket, this.user.id);
            return this.currentTicket.assigned_to === this.user.id;
        },

        addMaterial() {
            this.dialogVisible = true;
        },

        registerMaterial() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.loadingMat = true;
                    this.materials.push(this.form);
                    this.dialogVisible = false;
                    this.loadingMat = false;
                    this.tableMaterialsKey++;
                    this.$emit("loadMaterials", this.materials);
                } else {
                    this.$message.error(
                        this.$t("reports.modal.form.material.error")
                    );
                }
            });
        },

        deleteMaterial(code) {
            this.materials = this.materials.filter((material) => {
                return material.code !== code;
            });
            this.$emit("loadMaterials", this.materials);
        },

        save() {
            this.$emit("saveMaterials", this.materials);
        },
    },
};
</script>
