var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"scopeBlock"},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1"},[_vm._v(" "+_vm._s(_vm.$t("reports.modal.form.scopeBlockName", { n: _vm.value.index }))+" ")]),_c('div',{staticClass:"flex-initial"},[(!_vm.value.isCompleted)?_c('div',[_c('el-button',{attrs:{"type":"danger"},on:{"click":_vm.remove}},[_c('i',{staticClass:"fas fa-trash"})]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.save}},[_c('i',{staticClass:"fas fa-save"})])],1):_c('div',[_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.unlock}},[_c('i',{staticClass:"fas fa-lock"})])],1)])]),_c('el-form-item',{attrs:{"prop":"scope","label":_vm.$t('reports.modal.form.scopeBlock.scope')}},[_c('el-select',{staticClass:"w-full",attrs:{"clearable":"","disabled":_vm.value.isCompleted},model:{value:(_vm.localValue.scope),callback:function ($$v) {_vm.$set(_vm.localValue, "scope", $$v)},expression:"localValue.scope"}},_vm._l((_vm.scopes),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1 mr-2"},[_c('el-form-item',{attrs:{"prop":"from","label":_vm.$t('reports.modal.form.scopeBlock.from')}},[_c('el-time-select',{attrs:{"disabled":_vm.value.isCompleted,"picker-options":{
                        start: '08:00',
                        step: '00:15',
                        end: '19:30',
                    }},model:{value:(_vm.localValue.from),callback:function ($$v) {_vm.$set(_vm.localValue, "from", $$v)},expression:"localValue.from"}})],1)],1),_c('div',{staticClass:"flex-1"},[_c('el-form-item',{attrs:{"prop":"from","label":_vm.$t('reports.modal.form.scopeBlock.to')}},[_c('el-time-select',{attrs:{"disabled":_vm.value.isCompleted,"picker-options":{
                        start: '08:00',
                        step: '00:15',
                        end: '19:30',
                    }},model:{value:(_vm.localValue.to),callback:function ($$v) {_vm.$set(_vm.localValue, "to", $$v)},expression:"localValue.to"}})],1)],1)]),_c('el-form-item',{attrs:{"prop":"scope","label":_vm.$t('reports.modal.form.scopeBlock.description')}},[_c('el-input',{attrs:{"disabled":_vm.value.isCompleted,"type":"textarea","autosize":{ minRows: 4, maxRows: 8 },"maxlength":"255","show-word-limit":""},model:{value:(_vm.localValue.description),callback:function ($$v) {_vm.$set(_vm.localValue, "description", $$v)},expression:"localValue.description"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }