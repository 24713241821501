export default {
    reports: {
        modal: {
            openBtn: {
                text: "report",
            },
            title: "Register the report associated to this ticket",
            form: {
                steps: {
                    step1: {
                        title: "General information",
                        description: "",
                    },
                    step2: {
                        title: "Activities performed",
                        description: "",
                    },
                    step3: {
                        title: "Materials used",
                        description: "optional",
                    },
                },
                mainCustomer: "Main customer",
                customer: "Customer",
                date: "Date",
                location: "Place of the intervention",
                traveltime: "Travel time",
                type: "Type",
                scopeBlock: {
                    scope: "Activity type",
                    from: "Start time",
                    to: "End time",
                    description: "Description",
                },
                addScopeBlock: "Add activity",
                registerReport: "Register the report",
                saveToApprove: "Save for approval",
                registerDialog: {
                    confirm: "Confirm",
                    cancel: "Cancel",
                    title: "Do you want to register this report?",
                },
                approveDialog: {
                    confirm: "Confirm",
                    cancel: "Cancel",
                    title: "Do you want send report for approval? Once registered you can't modify it.",
                },
                scopeBlockName: "Activity #{n}",
                notifications:
                    "Email for notifications (write and press enter to add)",
                material: {
                    add: "add material",
                    register: "Register material",
                    required: "This field is required",
                    save: "Save",
                    cancel: "Cancel",
                    error: "Error in the material registration",
                    code: "Code",
                    serial: "Serial",
                    material: "Material",
                    quantity: "Q.ty",
                    price: "Price",
                    actions: "Actions",
                },
                save: {
                    success: "Operation success",
                },
            },
            rules: {
                required: "This filed is required",
            },
            activity: {
                title: "Performed activities",
                totHours: "Total hours",
            },
        },

        main: {
            t1: "Registered reports",
            t2: "Verify report",
            filters: {
                title: "Filters",
                customers: "Customers",
                location: "Place of intervention",
                users: "Tech",
                scope: "Scope",
                type: "Type",
                state: "State",
                toapprove: "to approve",
                approved: "approved",
                forTime: "Time range",
                receivedFrom: "Start date",
                receivedTo: "End date",
                receivedSeparator: "to",
                setFilters: "set filters",
                delFilters: "delete filters",
            },
        },

        table: {
            create_date: "Registration date",
            customer: "Customer",
            venue: "Place of intervention",
            type: "Type",
            scope: "Scope",
            approval_sent_at: "Submit for approval",
            materials: "Materials",
            actions: "Actions",
            status: {
                title: "Status",
                report: "recorded report",
                toapprove: "in approval",
                approved: "approved",
            },
            menu: {
                confirmDelete: "Confirm report delete?",
                delOk: "Yes",
                delKo: "No",
            },
        },
    },
};
