<template>
    <div>
        <div class="flex pb-4">
            <span class="mr-2">{{
                $t("administration.blacklist.filter")
            }}</span>
            <div class="flex-1 mr-2">
                <el-input
                    v-model="searchBlack"
                    clearable
                    :placeholder="$t('administration.blacklist.filterLabel')"
                />
            </div>
            <div class="flex-1">
                <el-button
                    icon="fas fa-search"
                    type="primary"
                    @click="doSearch"
                >
                </el-button>
            </div>
            <div class="flex-initial">
                <AdminBlacklistNew />
            </div>
        </div>

        <div class="bg-white p-4">
            <div v-if="blacklistEntries.length > 0">
                <el-tag
                    :key="tag.id"
                    v-for="tag in blacklistEntries"
                    closable
                    :disable-transitions="false"
                    @close="deleteEntry(tag.id)"
                    class="font-bold mr-2"
                >
                    {{ tag.email }}
                </el-tag>
            </div>
            <div v-else>
                <span>{{ $t("administration.blacklist.noEntries") }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters, actions } from "@/store";
import AdminBlacklistNew from "@/entities/administration/AdminBlacklistNew.vue";

export default {
    name: "AdminBlacklistMain",

    components: {
        AdminBlacklistNew,
    },

    computed: {
        blacklist: getters.administration.blacklist,
    },

    watch: {
        blacklist: function (val) {
            this.setBlacklistEntries(val);
        },
    },

    data() {
        return {
            blacklistEntries: [],
            searchBlack: "",
            q: {
                limit: 100,
            },
        };
    },

    async mounted() {
        await actions.administration.getBlacklist({ query: this.q });

        this.setBlacklistEntries(this.blacklist);

        EventBus.$on("updateBlacklist", async () => {
            await actions.administration.getBlacklist({ query: this.q });
        });
    },

    beforeDestroy() {
        EventBus.$off("updateBlacklist");
    },

    methods: {
        setBlacklistEntries(val) {
            const v = this.$copyStatic(val);

            this.blacklistEntries = v.map((el) => {
                return el;
            });
        },

        async deleteEntry(id) {
            try {
                this.$confirm(
                    this.$t("administration.blacklist.prompt.confirmDelete"),
                    "Warning",
                    {
                        confirmButtonText: this.$t(
                            "administration.blacklist.prompt.ok"
                        ),
                        ancelButtonText: this.$t(
                            "administration.blacklist.prompt.ko"
                        ),
                        type: "warning",
                    }
                )
                    .then(async () => {
                        await actions.administration.deleteBlacklistEntry(id);

                        EventBus.$emit("updateBlacklist", true);

                        this.$notify.success(
                            this.$t(
                                "administration.blacklist.prompt.operationSuccess"
                            )
                        );
                    })
                    .catch(() => {
                        this.$notify.info(
                            this.$t(
                                "administration.blacklist.prompt.operationAborted"
                            )
                        );
                    });
            } catch (error) {
                console.log(error);
                this.loadingSaveConfig = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async doSearch() {
            if (
                this.searchBlack.trim() !== "" &&
                this.searchBlack.trim().length > 2
            ) {
                await actions.administration.getBlacklist({
                    query: {
                        ...this.q,
                        term: this.searchBlack.trim(),
                    },
                });
            } else {
                await actions.administration.getBlacklist({
                    query: this.q,
                });
            }
        },
    },
};
</script>
