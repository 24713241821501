<template>
    <span class="notification">{{ assignedToMeCounter }}</span>
</template>

<script>
import { getters } from "@/store";

export default {
    name: "TicketPersonalCounter",

    computed: {
        user: getters.user.data,
        assignedToMeCounter: getters.ticket.assignedToMeCounter,
    },
};
</script>
