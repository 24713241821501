<template>
    <div>
        <div class="wrapper wrapper-full-page">
            <div
                class="full-page"
                :data-color="backgroundColor"
                :class="pageClass"
            >
                <div class="content">
                    <div class="container">
                        <slot></slot>
                    </div>
                </div>

                <footer class="footer footer-transparent text-center">
                    <span>&copy; {{ copywrite }}</span>
                </footer>

                <div
                    class="full-page-background"
                    :style="`background-image: url(${this.$baseUrl}static/img/INFOTECH-net-colori-esec.png);`"
                ></div>
            </div>
        </div>
    </div>
</template>
<script>
import { getters } from "@/store";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";

export default {
    components: {
        [CollapseTransition.name]: CollapseTransition,
    },
    props: {
        pageClass: {
            type: String,
            default: "login-page",
        },
        backgroundColor: {
            type: String,
            default: "black",
        },
    },
    computed: {
        title: getters.title,
        copywrite: getters.copywrite,
    },
    data() {
        return {
            showMenu: false,
        };
    },
    methods: {
        toggleNavbar() {
            document.body.classList.toggle("nav-open");
        },
        closeMenu() {
            document.body.classList.remove("nav-open");
            document.body.classList.remove("off-canvas-sidebar");
        },
    },
    beforeDestroy() {
        this.closeMenu();
    },
};
</script>
<style>
.navbar-nav .nav-item p {
    line-height: inherit;
    margin-left: 5px;
}
.navbar-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
}
.wrapper-full-page .navbar-toggler,
.wrapper-full-page .navbar-collapse .navbar-nav {
    margin-right: 30px;
}
.navbar-collapse .navbar-nav .nav-link {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>
