export default {
    knowledge: {
        newFaq: 'add a new FAQ',

        filter: {
            title: 'Search a term into FAQ'
        },

        faq: {
            q: 'Question',
            r: 'Response',
            lang: 'Language',
            save: 'save',
            cancel: 'cancel',
            operationSuccess: 'Operation completed',
            operationAborted: 'Operation canceled'
        },

        modal: {
            titleNew: 'New FAQ',
            titleMod: 'Modify FAQ'
        },

        prompt: {
            ok: 'save',
            ko: 'cancel',
            del: 'delete',
            sel: 'select',
            operationSuccess: 'Operation completed',
            operationAborted: 'Operation canceled',
            confirmSave: 'Confirm data?',
            confirmDelete: 'Confirm FAQ deletion?'
        },

        errors: {
            faq_question: 'Question is mandatory',
            faq_response: 'Response is mandatory'
        }
    }
};
