<template>
    <div class="">
        <el-button
            type="success"
            @click="addCustomer"
            v-if="$iCan(constants.permissions.administration.create_customer)"
        >
            <i class="fas fa-plus mr-2"></i>
            {{ $t("administration.customers.new") }}
        </el-button>

        <el-dialog
            :title="$t('administration.customers.modal.titleNew')"
            :visible.sync="dialogVisible"
            :before-close="exit"
            width="50%"
        >
            <AdminCustomerForm></AdminCustomerForm>
        </el-dialog>
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { mutations } from "@/store";
import constants from "@/store/constants";
import AdminCustomerForm from "@/entities/administration/AdminCustomerForm.vue";

export default {
    name: "AdminCustomersNew",

    components: {
        AdminCustomerForm,
    },

    data() {
        return {
            dialogVisible: false,
            constants,
        };
    },

    mounted() {
        EventBus.$on("exitAdminCustomerForm", () => this.exit());
    },

    beforeDestroy() {
        EventBus.$off("exitAdminCustomerForm");
    },

    methods: {
        addCustomer() {
            mutations.administration.setCurrentCustomer({
                name: "",
                description: "",
            });
            this.dialogVisible = true;
        },

        exit() {
            this.dialogVisible = false;
        },
    },
};
</script>
