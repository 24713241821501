<template>
    <div class="flex items-baseline pb-4">
        <div class="flex-1">
            <el-button @click="openFilters">
                <i class="fas fa-search" />
                {{ $t(`tickets.filters`) }}
            </el-button>

            <el-button class="ml-4" @click="resetFilters">
                <i class="fas fa-redo fa-flip-horizontal"></i>
            </el-button>
            <el-popconfirm
                :title="$t('tickets.selection.delete.title')"
                :confirm-button-text="$t('tickets.selection.delete.ok')"
                :cancel-button-text="$t('tickets.selection.delete.ko')"
                @confirm="deleteTickets()"
                v-if="
                    $iCan(constants.permissions.tickets.delete_ticket) &&
                    selectedTickets.length > 0
                "
            >
                <el-button
                    class="ml-4"
                    type="info"
                    slot="reference"
                    icon="fas fa-trash"
                >
                    {{ $t("tickets.selection.delete.deleteSelected") }}
                </el-button>
            </el-popconfirm>
        </div>
        <div class="flex-1">
            <!--  Filtri sui ticket miei o in generale -->
            <el-checkbox-group v-model="ticketType" @change="manageType">
                <el-checkbox label="assigned">{{
                    $t(`menu.vertical.tickets.assigned`)
                }}</el-checkbox>
                <el-checkbox label="todo">{{
                    $t(`menu.vertical.tickets.todo`)
                }}</el-checkbox>
                <el-checkbox label="mine">{{
                    $t(`menu.vertical.tickets.mine`)
                }}</el-checkbox>
            </el-checkbox-group>
            <!--  Fine -->
        </div>
        <div class="flex-initial">
            <el-button type="success" @click="newTicket">
                <i class="fas fa-plus mr-2"></i>
                {{ $t(`tickets.newTicket`) }}
            </el-button>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { getters, mutations, actions } from "@/store";
import { localStore } from "@/store/localStore";
import constants from "@/store/constants";

const defaultCheck = ["assigned", "todo"];

export default {
    name: "TicketsTopMenu",

    computed: {
        user: getters.user.data,
        selectedTickets: getters.ticket.selectedTickets,
        filters: getters.ticket.filters,
    },

    data() {
        return {
            constants,
            ticketType: defaultCheck,
        };
    },

    mounted() {
        const l = localStore.session("ticketType");
        if (l) {
            this.ticketType = l;
        }

        mutations.ticket.setFilters(this.convertAssignments());

        EventBus.$on("resetAllFilters", () => this.resetFilters());
    },

    beforeDestroy() {
        EventBus.$off("resetAllFilters");
    },

    methods: {
        convertAssignments() {
            let cnd = {
                is_assigned: [],
                assigned_to: [],
            };

            if (this.ticketType.indexOf("assigned") > -1) {
                cnd.is_assigned.push(1);
            } else {
                cnd.is_assigned = cnd.is_assigned.filter((el) => el !== 1);
            }

            if (this.ticketType.indexOf("todo") > -1) {
                cnd.is_assigned.push(0);
            } else {
                cnd.is_assigned = cnd.is_assigned.filter((el) => el !== 0);
            }

            if (this.ticketType.indexOf("mine") > -1) {
                cnd.assigned_to.push(this.user.id);
            } else {
                cnd.assigned_to = cnd.assigned_to.filter(
                    (el) => el !== this.user.id
                );
            }

            return cnd;
        },

        manageType(val) {
            console.log("manageType", val);

            if (val[val.length - 1] === "mine") {
                val = ["mine"];
            } else {
                val = val.filter((el) => el !== "mine");
            }

            this.ticketType = val;
            localStore.session("ticketType", val);
            mutations.ticket.setFilters(this.convertAssignments());
        },

        openFilters() {
            EventBus.$emit("toggleTicketFilters", true);
        },

        resetFilters() {
            this.manageType(defaultCheck);
            EventBus.$emit("removeFilterTickets", true);
        },

        newTicket() {
            EventBus.$emit("toggleNewTicket", true);
        },

        async deleteTickets() {
            try {
                await actions.ticket.deleteTicket({
                    tickets_id: this.selectedTickets.map((el) => el.id),
                });
                mutations.ticket.setSelectedTickets([]);
                EventBus.$emit("updateTicketList", true);
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },
    },
};
</script>
