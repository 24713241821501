var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentTicket)?_c('div',[_c('div',{staticClass:"flex items-center ticketTitle py-2 mb-2"},[_c('div',{staticClass:"flex-1"},[_c('i',{staticClass:"fas fa-ticket-alt mr-2 text-blue-400"}),_vm._v(" "+_vm._s(_vm.currentTicket.title)+" ")]),_c('div',{staticClass:"flex-1 text-center"},[_c('i',{staticClass:"fas fa-user-plus mr-2 text-blue-400"}),_vm._v(" "+_vm._s(_vm.currentTicket.assignedTo ? _vm.currentTicket.assignedTo.username : _vm.$t("tickets.sheet.notAssigned"))+" ")]),_c('div',{staticClass:"flex-initial"},[(_vm.isLeandro())?_c('ReportModal'):_vm._e(),(
                    _vm.currentTicket.status_id !== _vm.ticketClosed.id &&
                    (_vm.$iCan(
                        _vm.constants.permissions.tickets
                            .view_configuration_menu_ticket
                    ) ||
                        _vm.$iCan(
                            _vm.constants.permissions.tickets
                                .view_action_menu_ticket
                        ))
                )?_c('el-button',{attrs:{"type":"default"},on:{"click":_vm.handleMenu}},[_c('i',{staticClass:"fas fa-bars"})]):_vm._e()],1)]),_c('div',{staticClass:"text-right"},[(
                _vm.currentTicket.status_id !== _vm.ticketClosed.id &&
                _vm.currentTicket.assignedTo
            )?_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.addComment}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("tickets.editor.addComment"))+" ")]):_vm._e()],1),_c('div',{attrs:{"set":(_vm.att = _vm.getAttachments(_vm.currentTicket.attachments))}},[(_vm.att)?_c('div',{staticClass:"p-2 mb-1 text-sm"},[_c('i',{staticClass:"fas fa-paperclip mr-2"}),_vm._v(_vm._s(_vm.$t("tickets.sheet.attachments"))+": "),_vm._l((_vm.att),function(a){return _c('p',{key:a.filename},[_c('a',{attrs:{"target":"_blank","href":((a.path) + "/" + (a.filename))}},[_vm._v(_vm._s(a.filename))])])})],2):_vm._e()]),_c('el-card',{staticClass:"py-2 my-2"},[_c('div',{staticClass:"text-right pb-4"},[_c('el-button',{attrs:{"type":"link"},on:{"click":_vm.toggleMessage}},[(_vm.expandText)?_c('span',[_c('i',{staticClass:"fas fa-compress mr-2"}),_vm._v(" "+_vm._s(_vm.$t("tickets.sheet.collapseAll"))+" ")]):_c('span',[_c('i',{staticClass:"fas fa-expand mr-2"}),_vm._v(" "+_vm._s(_vm.$t("tickets.sheet.expandAll"))+" ")])])],1),_c('blockquote',{staticClass:"relative p-4 text-xl italic border-l-4 bg-neutral-100 text-neutral-600 border-neutral-500 quote mailText",class:_vm.expandText ? 'fullHeight' : 'halfHeight'},[_c('p',{attrs:{"className":"mb-4"},domProps:{"innerHTML":_vm._s(_vm.formatBody(_vm.currentTicket.body))}})]),(_vm.currentTicket.user)?_c('div',{staticClass:"text-right"},[_c('cite',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm.currentTicket.user.username)+" - "+_vm._s(_vm.formatDate(_vm.currentTicket.created_at, "DD-MM-YYYY HH:mm")))])]):_vm._e()]),_c('div',{staticClass:"flex items-center"},[_vm._m(0),_c('div',{staticClass:"flex-initial"},[(
                    _vm.currentTicket.status_id !== _vm.ticketClosed.id &&
                    _vm.currentTicket.assignedTo
                )?_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.addComment}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" "+_vm._s(_vm.$t("tickets.editor.addComment"))+" ")]):_vm._e()],1)]),(_vm.currentTicket.comments && _vm.currentTicket.comments.length > 0)?_c('div',[_c('div',{staticClass:"p-4"},[_vm._v(" "+_vm._s(_vm.$t("tickets.sheet.order"))+": "),_c('el-radio-group',{model:{value:(_vm.reverse),callback:function ($$v) {_vm.reverse=$$v},expression:"reverse"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v(_vm._s(_vm.$t("tickets.sheet.descending")))]),_c('el-radio',{attrs:{"label":false}},[_vm._v(_vm._s(_vm.$t("tickets.sheet.ascending")))])],1)],1),_c('div',{staticClass:"p-4 overflow-y-auto ticketTimeline"},[_c('el-timeline',{staticClass:"max-h-full",attrs:{"reverse":_vm.reverse}},_vm._l((_vm.currentTicket.comments),function(t){return _c('el-timeline-item',{key:t.id,attrs:{"timestamp":((_vm.formatDate(
                        t.created_at,
                        'DD-MM-YYYY HH:mm'
                    )) + " - " + (t.user.username)),"type":t.user.id === _vm.user.id ? 'primary' : 'info',"placement":"top"}},[_c('el-card',[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1"},[_c('p',{domProps:{"innerHTML":_vm._s(t.body)}}),_c('div',{attrs:{"set":(_vm.att = _vm.getAttachments(
                                            t.attactments
                                        ))}},[(_vm.att)?_c('el-divider'):_vm._e(),(_vm.att)?_c('div',{staticClass:"mt-1 text-sm"},[_c('i',{staticClass:"fas fa-paperclip mr-2"}),_vm._v(_vm._s(_vm.$t("tickets.sheet.attachments"))+": "),_vm._l((_vm.att),function(a){return _c('p',{key:a.filename},[_c('a',{attrs:{"target":"_blank","href":((a.path) + "/" + (a.filename))}},[_vm._v(_vm._s(a.filename))])])})],2):_vm._e()],1)]),(t.user.id === _vm.user.id)?_c('div',{staticClass:"flex-initial"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":_vm.$t('tickets.sheet.editComment'),"placement":"top-start"}},[_c('el-button',{attrs:{"size":"mini","icon":"fas fa-pen"},on:{"click":function($event){return _vm.editComment(t)}}})],1)],1):_vm._e()])])],1)}),1)],1)]):_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t("tickets.sheet.noTimelineData")))])]),_c('MainModal')],1):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-1"},[_c('span',{staticClass:"text-2xl"},[_vm._v("Timeline")])])}]

export { render, staticRenderFns }