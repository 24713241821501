"use strict";

import Vue from "vue";
import apiLib from "@/store/api";
import integrationLib from "@/store/integration";
import { localStore } from "@/store/localStore";
import qs from "query-string";
// import socket from '@/store/socket';

const api = apiLib(localStore);
const integration = integrationLib();

const baseFilters = {
    idExtReport: null,
    customers: [],
    users: [],
    state: null,
    range: [],
};

const state = Vue.observable({
    report: {
        modalOpen: false,
        list: {
            reports: [],
            total: 0,
            page: 1,
            pageSize: 20,
        },
        filters: {},
        currentReport: null,
        parentCustomers: [],
        users: [],
        scopes: [],
        materialCodes: [],
    },
});

export const getters = {
    modalOpen: () => state.report.modalOpen,
    reportsList: () => state.report.list,
    filters: () => state.report.filters,
    currentReport: () => state.report.currentReport,
    parentCustomers: () => state.report.parentCustomers,
    users: () => state.report.users,
    scopes: () => state.report.scopes,
    materialCodes: () => state.report.materialCodes,
};

export const mutations = {
    setModalOpen: (val) => {
        state.report.modalOpen = val;
    },

    setReportList: (val) => {
        state.report.list = val;
    },

    setCurrentReport: (val) => {
        state.report.currentReport = val;
    },

    setFilters: (val) => {
        console.log("<<<<<<<<", val);
        state.report.filters = { ...state.report.filters, ...val };
    },

    unsetFilters: () => {
        state.report.filters = baseFilters;
    },

    setParentCustomers: (val) => {
        state.report.parentCustomers = val;
    },

    setUsers: (val) => {
        state.report.users = val;
    },

    setScopes: (val) => {
        state.report.scopes = val;
    },

    setMaterialCodes: (val) => {
        state.report.materialCodes = val;
    },
};

export const actions = {
    renderData(data) {
        return data.map((el) => {
            el.json_report =
                typeof el.json_report !== "string"
                    ? el.json_report
                    : JSON.parse(el.json_report);
            return el;
        });
    },

    async getReports(url, payload) {
        try {
            console.log(">>PAY", payload);

            const res = await api.post(url, payload);

            console.log("<<<<<<<", this.renderData(res.data.data));

            const r = {
                reports: res.data ? this.renderData(res.data.data) : [],
                total: res.data.total,
                page: res.data.page,
                perPage: parseInt(res.data.perPage),
            };

            return r;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListReportException",
                message: "Cannot list reports",
                status: 400,
            });
        }
    },

    async getReport(customerName, payload) {
        try {
            const res = await api.post(`/report/${customerName}`, payload);

            const r = res.data.data[res.data.data.length - 1];

            if (r) {
                const report = JSON.parse(r.json_report);

                mutations.setCurrentReport({
                    data: {
                        reportId: r.id,
                        idExtReport: r.idExtReport,
                    },
                    report,
                });
            } else {
                mutations.setCurrentReport(null);
            }
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetReportException",
                message: "Cannot get report",
                status: 400,
            });
        }
    },

    async getCustomerEmails({ customerId, customer_name, arca_code }) {
        try {
            const res = await integration.post(`/email/${customerId}`, {
                arca_code: arca_code,
                customer_name: customer_name,
            });

            const r = res.data;

            return r;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetCustomerEmailsException",
                message: "Cannot get customer email",
                status: 400,
            });
        }
    },

    async getParentCustomer({ customerId, customerDescription }) {
        try {
            const res = await integration.post(`/${customerId}`, {
                description: customerDescription,
            });

            const r = res.data;

            return r;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetParentCustomerException",
                message: "Cannot get parent customer",
                status: 400,
            });
        }
    },

    async getVenue(customerName) {
        try {
            const res = await integration.get(`/venue/${customerName}`);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetVenueException",
                message: "Cannot get venue",
                status: 400,
            });
        }
    },

    async getTransferTime(customerName) {
        try {
            const res = await integration.get(`/transfertime/${customerName}`);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetVenueException",
                message: "Cannot get transfer time",
                status: 400,
            });
        }
    },

    async getType({ customerId, customerName }) {
        try {
            const res = await integration.get(
                `/reportType/${customerName}/${customerId}`
            );

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetReportTypeException",
                message: "Cannot get type",
                status: 400,
            });
        }
    },

    async getUsers({ query, payload }) {
        try {
            const q = qs.stringify(query);
            const res = await api.post(
                `/search/users${q !== "" ? "?" + q : ""}`,
                payload,
                {
                    "content-type": "application/json",
                }
            );

            return res.data.data;
        } catch (error) {
            console.log(error);
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "ListUsersError",
                message: "Cannot list users",
                status: 400,
            });
        }
    },

    async getScopes(customerName) {
        try {
            const res = await integration.get(`/scope/${customerName}`);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetScopesException",
                message: "Cannot get scopes",
                status: 400,
            });
        }
    },

    async getMaterialCodes(customerName) {
        try {
            const res = await integration.get(`/articles/${customerName}`);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "GetMaterialCodesException",
                message: "Cannot get material codes",
                status: 400,
            });
        }
    },

    //--------------------------------------------------------------

    async saveStep1(customerName, payload) {
        try {
            const res = await api.post(`/report/add/${customerName}`, payload);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveReportStep1Exception",
                message: "Cannot save step1 report",
                status: 400,
            });
        }
    },

    async saveStep2(customerName, payload) {
        try {
            const res = await api.post(`/report/add/${customerName}`, payload);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveReportStep2Exception",
                message: "Cannot save step2 report",
                status: 400,
            });
        }
    },

    async saveStep3(customerName, payload) {
        try {
            const res = await api.post(`/report/add/${customerName}`, payload);

            return res.data;
        } catch (error) {
            Vue.$log.debug(error);

            return Vue.prototype.$throwError({
                type: "SaveReportStep3Exception",
                message: "Cannot save step3 report",
                status: 400,
            });
        }
    },
};
