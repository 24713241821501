export default {
    administration: {
        domains: {
            new: "insert new domain",
            prompt: {
                title: "New domain",
                text: "New domain name",
                ok: "ok",
                ko: "cancel",
                validationError:
                    "Invalid name, the first letter must be @ character",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm save?",
                confirmDelete: "Confirm domain deletion?",
            },
            modal: {
                title: "Manage actives domains",
            },
        },
        customers: {
            new: "add new customer",
            prompt: {
                title: "New customer",
                text: "New customer name",
                ok: "ok",
                ko: "cancel",
                del: "delete",
                validationError: "Invalid name, use only letters and numbers",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm save?",
                confirmDelete: "Confirm customer deletion?",
            },
            table: {
                search: "Search",
                name: "Name",
                description: "Description",
                address: "Address",
                phone: "Phone",
                email: "Email",
                vat: "VAT",
                email_to_ticket: "Email to ticket",
                arca_code: "ARCA code",
            },
            modal: {
                titleMod: "Modify customer",
                titleNew: "Add customer",
            },
            sheet: {
                name: "Name",
                description: "Description",
                address: "Address",
                phone: "Phone",
                email: "Email",
                vat: "VAT",
            },
            incomplete: "Warning: there are {0} customers without email",
            selectIncomplete: "select customers without email",
        },
        roles: {
            new: "create new role",
            prompt: {
                title: "New role",
                text: "New role name",
                ok: "save",
                ko: "cancel",
                validationError: "Invalid name, only letters and numbers",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm data?",
                confirmDelete: "Confirm role deletion?",
            },
        },
        categories: {
            new: "create new category",
            prompt: {
                title: "New category",
                text: "New category name",
                ok: "save",
                ko: "cancel",
                validationError: "Invalid name, only letters and numbers",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm data?",
                confirmDelete: "Confirm category deletion?",
            },
        },
        users: {
            new: "create new user",
            prompt: {
                title: "New user",
                ok: "save",
                ko: "cancel",
                del: "delete",
                sel: "select",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm data?",
                confirmDelete: "Confirm user deletion?",
            },
            table: {
                search: "Search",
                username: "Username",
                email: "Email",
                updated_at: "Updated at",
                tickets: "Assigned tickets",
                categories: "Sectors",
                no_categories: "no category",
                roles: "Roles",
                no_roles: "no role",
            },
            modal: {
                titleMod: "Modify user",
                titleNew: "Add user",
            },
            sheet: {
                role: "role",
                category: "category",
                customer: "customer",
                search: "find by email:",
            },
        },
        whitelist: {
            new: "add a new domain in whitelist",
            prompt: {
                title: "New Domain",
                text: "Domain name",
                ok: "save",
                ko: "cancel",
                validationError: "Invalid name, only letters and numbers",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm data?",
                confirmDelete: "Confirm domain deletion?",
            },
        },
        blacklist: {
            new: "add a new record in blacklist",
            noEntries: "no record",
            filter: "Filter",
            filterLabel: "search:",
            prompt: {
                title: "New record",
                text: "Record name",
                ok: "save",
                ko: "cancel",
                validationError: "Invalid email",
                operationSuccess: "Operation completed",
                operationAborted: "Operation canceled",
                confirmSave: "Confirm data?",
                confirmDelete: "Confirm recors deletion?",
            },
        },
        permissions: {
            view_ticket_menu: "View Ticket menu",
            view_only_own_ticket: "View ONLY own ticket",
            view_operation_menu_ticket: "View Ticket operation menu",
            view_configuration_menu_ticket: "View Ticket config menu",
            view_action_menu_ticket: "View Ticket action menu",
            update_category_ticket: "Update ticket category",
            update_status_ticket: "Update ticket status",
            update_priority_ticket: "Update ticket priority",
            update_due_date_ticket: "Update ticket due date",
            delete_ticket: "Cancel ticket",
            close_ticket: "Close ticket",
            assign_to_me_ticket: "Get ticket",
            assign_to_operator_ticket: "Assign ticket to operator",
            view_knowledge_menu: "View Knowlodge menu",
            create_faq: "Create FAQ",
            update_faq: "Modify FAQ",
            delete_faq: "Delete FAQ",
            view_report_menu: "View Report menu",
            view_admin_menu: "View Administration menu",
            create_user: "Create users",
            delete_user: "Delete users",
            update_user: "Update users",
            create_category: "Create categories",
            update_category: "Update categories",
            create_role: "Create roles",
            update_role: "Update roles",
            delete_role: "Delete roles",
            create_customer: "Create customers",
            update_customer: "Update customers",
            delete_customer: "Delete customers",
            add_whitelist_domain: "Add whitelist domain",
            delete_whitelist_domain: "remove whitelist domain",
        },

        errors: {
            category: "Category name is mandatory",
            role: "Role name is mandatory",
            user_username: "User name is mandatory",
            user_email: "Wrong email address",
            user_roles: "User must have a role",
            user_categories: "User musta have one or more categories",
            user_customers: "User musta have one or more customers",
        },
    },
};
