<template>
    <div>
        <div class="flex pb-4">
            <span class="mr-2">{{
                $t("administration.users.table.search")
            }}</span>
            <div class="flex-1 mr-2">
                <el-input v-model="search" clearable />
            </div>
            <div class="flex-1">
                <el-button
                    icon="fas fa-search"
                    type="primary"
                    @click="doSearch"
                >
                </el-button>
            </div>
            <div class="flex-initial">
                <AdminUserNew />
            </div>
        </div>

        <data-tables-server
            ref="UsersTable"
            class="datatable"
            :key="usersListKey"
            :loading="loading"
            :table-props="tableProps"
            :data="usersList.users"
            :total="parseInt(usersList.total) || 0"
            :current-page="parseInt(usersList['page']) || 1"
            :page-size="parseInt(usersList['pageSize']) || recordPerPage"
            :filters="filters"
            :pagination-props="{ pageSizes: paginations }"
            @query-change="getData"
        >
            <el-table-column
                sortable="custom"
                :label="$t('administration.users.table.username')"
            >
                <template slot-scope="scope">
                    <div class="flex">
                        <div class="flex-initial pr-2">
                            <avatar :username="scope.row.username" :size="30" />
                        </div>
                        <div class="flex-1">
                            {{ scope.row.username }}
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="email"
                sortable="custom"
                :label="$t('administration.users.table.email')"
            />
            <el-table-column
                sortable="custom"
                :label="$t('administration.users.table.roles')"
            >
                <template slot-scope="scope">
                    <p v-for="role in scope.row.roles" :key="role.name">
                        <span v-if="role">{{ role.name }}</span>
                        <span v-else class="text-gray-400">
                            {{ $t("administration.users.table.no_roles") }}
                        </span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                :label="$t('administration.users.table.categories')"
            >
                <template slot-scope="scope">
                    <p v-for="cat in scope.row.categories" :key="cat.name">
                        <span v-if="cat">{{ cat.name }}</span>
                        <span v-else class="text-gray-400">
                            {{ $t("administration.users.table.no_categories") }}
                        </span>
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                prop="updated_at"
                align="center"
                :label="$t('administration.users.table.updated_at')"
            >
                <template slot-scope="scope">
                    {{ formatDate(scope.row.updated_at) }}
                </template>
            </el-table-column>
            <el-table-column
                align="center"
                :label="$t('administration.users.table.tickets')"
            >
                <template slot-scope="scope">
                    {{ scope.row.tickets.length }}
                </template>
            </el-table-column>
            <el-table-column align="center" width="100">
                <template slot-scope="scope">
                    <el-button
                        size="mini"
                        type="default"
                        icon="fas fa-pen"
                        @click="modUser(scope.row)"
                        v-if="
                            $iCan(
                                constants.permissions.administration.update_user
                            )
                        "
                    />
                </template>
            </el-table-column>
        </data-tables-server>

        <el-dialog
            :title="$t('administration.users.modal.titleMod')"
            :visible.sync="dialogVisible"
            :before-close="exit"
            width="50%"
        >
            <AdminUserForm></AdminUserForm>
        </el-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
import qs from "query-string";
import Avatar from "vue-avatar";
import BaseMixin from "@/mixins/Base.js";
import { EventBus } from "@/store/eventBus";
import constants from "@/store/constants";
import { getters, mutations, actions } from "@/store";
import AdminUserForm from "@/entities/administration/AdminUserForm.vue";
import AdminUserNew from "@/entities/administration/AdminUserNew.vue";

export default {
    name: "AdminUsersMain",

    mixins: [BaseMixin],

    components: {
        Avatar,
        AdminUserNew,
        AdminUserForm,
    },

    computed: {
        recordPerPage: getters.recordPerPage,
        paginations: getters.paginations,
        user: getters.user.data,
        usersList: getters.administration.users,
        roles: getters.user.roles,
        permissions: getters.user.permissions,
        categories: getters.ticket.categories,
    },

    watch: {
        users: function (val) {
            this.setUserList(val);
        },
    },

    data() {
        return {
            constants,
            usersListKey: "init",
            loading: false,
            tableProps: {
                border: true,
                stripe: true,
                defaultSort: {
                    prop: "id",
                    order: "descending",
                },
            },
            filters: [],
            search: "",
            userList: [],
            dialogVisible: false,
        };
    },

    async mounted() {
        this.loading = true;

        await actions.ticket.getCategories();

        this.loading = false;

        EventBus.$on("updateUsers", async () => {
            this.loading = true;
            this.usersListKey = dayjs().format();
            this.loading = false;
        });

        EventBus.$on("exitAdminUserForm", () => this.exit());
    },

    beforeDestroy() {
        EventBus.$off("updateUsers");
        EventBus.$off("exitAdminUserForm");
    },

    methods: {
        async getData(queryInfo) {
            try {
                this.loading = true;

                const customers_id = this.user.customers.map((el) => el.id);

                const qp = {
                    limit: queryInfo.pageSize,
                    page: queryInfo.page,
                    sortBy: queryInfo.sort.prop,
                    sortType:
                        queryInfo.sort.order === "ascending" ? "asc" : "desc",
                };

                let url = `/search/users?${qs.stringify(qp)}`;
                if (this.search.trim() !== "") {
                    url += `&email=${this.search}`;
                }

                await actions.administration.getUsersWithPagination(url, {
                    customers_id,
                });

                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        setUserList(val) {
            const v = this.$copyStatic(val.data);

            if (v && v.length > -1) {
                this.userList = v.map((el) => {
                    return el;
                });
            }
        },

        modUser(user) {
            mutations.administration.setCurrentUser(this.$copyStatic(user));
            this.dialogVisible = true;
        },

        exit() {
            this.dialogVisible = false;
        },

        doSearch() {
            this.loading = true;
            this.usersListKey = dayjs().format();
            this.loading = false;
        },
    },
};
</script>
