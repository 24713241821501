<template>
    <el-dialog
        :title="$t('tickets.editor.comment')"
        :visible.sync="isOpen"
        width="80%"
    >
        <!-- <el-switch
            class="mb-2"
            v-model="form.commentType"
            :inactive-text="$t('tickets.editor.internalComment')"
            :active-text="$t('tickets.editor.externalComment')">
        </el-switch> -->
        <vue-editor
            v-model="form.content"
            :editorToolbar="customToolbar"
            useCustomImageHandler
            @image-added="handleImageAdded"
        />
        <span slot="footer" class="dialog-footer">
            <!-- <el-button v-if="!form.commentType" type="primary" @click="saveComment">{{$t('tickets.editor.save')}}</el-button> -->
            <span>
                <el-popconfirm
                    confirm-button-text='OK'
                    cancel-button-text='NO'
                    icon="el-icon-info"
                    icon-color="red"
                    :title="$t('tickets.editor.confirmComment')"
                    @confirm="saveComment"
                >
                    <el-button class="mr-2" slot="reference" type="primary">{{$t('tickets.editor.save')}}</el-button>
                </el-popconfirm>
            </span>
            <el-button @click="closeComment">{{$t('tickets.editor.cancel')}}</el-button>
        </span>
    </el-dialog>
</template>

<script>
import { EventBus } from '@/store/eventBus';
import { getters, actions } from "@/store";
import { localStore } from '@/store/localStore';
import { VueEditor } from 'vue2-editor';
import apiLib from '@/store/api';
import DOMPurify from 'dompurify';

const api = apiLib(localStore);

export default {
    name: 'TicketEditor',

    components: {
        VueEditor
    },

    data() {
        return {
            isOpen: false,
            form: {
                content: '',
                comment_id: null,
                commentType: false
            },
            customToolbar: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                ['image', 'link', 'code-block'],
                ['clean']
            ]
        }
    },

    computed: {
        user: getters.user.data,
        currentTicket: getters.ticket.currentTicket
    },

    mounted() {
        EventBus.$on('openTicketEditor', val => {
            if (val.type === 'new') {
                this.isOpen = true;
                this.form = {
                    content: '',
                    comment_id: null
                }
            }

            if (val.type === 'mod') {
                this.isOpen = true;
                this.form.comment_id = val.comment.id;
                this.form.content = val.comment.body;
            }
        });
    },

    beforeDestroy() {
        EventBus.$off('openTicketEditor');
    },

    methods: {
        closeComment() {
            this.form.content = '';
            this.isOpen = false;
        },

        async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('owner_id', this.user.id);
            formData.append('owner_type', 'Tickets');

            try {
                const res = await api.post(`/attachments`, formData, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });

                const url = `${process.env.VUE_APP_API_URL.replace('/api', '')}/${res.data.path}/${res.data.filename}`;
                Editor.insertEmbed(cursorLocation, 'image', url);
                resetUploader();
            } catch (error) {
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        },

        async saveComment() {
            try {
                if (this.form.content && this.form.content.trim() !== '') {
                    await actions.ticket.addComment({
                        id: this.currentTicket.id,
                        ticket_id: this.currentTicket.ticket_id,
                        user_id: this.user.id,
                        comment_id: this.form.comment_id,
                        body: DOMPurify.sanitize( this.form.content , {USE_PROFILES: {html: true}} )
                    });

                    this.closeComment();
                }
            } catch (error) {
                this.loading = false;
                this.$log.debug(error);
                this.$notify.error(error.message);
            }
        }
    }
}
</script>
