"use strict";

export default {
    path: "/administration",
    component: () =>
        import(
            /* webpackChunkName: "home" */ "@/pages/Dashboard/Layout/DashboardLayout.vue"
        ),
    children: [
        {
            path: "customers",
            name: "administration_customers",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminCustomersMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "administration.customers",
            },
        },
        {
            path: "roles",
            name: "administration_roles",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminRolesMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "administration.roles",
            },
        },
        {
            path: "categories",
            name: "administration_categories",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminCategoriesMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "administration.categories",
            },
        },
        {
            path: "users",
            name: "administration_users",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminUsersMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "administration.users",
            },
        },
        {
            path: "whitelist",
            name: "administration_whitelist",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminWhitelistTopMenu.vue"
                    ),
                slot2: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminWhitelistMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "administration.whitelist",
            },
        },
        {
            path: "blacklist",
            name: "administration_blacklist",
            components: {
                slot1: () =>
                    import(
                        /* webpackChunkName: "administration" */ "@/entities/administration/AdminBlacklistMain.vue"
                    ),
            },
            meta: {
                requiresAuth: true,
                label: "administration.blacklist",
            },
        },
    ],
    meta: {
        requiresAuth: true,
    },
};
