var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.$t('tickets.editor.newTicket'),"visible":_vm.isOpen,"width":"80%"},on:{"update:visible":function($event){_vm.isOpen=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"size":"medium"}},[_c('div',{staticClass:"flex"},[_c('div',{staticClass:"flex-1"},[(_vm.listCustomers.length > 0)?_c('div',{staticClass:"p-2"},[_c('el-form-item',{attrs:{"prop":"customer_id","label":_vm.$t(
                                'tickets.drawer.actions.sections.config.customer'
                            )}},[_c('el-select',{staticClass:"w-full",attrs:{"filterable":"","clearable":"","placeholder":_vm.$t(
                                    'tickets.drawer.actions.sections.placeholder1'
                                )},model:{value:(_vm.form.customer_id),callback:function ($$v) {_vm.$set(_vm.form, "customer_id", $$v)},expression:"form.customer_id"}},_vm._l((_vm.listCustomers),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1):_vm._e(),_c('div',{staticClass:"p-2"},[_c('el-form-item',{attrs:{"prop":"category_id","label":_vm.$t(
                                'tickets.drawer.actions.sections.config.changeCategory'
                            )}},[_c('el-select',{staticClass:"w-full",attrs:{"filterable":"","remote":"","clearable":"","reserve-keyword":"","remote-method":_vm.srcCategories,"loading":_vm.loadingCategories,"placeholder":_vm.$t(
                                    'tickets.drawer.actions.sections.config.modCategory'
                                )},model:{value:(_vm.form.category_id),callback:function ($$v) {_vm.$set(_vm.form, "category_id", $$v)},expression:"form.category_id"}},_vm._l((_vm.listCategories),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('div',{staticClass:"p-2"},[_c('el-form-item',{attrs:{"prop":"priority_id","label":_vm.$t(
                                'tickets.drawer.actions.sections.config.changePriority'
                            )}},[_c('el-select',{staticClass:"w-full",attrs:{"clearable":"","placeholder":_vm.$t(
                                    'tickets.drawer.actions.sections.config.modPriority'
                                )},model:{value:(_vm.form.priority_id),callback:function ($$v) {_vm.$set(_vm.form, "priority_id", $$v)},expression:"form.priority_id"}},_vm._l((_vm.listPriorities),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1),_c('div',{staticClass:"p-2"},[_c('el-form-item',{attrs:{"label":_vm.$t(
                                'tickets.drawer.actions.sections.config.changeDueDate'
                            )}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"clearable":"","type":"date","format":"dd-MM-yyyy","value-format":"yyyy-MM-dd","placeholder":_vm.$t(
                                    'tickets.drawer.actions.sections.config.modDueDate'
                                )},model:{value:(_vm.form.due_date),callback:function ($$v) {_vm.$set(_vm.form, "due_date", $$v)},expression:"form.due_date"}})],1)],1)]),_c('div',{staticClass:"flex-1"},[_c('div',{staticClass:"p-2"},[_c('el-form-item',{attrs:{"prop":"title","label":_vm.$t(
                                'tickets.drawer.actions.sections.config.changeTitle'
                            )}},[_c('el-input',{staticClass:"custom-input",attrs:{"clearable":"","type":"input","placeholder":_vm.$t(
                                    'tickets.drawer.actions.sections.placeholder2'
                                )},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1),_c('div',{staticClass:"p-2"},[_c('el-form-item',{attrs:{"prop":"description","label":_vm.$t(
                                'tickets.drawer.actions.sections.config.changeBody'
                            )}},[_c('el-input',{staticClass:"custom-input",attrs:{"clearable":"","type":"textarea","rows":10,"placeholder":_vm.$t(
                                    'tickets.drawer.actions.sections.placeholder2'
                                )},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1)],1)])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-popconfirm',{attrs:{"confirm-button-text":"OK","cancel-button-text":"NO","icon":"el-icon-info","icon-color":"red","title":_vm.$t('tickets.editor.confirmTicketCreation')},on:{"confirm":_vm.saveConfig}},[_c('el-button',{staticClass:"mr-2",attrs:{"slot":"reference","loading":_vm.loadingSaveConfig,"type":"primary"},slot:"reference"},[_vm._v(" "+_vm._s(_vm.$t("tickets.editor.save"))+" ")])],1),_c('el-button',{on:{"click":_vm.closeConfig}},[_vm._v(_vm._s(_vm.$t("tickets.editor.cancel")))])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }