<template>
    <div class="">
        <el-button
            type="success"
            @click="addUser"
            v-if="$iCan(constants.permissions.administration.create_user)"
        >
            <i class="fas fa-plus mr-2"></i>
            {{ $t("administration.users.new") }}
        </el-button>

        <el-dialog
            :title="$t('administration.users.modal.titleNew')"
            :visible.sync="dialogVisible"
            :before-close="exit"
            width="50%"
        >
            <AdminUserForm></AdminUserForm>
        </el-dialog>
    </div>
</template>

<script>
import { EventBus } from "@/store/eventBus";
import { mutations } from "@/store";
import constants from "@/store/constants";
import AdminUserForm from "@/entities/administration/AdminUserForm.vue";

export default {
    name: "AdminUserNew",

    components: {
        AdminUserForm,
    },

    data() {
        return {
            dialogVisible: false,
            constants,
        };
    },

    mounted() {
        EventBus.$on("exitAdminUserForm", () => this.exit());
    },

    beforeDestroy() {
        EventBus.$off("exitAdminUserForm");
    },

    methods: {
        addUser() {
            mutations.administration.setCurrentUser({
                username: "",
                email: "",
                password: "",
                roles_id: [],
                categories_id: [],
            });
            this.dialogVisible = true;
        },

        exit() {
            this.dialogVisible = false;
        },
    },
};
</script>
